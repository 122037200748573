import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import Slider from '@material-ui/core/Slider';
import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';
import ApplicationUser from '../../entities/ApplicationUser';
import { ProfileService } from '../../services/ProfileService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {

        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        avatar: {
            margin: '0 auto',
            marginTop: theme.spacing() * 4,
            width: 250,
            height: 250,
            borderRadius: 0
        },
        input: {
            display: 'none',
        },
        button: {
            margin: '0 auto',
            marginTop: theme.spacing() * 2,
        },
        slider: {
            padding: '22px 0px',
        },
        cropDialog: {
            minWidth: '400px',
            overflow: 'hidden'
        },
        [theme.breakpoints.down('xs')]: {
            cropDialog: {
                minWidth: 'inherit'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
    onSignIn: (user: ApplicationUser) => void;
}

interface State {
    enableSubmit: boolean;
    user: ApplicationUser;
    showUpdateSuccessMessage: boolean;
    uploadedFileData: any;
    showCropDialog: boolean;
    cropScale: number;
    errors: FieldValidationError[];
}

class ProfileImage extends React.Component<Props, State> {

    cropperRef: AvatarEditor | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            user: props.user,
            showUpdateSuccessMessage: false,
            uploadedFileData: '',
            showCropDialog: false,
            cropScale: 1,
            errors: []
        };
    }

    componentDidMount() {
        document.title = "SermonCentral Account - Profile Image";
    }

    handleFileUpload(file: File) {

        const reader = new FileReader();

        reader.onload = (e) => {
            this.setState({ uploadedFileData: reader.result, showCropDialog: true });
        };

        reader.readAsDataURL(file);
    }

    handleFileUploadChange(e: React.ChangeEvent<HTMLInputElement>) {

        var file = e.target.files && e.target.files[0];
        if (file) {
            this.handleFileUpload(file);
        }
    }

    handleFileUploadDrop(e: React.DragEvent<HTMLElement>) {

        e.preventDefault();
        var file = e.dataTransfer.files[0];
        if (file) {
            this.handleFileUpload(file);
        }
    }

    async removeProfileImage() {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await ProfileService.changeProfileImage(null);

            if (ServerResponse.isServerResponse<ApplicationUser>(response)) {

                this.setState({ user: response.data, showUpdateSuccessMessage: true, errors: [], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                this.props.onSignIn(response.data);
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
        }
    }

    async updateProfileImage() {

        if (!this.state.enableSubmit) {
            return;
        }

        if (this.cropperRef) {

            let canvas = document.createElement('canvas');
            canvas.width = 500;
            canvas.height = 500;
            const image = this.cropperRef.getImage();
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(image, 0, 0, 500, 500);
                const base64ImageUrl = canvas.toDataURL();

                try {
                    this.setState({ enableSubmit: false });
                    const response = await ProfileService.changeProfileImage(base64ImageUrl);

                    if (ServerResponse.isServerResponse<ApplicationUser>(response)) {

                        this.setState({ user: response.data, showUpdateSuccessMessage: true, errors: [], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                        this.props.onSignIn(response.data);
                    }
                    else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                        if (response.valid) {
                            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                            this.setState({ errors: [serverError], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                        }
                        else {
                            this.setState({ errors: response.errors, enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                        }
                    }
                    else {
                        const serverError: FieldValidationError = { field: "", errors: [response.message] };
                        this.setState({ errors: [serverError], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                    }
                }
                catch (errorResult) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showCropDialog: false, uploadedFileData: '', cropScale: 1 });
                }
            }
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Your profile image has been updated.</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Toolbar>
                    <Typography variant="h5">
                        Profile Image
                    </Typography>
                </Toolbar>

                <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => this.handleFileUploadDrop(e)}>
                    {this.state.user.profileImageUrl ?
                        <Avatar className={classes.avatar} src={this.state.user.profileImageUrl} /> :
                        <Avatar className={classes.avatar} src="https://i.cdn-sc.com/users/default-avatar.jpg" />
                    }

                    {this.state.user.profileImageUrl &&
                        <div style={{ textAlign: "center" }}>
                            <Button disabled={!this.state.enableSubmit} component="span" className={classes.button} onClick={() => this.removeProfileImage()}>
                                Remove Profile Image
                            </Button>
                        </div>
                    }

                    <div style={{ textAlign: "center", marginTop: '20px' }}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="outlined-button-file"
                            type="file"
                            onChange={(e) => this.handleFileUploadChange(e)}
                        />
                        <label htmlFor="outlined-button-file">
                            <Button disabled={!this.state.enableSubmit} variant="contained" color="primary" component="span" className={classes.button}>
                                Change Profile Image
                            </Button>
                        </label>
                    </div>
                </div>

                <Dialog
                    open={this.state.showCropDialog}
                    onClose={e => this.setState({ showCropDialog: false, uploadedFileData: '', cropScale: 1 })}
                    aria-labelledby="crop-dialog-title"
                    aria-describedby="crop-dialog-description"
                >
                    <DialogTitle id="crop-dialog-title">Crop Image</DialogTitle>
                    <DialogContent className={classes.cropDialog}>
                        <AvatarEditor
                            style={{ margin: '0 auto', marginBottom: '30px', display: 'block' }}
                            ref={(cropper) => this.cropperRef = cropper}
                            image={this.state.uploadedFileData}
                            width={250}
                            height={250}
                            border={10}
                            rotate={0}
                            scale={this.state.cropScale}
                        />
                        <Typography id="label">Zoom</Typography>
                        <Slider
                            className={classes.slider}
                            value={this.state.cropScale}
                            min={1}
                            max={3}
                            aria-labelledby="label"
                            onChange={(e, value) => this.setState({ cropScale: value as number })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!this.state.enableSubmit} onClick={e => this.setState({ showCropDialog: false, uploadedFileData: '', cropScale: 1 })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={!this.state.enableSubmit} onClick={() => this.updateProfileImage()} color="primary">
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        );
    }
}

export default withRoot(withStyles(styles)(ProfileImage));