import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import AboutMe from '../../entities/AboutMe';
import ApplicationUser from '../../entities/ApplicationUser';
import { ProfileService } from '../../services/ProfileService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            minHeight: '72vh',
            maxWidth: '700px',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto'
        },
        viewButton: {
            marginLeft: 'auto'
        },
        editForm: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        formButton: {
            marginTop: '15px',
            marginLeft: 'auto',
            display: 'flex'
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
    onSignIn: (user: ApplicationUser) => void;
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    firstName: string;
    lastName: string;
    education: string;
    experience: string;
    jobPosition: string;
    savedAboutMe?: AboutMe;
    showUpdateSuccessMessage: boolean;
    errors: FieldValidationError[];
}

class AboutMePage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            firstName: '',
            lastName: '',
            education: '',
            experience: '',
            jobPosition: '',
            showUpdateSuccessMessage: false,
            errors: [],
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - About Me";

        try {
            const aboutMe = await ProfileService.getAboutMe();

            if (aboutMe) {
                this.setState({
                    loading: false,
                    enableSubmit: true,
                    firstName: aboutMe.firstName,
                    lastName: aboutMe.lastName,
                    education: aboutMe.education,
                    experience: aboutMe.experience,
                    jobPosition: aboutMe.jobPosition,
                    savedAboutMe: aboutMe,
                    errors: [],
                });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.firstName && this.state.lastName) {
            try {
                this.setState({ enableSubmit: false });
                const response = await ProfileService.updateAboutMe(this.state.firstName, this.state.lastName, this.state.education, this.state.experience, this.state.jobPosition);

                if (ServerResponse.isServerResponse<ApplicationUser>(response)) {

                    this.props.onSignIn(response.data);
                    this.setState({
                        savedAboutMe: {
                            firstName: this.state.firstName,
                            lastName: this.state.lastName,
                            jobPosition: this.state.jobPosition,
                            experience: this.state.experience,
                            education: this.state.education
                        },
                        errors: [],
                        enableSubmit: true,
                        showUpdateSuccessMessage: true
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    renderEditMode() {

        const classes = this.props.classes;

        return (
            <form className={classes.editForm} onSubmit={(e) => this.handleFormSubmit(e)}>
                <TextField
                    margin="dense"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    required
                    value={this.state.firstName}
                    onChange={(e) => this.setState({ firstName: e.target.value })}
                    error={FieldValidationError.isFieldInError(this.state.errors, 'FirstName')}
                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'FirstName') }} />}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    required
                    value={this.state.lastName}
                    onChange={(e) => this.setState({ lastName: e.target.value })}
                    error={FieldValidationError.isFieldInError(this.state.errors, 'LastName')}
                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'LastName') }} />}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    id="jobPosition"
                    label="Job Position"
                    value={this.state.jobPosition}
                    onChange={(e) => this.setState({ jobPosition: e.target.value })}
                    error={FieldValidationError.isFieldInError(this.state.errors, 'JobPosition')}
                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'JobPosition') }} />}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    id="education"
                    label="Education"
                    multiline
                    rows="4"
                    value={this.state.education}
                    onChange={(e) => this.setState({ education: e.target.value })}
                    error={FieldValidationError.isFieldInError(this.state.errors, 'Education')}
                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Education') }} />}
                />
                <TextField
                    margin="dense"
                    fullWidth
                    id="experience"
                    label="Experience"
                    multiline
                    rows="4"
                    value={this.state.experience}
                    onChange={(e) => this.setState({ experience: e.target.value })}
                    error={FieldValidationError.isFieldInError(this.state.errors, 'Experience')}
                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Experience') }} />}
                />
                <Button disabled={!this.state.enableSubmit} type="submit" variant="contained" color="primary" className={classes.formButton}>
                    Save Changes
                </Button>
            </form>
        );
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Your profile has been updated</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                {this.state.loading &&
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ marginTop: "50px" }} />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading...
                        </Typography>
                    </div>
                }
                {!this.state.loading &&
                    <Paper elevation={1} className={classes.paper}>
                        <Toolbar>
                            <Typography variant="h5">
                                About Me
                        </Typography>
                            <Button href={this.props.user.publicProfileUrl} target="blank" className={classes.viewButton}>(View public profile)</Button>
                        </Toolbar>
                        {this.renderEditMode()}
                    </Paper>
                }
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(AboutMePage));