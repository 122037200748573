import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import MessageIcon from '@material-ui/icons/Message';
import * as React from 'react';
import PrivateMessage from '../../entities/PrivateMessage';
import { MiscService } from '../../services/MiscService';
import { FieldValidationError } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2
        },
        divider: {
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
        },
        image: {
            width: '100%',
            maxWidth: '50%',
            margin: '0 auto',
            display: 'block'
        },
        date: {
            opacity: 0.7,
            marginBottom: theme.spacing()
        },
        card: {
            width: '500px',
            marginBottom: "16px"
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            cardContainer: {
                width: "100%",
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
}

interface State {
    messages: PrivateMessage[] | undefined;
    errors: FieldValidationError[];
}

class PrivateMessagesPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            messages: undefined,
            errors: [],
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Private Messages";

        try {
            const messages = await MiscService.getPrivateMessages();

            if (messages) {
                this.setState({ messages });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <div className={classes.paper}>
                    <Typography variant="h5" component="h2">
                        Private Messages
                    </Typography>
                    <Grid container direction="column" justify="center" alignItems="center">
                        {!this.state.messages &&
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading...
                                </Typography>
                            </div>
                        }
                        {this.state.messages && this.state.messages.length === 0 &&
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                <MessageIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                                <br />
                                You do not have any private messages.
                            </Typography>
                        }
                        {this.state.messages && this.state.messages.length > 0 && this.state.messages.map((message, index) =>
                            <Card className={classes.card} key={index}>
                                <CardHeader
                                    title={message.fromUserFirstName + ' ' + (message.fromUserLastName ? message.fromUserLastName : '')}
                                    subheader={new Date(message.dateSent).toDateString()}
                                    avatar={message.fromUserImageUrl ?
                                        <Avatar src={message.fromUserImageUrl} /> :
                                        (message.fromUserLastName ?
                                            <Avatar color="primary">{message.fromUserFirstName.charAt(0).toUpperCase()}{message.fromUserLastName.charAt(0).toUpperCase()}</Avatar> :
                                            <Avatar src='https://i.cdn-sc.com/users/default-avatar.jpg' />
                                        )
                                    }
                                />
                                <Divider />
                                <CardContent>
                                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message.message }} />
                                </CardContent>
                            </Card>
                        )}
                    </Grid>
                </div>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(PrivateMessagesPage));