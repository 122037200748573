import { Currencies } from '../entities/Currencies';
export class CurrencyHelper {
    public static GetCurrencySymbol = (currency: Currencies) => {
        switch (currency) {
            case "PHP":
                return '₱';
            case "ZAR":
                return 'R';
            case "NGN":
                return '₦';
            case "KES":
                return 'KSh';
            case "GHS":
                return 'GH₵';
            default:
                return '$';
        }
    }
}