import AppConfig from "../entities/AppConfig";
import ApplicationUser from "../entities/ApplicationUser";
import BillingStatement from "../entities/BillingStatement";
import Country from "../entities/Country";
import CreditCard from "../entities/CreditCard";
import SparkSubscription, { SparkSubscriptionStatus } from "../entities/SparkSubscription";
import { ServerResponse, WebClient } from "../services/ServiceHelper";

export interface MediaStatistics {
  totalDownloadCount: number;
  totalPurchaseCount: number;
}
export interface PremiumSermonSeriesKitAccess {
  lastActivatedName: string;
  lastActivatedDate: string;
  lastActivatedUrl: string;
  nextActivationDate: string;
  activationCount: number;
  canActivate: boolean;
}

export interface PlusMediaAccess {
  periodStart: string;
  periodEnd: string;
  actualBackgroundImageDownloads: number;
  allowedBackgroundImageDownloads?: number;
  actualCountdownVideoDownloads: number;
  allowedCountdownVideoDownloads?: number;
  actualMotionBackgroundDownloads: number;
  allowedMotionBackgroundDownloads?: number;
  actualPreachingSlidesDownloads: number;
  allowedPreachingSlidesDownloads?: number;
  actualWorshipLyricSlideDownloads: number;
  allowedWorshipLyricSlideDownloads?: number;
  actualVideoIllustrationDownloads: number;
  allowedVideoIllustrationDownloads?: number;
  actualWorshipMusicVideoDownloads: number;
  allowedWorshipMusicVideoDownloads?: number;
  actualSocialGraphicDownloads: number;
  allowedSocialGraphicDownloads?: number;
  actualComedyVideoDownloads: number;
  allowedComedyVideoDownloads?: number;
  actualInspirationalVideoDownloads: number;
  allowedInspirationalVideoDownloads?: number;
  actualSmallGroupStudyVideoDownloads: number;
  allowedSmallGroupStudyVideoDownloads?: number;
}

export interface SermonStatistics {
  approvedCount: number;
  pendingCount: number;
  totalViewCount: number;
}

export interface SermonIllustrationStatistics {
  approvedCount: number;
  pendingCount: number;
  totalViewCount: number;
}

export class AccountService {
  public static async changeEmail(newEmail: string, appConfig: AppConfig) {
    let changeEmailResponse = await WebClient.Post<ApplicationUser>(
      "api/Account/ChangeEmail",
      { newEmail: newEmail }
    );
    if (ServerResponse.isServerResponse(changeEmailResponse)) {
      await WebClient.Put<null>(
        appConfig.makerSiteUrl + "/api/Account/UpdateSermonMakerUser",
        {}
      );
    }
    return changeEmailResponse;
  }

  public static changePassword(
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) {
    return WebClient.Post<null>("api/Account/ChangePassword", {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    });
  }

  
  public static changePhone(newPhone: string) {
    return WebClient.Post<ApplicationUser>("api/Account/ChangePhone", { newPhone });
  }

  public static async getMediaStatistics() {
    var response = await WebClient.Get<MediaStatistics>(
      "api/Account/GetMediaStatistics"
    );
    if (ServerResponse.isServerResponse<MediaStatistics>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async getPremiumSermonSeriesKitAccess() {
    var response = await WebClient.Get<PremiumSermonSeriesKitAccess>(
      "api/Account/GetPremiumSermonSeriesKitAccess"
    );
    if (
      ServerResponse.isServerResponse<PremiumSermonSeriesKitAccess>(response)
    ) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async getPlusMediaAccess() {
    var response = await WebClient.Get<PlusMediaAccess>(
      "api/Account/GetPlusMediaAccess"
    );
    if (ServerResponse.isServerResponse<PlusMediaAccess>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async getSermonStatistics() {
    var response = await WebClient.Get<SermonStatistics>(
      "api/Account/GetSermonStatistics"
    );
    if (ServerResponse.isServerResponse<SermonStatistics>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async getSermonIllustrationStatistics() {
    var response = await WebClient.Get<SermonIllustrationStatistics>(
      "api/Account/GetSermonIllustrationStatistics"
    );
    if (
      ServerResponse.isServerResponse<SermonIllustrationStatistics>(response)
    ) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async getHasSermonMakerSubscription(appConfig: AppConfig) {
    var response = await WebClient.Get<{ makerSubscription?: any }>(
      appConfig.makerSiteUrl + "/api/Subscription"
    );
    if (ServerResponse.isServerResponse(response)) {
      return response.data != null;
    } else {
      return undefined;
    }
  }

  public static async getHasSermonSparkSubscription(sparkSiteUrl: string): Promise<boolean> {
    var response = await WebClient.Get<SparkSubscription>(
      sparkSiteUrl + "/api/Account/Subscription"
    );
    if (ServerResponse.isServerResponse(response)) {
      return response.data == null
        ? false
        : response.data.status === SparkSubscriptionStatus.Active;
    } else {
      return false
    }
  }

  public static async getBillingStatements() {
    var response = await WebClient.Get<BillingStatement[]>(
      "api/Account/GetBillingStatements"
    );
    if (ServerResponse.isServerResponse<BillingStatement[]>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async hasPayPal() {
    var response = await WebClient.Get<boolean>("api/Account/HasPayPal");
    if (ServerResponse.isServerResponse<boolean>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  static async getCountries() {
    var response = await WebClient.Get<Country[]>("api/Account/GetCountries");
    if (ServerResponse.isServerResponse<Country[]>(response)) {
      return response;
    } else {
      return null;
    }
  }

  public static async getCreditCards() {
    var response = await WebClient.Get<CreditCard[]>(
      "api/Account/GetCreditCards"
    );
    if (ServerResponse.isServerResponse<CreditCard[]>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async changeDefaultCreditCard(cardId: string) {
    let response = await WebClient.Post<CreditCard[]>(
      "api/Account/SetDefaultCreditCard",
      { CreditCardId: cardId }
    );
    if (ServerResponse.isServerResponse<CreditCard[]>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async deleteCreditCard(cardId: string) {
    let response = await WebClient.Delete<CreditCard[]>(
      "api/Account/DeleteCreditCard",
      { CreditCardId: cardId }
    );
    if (ServerResponse.isServerResponse<CreditCard[]>(response)) {
      return response.data;
    } else {
      return null;
    }
  }

  public static async addPaymentMethod(
    appConfig: AppConfig,
    cardName: string,
    cardNumber: string,
    expiryMonth: string,
    expiryYear: string,
    cvv: string,
    zip: string,
    country?: string
  ) {
    let model = {
      CardHolderName: cardName,
      CardNumber: cardNumber.replace(/[^0-9]/gi, ""),
      SecurityCode: cvv,
      ExpirationMonth: expiryMonth,
      ExpirationYear: expiryYear,
      PostalCode: zip,
      Country: country,
    };

    let response = await WebClient.Post<CreditCard>(
      appConfig.paymentsSiteUrl + "/api/CreditCards/AddCreditCard",
      model
    );

    return response;
  }

  public static async updatePaymentMethod(
    appConfig: AppConfig,
    paymentMethodId: string,
    expiryMonth: string,
    expiryYear: string,
    cvv: string,
    zip: string,
    country?: string
  ) {
    let model = {
      PaymentMethodId: paymentMethodId,
      SecurityCode: cvv,
      ExpirationMonth: expiryMonth,
      ExpirationYear: expiryYear,
      PostalCode: zip,
      Country: country,
    };

    let response = await WebClient.Post<CreditCard>(
      appConfig.paymentsSiteUrl + "/api/CreditCards/UpdateCreditCard",
      model
    );
    return response;
  }
}
