import { library } from '@fortawesome/fontawesome-svg-core';
import { faCcAmex, faCcDiscover, faCcMastercard, faCcPaypal, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import * as React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import './app.css';
import AnalyticsPageTracker from "./components/AnalyticsPageTracker";
import { PrivateRoute } from './components/PrivateRoute';
import AppConfig, { AppConfigEnvironment } from "./entities/AppConfig";
import ApplicationUser from './entities/ApplicationUser';
import Layout from './layout/layout';
import BillingStatements from './pages/account/billingStatements';
import ChangeEmail from './pages/account/changeEmail';
import ChangePassword from './pages/account/changePassword';
import ChangePhone from './pages/account/changePhone';
import AccountDashboard from './pages/account/home';
import MediaDashboard from './pages/account/mediaDashboard';
import PaymentMethods from './pages/account/paymentMethods';
import FreeProAccess from './pages/admin/freeProAccess';
import Impersonate from './pages/admin/impersonate';
import Notes from './pages/admin/notes';
import ForgotPassword from './pages/authentication/forgotPassword';
import ForgotPasswordSuccess from './pages/authentication/forgotPasswordSuccess';
import Register from './pages/authentication/register';
import ResetPassword from './pages/authentication/resetPassword';
import SignIn from './pages/authentication/signIn';
import EditSermon from './pages/contributions/editSermon';
import EditSermonIllustration from './pages/contributions/editSermonIllustration';
import AddEditSeries from './pages/contributions/editSermonSeries';
import ContributorDashboard from './pages/contributions/home';
import SermonIllustrations from './pages/contributions/sermonIllustrations';
import SermonSeries from './pages/contributions/sermonSeries';
import Sermons from './pages/contributions/sermons';
import Activations from './pages/media/activations';
import Downloads from './pages/media/downloads';
import ManageMedia from "./pages/media/media-producer/manageMedia";
import ApprovedVideoMedia from "./pages/media/media-producer/video-media/approvedVideoMedia";
import PendingVideoMedia from "./pages/media/media-producer/video-media/pendingVideoMedia";
import Purchases from './pages/media/purchases';
import DiscussionNotifications from "./pages/misc/discussionNotifications";
import PrivateMessages from './pages/misc/privateMessages';
import SermonFolders from './pages/misc/sermonFolders';
import ViewingHistory from "./pages/misc/viewingHistory";
import NewsletterSubscriptions from './pages/preferences/newsletterSubscriptions';
import Notifications from './pages/preferences/notificationsPreferences';
import BibleTranslation from './pages/preferences/preferredBibleTranslation';
import AboutMe from './pages/profile/aboutMe';
import MyChurch from './pages/profile/myChurch';
import MyStory from './pages/profile/myStory';
import ProfileImage from './pages/profile/profileImage';
import SharedLinks from './pages/profile/sharedLinks';
import { AppConfigService } from "./services/AppConfigService";
import { AuthenticationService } from './services/AuthenticationService';

library.add(faCcAmex);
library.add(faCcDiscover);
library.add(faCcMastercard);
library.add(faCcVisa);
library.add(faCcPaypal);

interface AppProps {
    baseUrl: string;
}

interface AppState {
    loading: boolean;
    user: ApplicationUser | null;
    config: AppConfig | null;
}

class App extends React.Component<AppProps, AppState> {

    constructor(props: AppProps) {
        super(props);
        this.state = {
            loading: true,
            user: null,
            config: {
                environment: AppConfigEnvironment.Production,
                googleAnalytics: {
                    isEnabled: true,
                    trackingId: 'UA-75701108-7'
                },
                paypalUrl: 'https://www.sermoncentral.com',
                mainSiteUrl: 'https://www.sermoncentral.com',
                paymentsSiteUrl: 'https://payments.sermoncentral.com',
                makerSiteUrl: 'https://maker.sermoncentral.com',
                sparkSiteUrl: 'https://research.sermoncentral.com',
                accountSiteUrl: 'https://account.sermoncentral.com',
                glooSubscriptionsUrl: 'https://app.gloo.us/settings/subscriptions',
                glooUserProfileUrl: "https://app.gloo.us/settings/settings/profile/info",
            }
        };
    }

    componentDidMount() {

        AppConfigService.getAppConfig()
            .then(config => {
                if (config) {
                    this.setState({ config: config });
                }
            });

        AuthenticationService.getAuthenticatedUser()
            .then(user => this.setState({
                user: user,
                loading: false
            }))
            .catch(error => this.setState({
                user: null,
                loading: false
            }));
    }

    handleUpdateUser(user: ApplicationUser | null) {
        this.setState({
            user: user
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <img alt="" className="sc-content-spinner" src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif" />
            );
        }
        else {
            return (
                <BrowserRouter basename={this.props.baseUrl}>
                    <AnalyticsPageTracker config={this.state.config!}>
                        <Layout config={this.state.config!} user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} onSignOut={() => this.handleUpdateUser(null)}>
                            <Switch>
                                <Route exact path="/auth/sign-in" render={(routeProps) => <SignIn {...routeProps} config={this.state.config!} user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                <Route exact path="/auth/register" render={() => <Register user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                <Route exact path="/auth/forgot-password" render={(routeProps) => <ForgotPassword {...routeProps} user={this.state.user} />} />
                                <Route exact path="/auth/forgot-password/success" render={() => <ForgotPasswordSuccess user={this.state.user} />} />
                                <Route exact path='/auth/reset-password/:email/:code' render={(routeProps) => <ResetPassword {...routeProps} user={this.state.user} onSignIn={(user) => this.handleUpdateUser(user)} />} />


                                {this.state.user && this.state.user.roles.filter((r) => r === 'MediaProducer').length > 0 ?
                                    <PrivateRoute exact path="/" user={this.state.user} render={() => <MediaDashboard user={this.state.user!} config={this.state.config!} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                    :
                                    <PrivateRoute exact path="/" user={this.state.user} render={() => <AccountDashboard user={this.state.user!} config={this.state.config!} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                }

                                <PrivateRoute exact path="/admin/impersonate" user={this.state.user} render={(routeProps) => <Impersonate {...routeProps} user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                <PrivateRoute exact path="/admin/notes" user={this.state.user} render={() => <Notes user={this.state.user!} />} />
                                <PrivateRoute exact path="/admin/free-pro-access" user={this.state.user} render={() => <FreeProAccess user={this.state.user!} />} />

                                <PrivateRoute exact path="/account/change-email" user={this.state.user} render={() => <ChangeEmail user={this.state.user!} config={this.state.config!} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                <PrivateRoute exact path="/account/change-password" user={this.state.user} render={() => <ChangePassword user={this.state.user!} />} />
                                <PrivateRoute exact path="/account/change-phone" user={this.state.user} render={() => <ChangePhone user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                <PrivateRoute exact path="/account/payment-methods" user={this.state.user} render={() => <PaymentMethods config={this.state.config!} user={this.state.user!} />} />
                                <PrivateRoute exact path="/account/billing-statements" user={this.state.user} render={() => <BillingStatements user={this.state.user!} />} />

                                <PrivateRoute exact path="/media/downloads" user={this.state.user} component={Downloads} />
                                <PrivateRoute exact path="/media/purchases" user={this.state.user} component={Purchases} />
                                <PrivateRoute exact path="/media/activations" user={this.state.user} component={Activations} />
                                <PrivateRoute exact path="/media/manage/:type" user={this.state.user} render={(routeProps) => <ManageMedia {...routeProps} key={routeProps.location.pathname} config={this.state.config!}  user={this.state.user!}/>} />
                                <PrivateRoute exact path="/media/video-media/pending/add/:type" user={this.state.user} render={(routeProps)  => <PendingVideoMedia key={routeProps.location.pathname} {...routeProps} config={this.state.config!} />} />
                                <PrivateRoute exact path="/media/video-media/pending/edit/:id" user={this.state.user} render={(routeProps) => <PendingVideoMedia key={routeProps.location.pathname} {...routeProps} config={this.state.config!} />} />
                                <PrivateRoute exact path="/media/video-media/approved/edit/:id" user={this.state.user} render={(routeProps) => <ApprovedVideoMedia key={routeProps.location.pathname} {...routeProps} config={this.state.config!} />} />

                                <PrivateRoute exact path="/contributions" user={this.state.user} component={ContributorDashboard} />
                                <PrivateRoute exact path="/contributions/sermon-illustrations" user={this.state.user} component={SermonIllustrations} />
                                <PrivateRoute exact path="/contributions/sermon-illustrations/add" user={this.state.user} component={EditSermonIllustration} />
                                <PrivateRoute exact path="/contributions/sermon-illustrations/edit/:id" user={this.state.user} component={EditSermonIllustration} />
                                <PrivateRoute exact path="/contributions/sermons" user={this.state.user} component={Sermons} />
                                <PrivateRoute exact path="/contributions/sermons/add" user={this.state.user} component={EditSermon} />
                                <PrivateRoute exact path="/contributions/sermons/edit/:id" user={this.state.user} component={EditSermon} />
                                <PrivateRoute exact path="/contributions/sermon-series" user={this.state.user} component={SermonSeries} />
                                <PrivateRoute exact path="/contributions/sermon-series/add" user={this.state.user} component={AddEditSeries} />
                                <PrivateRoute exact path="/contributions/sermon-series/edit/:id" user={this.state.user} component={AddEditSeries} />

                                <PrivateRoute exact path="/discussion-notifications" user={this.state.user} component={DiscussionNotifications} />
                                <PrivateRoute exact path="/private-messages" user={this.state.user} component={PrivateMessages} />
                                <PrivateRoute exact path="/sermon-folders" user={this.state.user} render={(routeProps) => <SermonFolders {...routeProps} config={this.state.config!} />} />
                                <PrivateRoute exact path="/sermon-folders/:id" user={this.state.user} render={(routeProps) => <SermonFolders {...routeProps} config={this.state.config!} />} />
                                <PrivateRoute exact path="/viewing-history" user={this.state.user} render={(routeProps) => <ViewingHistory {...routeProps} config={this.state.config!} user={this.state.user!} />} />

                                <PrivateRoute exact path="/preferences/bible-translation" user={this.state.user} component={BibleTranslation} />
                                <PrivateRoute exact path="/preferences/newsletter-subscriptions" user={this.state.user} render={() => <NewsletterSubscriptions user={this.state.user!} />} />
                                <PrivateRoute exact path="/preferences/notifications" user={this.state.user} component={Notifications} />

                                <PrivateRoute exact path="/profile/about-me" user={this.state.user} render={() => <AboutMe user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />} />
                                <PrivateRoute exact path="/profile/my-church" user={this.state.user} render={() => <MyChurch user={this.state.user!} config={this.state.config!} />} />
                                <PrivateRoute exact path="/profile/my-story" user={this.state.user} render={() => <MyStory user={this.state.user!} />} />
                                <PrivateRoute exact path="/profile/shared-links" user={this.state.user} render={() => <SharedLinks user={this.state.user!} />} />
                                <PrivateRoute exact path="/profile/profile-image" user={this.state.user} render={() => <ProfileImage user={this.state.user!} onSignIn={(user) => this.handleUpdateUser(user)} />} />

                                <Redirect to="/" />
                            </Switch>
                        </Layout>
                    </AnalyticsPageTracker>
                </BrowserRouter>
            );
        }
    }
}

export default App;