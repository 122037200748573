import Country from "../entities/Country";
import Sermon from "../entities/Sermon";
import SermonIllustration from "../entities/SermonIllustration";
import SermonSeries from "../entities/SermonSeries";
import USState from "../entities/USState";
import { ServerResponse, WebClient } from "./ServiceHelper";

export interface SermonRegionViewSummary {
    countryCode: string;
    endDate: Date;
    regionName: string;
    sermonId: number;
    startDate: Date;
    viewCount: number;
}

export interface SermonIllustrationRegionViewSummary {
    countryCode: string;
    endDate: Date;
    regionName: string;
    sermonIllustrationId: number;
    startDate: Date;
    viewCount: number;
}

export class ContributionsService {

    public static async getChurchServiceLanguages() {

        var response = await WebClient.Get<{ name: string }[]>("api/Contributions/GetChurchServiceLanguages");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermonIllustrationCategories() {

        var response = await WebClient.Get<{ name: string }[]>("api/Contributions/GetSermonIllustrationCategories");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getChurchServiceTargetAudiences() {

        var response = await WebClient.Get<{ id: number, name: string }[]>("api/Contributions/GetChurchServiceTargetAudiences");
        if (ServerResponse.isServerResponse<{ id: number, name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermonTypes() {

        var response = await WebClient.Get<{ name: string }[]>("api/Contributions/GetSermonTypes");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getCountries() {
        const response = await WebClient.Get<Country[]>("api/Contributions/GetCountries");
        if (ServerResponse.isServerResponse<Country[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getUSStates() {
        const response = await WebClient.Get<USState[]>("api/Contributions/GetUSStates");
        if (ServerResponse.isServerResponse<USState[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermons() {
        const response = await WebClient.Get<Sermon[]>("api/Contributions/GetSermons");
        if (ServerResponse.isServerResponse<Sermon[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermon(id: number) {
        const response = await WebClient.Get<Sermon>("api/Contributions/GetSermon/" + id);
        if (ServerResponse.isServerResponse<Sermon>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermonRegionViewSummaries(timeframe: string) {
        const response = await WebClient.Get<SermonRegionViewSummary[]>("api/Contributions/GetSermonRegionViewSummaries/" + timeframe);
        if (ServerResponse.isServerResponse<SermonRegionViewSummary[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static deleteSermon(sermonId: number, sermonSeriesId: number | undefined) {
        return WebClient.Delete<null>("api/Contributions/DeleteSermon", { sermonId, sermonSeriesId });
    }

    public static saveSermon(id: number | undefined, sermonSeriesId: number | undefined, sermonSeriesName: string, title: string, description: string, body: string, language: string, targetAudience: string, sermonType: string, topics: string[], scriptureReferences: string[]) {
        return WebClient.Post<Sermon>("api/Contributions/SaveSermon", { id, sermonSeriesId, sermonSeriesName, title, description, body, language, targetAudience, sermonType, topics, scriptureReferences });
    }

    public static async getSermonIllustrations() {
        const response = await WebClient.Get<SermonIllustration[]>("api/Contributions/GetSermonIllustrations");
        if (ServerResponse.isServerResponse<SermonIllustration[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermonIllustration(id: number) {
        const response = await WebClient.Get<SermonIllustration>("api/Contributions/GetSermonIllustration/" + id);
        if (ServerResponse.isServerResponse<SermonIllustration>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermonIllustrationRegionViewSummaries(timeframe: string) {
        const response = await WebClient.Get<SermonIllustrationRegionViewSummary[]>("api/Contributions/GetSermonIllustrationRegionViewSummaries/" + timeframe);
        if (ServerResponse.isServerResponse<SermonIllustrationRegionViewSummary[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static deleteSermonIllustration(sermonIllustrationId: number) {
        return WebClient.Delete<null>("api/Contributions/DeleteSermonIllustration", { sermonIllustrationId });
    }

    public static saveSermonIllustration(id: number | undefined, title: string, body: string, language: string, category: string, targetAudience: string, topics: string[], scriptureReferences: string[]) {
        return WebClient.Post<SermonIllustration>("api/Contributions/SaveSermonIllustration", { id, title, body, language, category, targetAudience, topics, scriptureReferences });
    }

    public static async getAllSermonSeries() {

        const response = await WebClient.Get<SermonSeries[]>("api/Contributions/GetSermonSeries");
        if (ServerResponse.isServerResponse<SermonSeries[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSermonSeries(id: number | undefined) {

        const response = await WebClient.Get<SermonSeries>("api/Contributions/GetSermonSeries/" + id);
        if (ServerResponse.isServerResponse<SermonSeries>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static deleteSermonSeries(sermonSeriesId: number) {
        return WebClient.Delete<null>("api/Contributions/DeleteSermonSeries", { sermonSeriesId });
    }

    public static saveSermonSeries(id: number | undefined, title: string, summary: string, topics: string[], scriptureReferences: string[]) {
        return WebClient.Post<SermonSeries>("api/Contributions/SaveSermonSeries", { id, title, summary, topics, scriptureReferences });
    }

    public static async getSermonSeriesSermons(id: number) {
        const response = await WebClient.Get<Sermon[]>("api/Contributions/GetSermonSeriesSermons/" + id);
        if (ServerResponse.isServerResponse<Sermon[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static addSermonSeriesSermon(sermonId: number, sermonSeriesId: number) {
        return WebClient.Post<Sermon>("api/Contributions/AddSermonSeriesSermon", { sermonId, sermonSeriesId });
    }

    public static removeSermonSeriesSermon(sermonId: number, sermonSeriesId: number) {
        return WebClient.Delete<null>("api/Contributions/RemoveSermonSeriesSermon", { sermonId, sermonSeriesId });
    }

    public static updateSermonSeriesSermon(sermonId: number, sermonSeriesId: number, sortOrder: number) {
        return WebClient.Post<Sermon>("api/Contributions/UpdateSermonSeriesSermon", { sermonId, sermonSeriesId, sortOrder });
    }
}