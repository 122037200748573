import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import ApplicationUser from '../../entities/ApplicationUser';
import Logo from '../../resources/logo.png';
import { AuthenticationService } from '../../services/AuthenticationService';
import { FieldValidationError, ServerError, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            paddingTop: theme.spacing() * 20,
        },
        forgotPasswordContainer: {
            height: '100vh',
        },
        forgotPasswordPaper: {
            padding: '0px 50px',
            maxWidth: 500
        },
        logoImage: {
            maxWidth: "90px",
            margin: "30px 0px 20px 0px"
        },
        textButton: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        submitButton: {
            minWidth: '150px'
        },
        submitButtonMobile: {
            margin: '20px 0px 70px 0px'
        },
        buttonContainer: {
            padding: '30px 0px 70px 0px'
        },
        link: {
            textDecoration: 'none'
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            forgotPasswordPaper: {
                margin: '0px 20px',
                padding: '0px 30px'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
    user: ApplicationUser | null;
}

type State = {
    enableSubmit: boolean;
    email: string;
    errors: Array<FieldValidationError>;
};

class ForgotPassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            email: '',
            errors: [],
        };
    }

    componentDidMount() {
        document.title = "SermonCentral Account - Forgot Password";
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.email) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AuthenticationService.sendResetPasswordEmail(this.state.email);

                if (ServerResponse.isServerResponse(response)) {
                    this.props.history.push("/auth/forgot-password/success");
                }
                else if (ServerError.isServerError(response)) {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {

        const classes = this.props.classes;

        if (this.props.user) {
            return <Redirect to="/" />;
        }

        return (
            <Grid className={classes.forgotPasswordContainer} container direction="column" justify="center" alignItems="center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbartMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Grid item>
                    <Paper className={classes.forgotPasswordPaper}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <img src={Logo} className={classes.logoImage} />
                            <Typography variant="h5">Forgot Your Password?</Typography>
                            <Typography variant="subtitle1">Enter your email address and we will send you an email with a link to reset your password.</Typography>
                        </Grid>
                        <form onSubmit={e => this.handleFormSubmit(e)}>
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Email')}
                                required
                                autoFocus
                                fullWidth
                                id="email"
                                label="Email"
                                type="email"
                                margin="normal"
                                onChange={e => this.setState({ email: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Email') }} />}
                            />
                            <Hidden smDown>
                                <Grid container direction="row" justify="space-between" alignItems="center" className={classes.buttonContainer}>
                                    <Link to="/" className={classes.link}>
                                        <Button variant="text" size="medium" color="primary" type="button" className={classes.textButton}>Cancel</Button>
                                    </Link>
                                    <Button variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButton}>Send Email</Button>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Link to="/" className={classes.link}>
                                        <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Cancel</Button>
                                    </Link>
                                </Grid>
                                <Button fullWidth variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButtonMobile}>Send Email</Button>
                            </Hidden>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(ForgotPassword));