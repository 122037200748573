import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import HistoryIcon from '@material-ui/icons/History';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AppConfig from '../../entities/AppConfig';
import ApplicationUser from '../../entities/ApplicationUser';
import HistoryResource from '../../entities/HistoryResource';
import { ResourceViewingHistoryService } from '../../services/ResourceViewingHistory';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flexGrow: 1,
        },
        toolbar: {
            flexWrap: 'wrap'
        },
        formControl: {
            margin: theme.spacing(),
            minWidth: 200,
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px"
        },
        cardImage: {
            height: 150,
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 2,
            right: theme.spacing() * 2,
        },
        extendedFab: {
            marginRight: theme.spacing()
        },
        radioBox: {
            padding: '5px 12px'
        },
        [theme.breakpoints.down('sm')]: {
            cardContainer: {
                width: "100%",
            }
        },
        snackBarText: {
            overflow: 'hidden'
        },
        avatar: {
            margin: '8px 0px'
        },
        cardHeader: {
            paddingTop: 0,
            paddingBottom: 0
        },
        cardHeaderContent: {
            width: '83%'
        },
        contributorLink: {
            color: theme.palette.primary.main,
            textDecoration: 'none'
        },
        titleLink: {
            textDecoration: 'none'
        }
    });

interface Props extends RouteComponentProps<{ id: string | undefined }>, WithStyles<typeof styles> {
    config: AppConfig;
    user: ApplicationUser;
}

interface State {
    loading: boolean;
    resources: HistoryResource[];
    errors: FieldValidationError[];
    showUpdateSuccessMessage: boolean;
    updateSuccessMessage: string;
    showDeleteDialog: boolean;
    showDeleteAllDialog: boolean;
    historyEnabled: boolean;
    enableSubmit: boolean;
    selectedResourceIdToDelete: number | undefined;
}

class ViewingHistory extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            resources: [],
            errors: [],
            showUpdateSuccessMessage: false,
            updateSuccessMessage: '',
            showDeleteDialog: false,
            showDeleteAllDialog: false,
            historyEnabled: true,
            enableSubmit: true,
            selectedResourceIdToDelete: undefined
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Viewing History";

        try {
            const resources = await ResourceViewingHistoryService.getResourceViewingHistory();

            if (resources) {
                this.setState({ resources, loading: false, historyEnabled: this.props.user.isRecordingHistory });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async deleteResource() {
        if (this.state.selectedResourceIdToDelete) {
            try {
                const response = await ResourceViewingHistoryService.deleteResourceViewingHistory(this.state.selectedResourceIdToDelete);
                if (ServerResponse.isServerResponse<null>(response)) {

                    let resources = this.state.resources;
                    resources = resources.filter(l => l.id !== this.state.selectedResourceIdToDelete!);

                    this.setState({
                        resources: resources,
                        selectedResourceIdToDelete: undefined,
                        enableSubmit: true,
                        showDeleteDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'History Resource was deleted successfully'
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showDeleteDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
    }

    async deleteAllResources() {
        try {
            const response = await ResourceViewingHistoryService.deleteResourceViewingHistories();
            if (ServerResponse.isServerResponse<null>(response)) {
                this.setState({
                    resources: [],
                    enableSubmit: true,
                    showDeleteAllDialog: false,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'All History Resources were deleted successfully'
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteAllDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true, showDeleteAllDialog: false });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showDeleteAllDialog: false });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async toggleRecording() {
        try {
            const response = this.state.historyEnabled ? await ResourceViewingHistoryService.disableResourceViewingHistory() : await ResourceViewingHistoryService.enableResourceViewingHistory();
            if (ServerResponse.isServerResponse<null>(response)) {
                this.setState({
                    enableSubmit: true,
                    historyEnabled: !this.state.historyEnabled
                },
                    () => this.setState({
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: `History Recording ${this.state.historyEnabled ? 'Enabled' : 'Disabled'}`
                    }));
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async enableRecording() {
        try {
            const response = await ResourceViewingHistoryService.enableResourceViewingHistory();
            if (ServerResponse.isServerResponse<null>(response)) {
                this.setState({
                    enableSubmit: true,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'Viewing History Enabled'
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async disableRecording() {
        try {
            const response = await ResourceViewingHistoryService.disableResourceViewingHistory();
            if (ServerResponse.isServerResponse<null>(response)) {
                this.setState({
                    enableSubmit: true,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'Viewing History Disabled'
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <CheckCircleIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Viewing History
                        </Typography>
                        {!this.state.loading &&
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.state.historyEnabled}
                                        onChange={() => this.toggleRecording()}
                                        value="checkedB"
                                        color="primary"
                                    />
                                }
                                label={this.state.historyEnabled ? 'History Enabled' : 'History Disabled'}
                            />
                        }
                    </div>
                    <div className={classes.spacer} />
                    {!this.state.loading &&
                        <Button color="secondary" variant="outlined" size="small" onClick={() => this.setState({ showDeleteAllDialog: true })}>
                            Clear History
                        </Button>
                    }
                </Toolbar>
                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading ?
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                        :
                        (
                            <>
                                {this.state.resources.length === 0 &&
                                    <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                        <HistoryIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                                        <br />
                                        You have no items in your history.
                            </Typography>
                                }
                                {!this.state.historyEnabled &&
                                    <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                        <HistoryIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                                        <br />
                                        Your history viewing feature is disabled.
                            </Typography>
                                }
                                {this.state.historyEnabled && this.state.resources.length > 0 && this.state.resources.map((resource, index) =>
                                    <Card className={classes.cardContainer} key={resource.id}>
                                        <CardContent style={{ paddingBottom: 5 }}>
                                            <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                                Visited on {new Date(resource.dateViewed).toDateString()}
                                            </Typography>
                                            <Typography className={classes.cardStatus} variant="caption" color="textSecondary">
                                                {resource.resourceType.replace('_', ' ')}
                                            </Typography>
                                        </CardContent>
                                        <CardHeader
                                            className={classes.cardHeader}
                                            classes={{
                                                content: classes.cardHeaderContent

                                            }}
                                            avatar={<Avatar alt={resource.resourceUserName} src={resource.resourceImageUri} className={classes.avatar} />}
                                            title={<a href={resource.uri} className={classes.titleLink}><Typography noWrap variant="h6">{resource.title}</Typography></a>}
                                            subheader={<Typography variant="caption" color="textSecondary">{resource.resourceUserName && resource.resourceUserName ? <>By: <a className={classes.contributorLink} href={resource.resourceUserUri}>{resource.resourceUserName}</a></> : ''}</Typography>}
                                        />
                                        <CardContent style={{ paddingBottom: 5, paddingTop: 0 }}>
                                            <Typography gutterBottom>
                                                {resource.summary.replace(/<(?:.|\n)*?>/gi, " ")}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button disabled={!this.state.enableSubmit} color="secondary" onClick={() => this.setState({ selectedResourceIdToDelete: resource.id, showDeleteDialog: true })}>
                                                Remove
                                    </Button>
                                            <div style={{ marginLeft: "auto", display: 'flex' }}>
                                                <Button disabled={!this.state.enableSubmit} color="primary" href={resource.uri}>
                                                    View now
                                        </Button>
                                            </div>
                                        </CardActions>
                                    </Card>
                                )}
                            </>)
                    }
                    <Dialog
                        open={this.state.showDeleteDialog}
                        onClose={() => this.setState({ showDeleteDialog: false })}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                    >
                        <DialogTitle id="delete-dialog-title">Remove item from viewing history</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant='subtitle2' id="delete-dialog-description">
                                Are you sure you wish to remove this item from your viewing history?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showDeleteDialog: false })} color="default">
                                Cancel
                            </Button>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.deleteResource()} color="secondary">
                                Remove
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.showDeleteAllDialog}
                        onClose={() => this.setState({ showDeleteAllDialog: false })}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                    >
                        <DialogTitle id="delete-dialog-title">Clear viewing history</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant='subtitle2' id="delete-dialog-description">
                                Are you sure you wish to clear all items from your viewing history?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showDeleteAllDialog: false })} color="default">
                                Cancel
                            </Button>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.deleteAllResources()} color="secondary">
                                Clear All
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(ViewingHistory));