import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import ApplicationUser from '../../entities/ApplicationUser';
import Logo from '../../resources/logo.png';
import { AuthenticationService } from '../../services/AuthenticationService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import { GlooButton } from '../../components/GlooButton';
import AppConfig, { AppConfigEnvironment } from '../../entities/AppConfig';
import { Box } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            paddingTop: theme.spacing() * 20,
        },
        signInContainer: {
            height: '100vh'
        },
        logoImage: {
            maxWidth: "90px",
            margin: "30px 0px 20px 0px"
        },
        textButton: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        submitButton: {
            minWidth: '150px',
            minHeight: '50px',
        },
        submitButtonMobile: {
            marginTop: '20px',
            minHeight: '50px'
        },
        buttonContainer: {
            padding: '40px 0px 40px 0px'
        },
        link: {
            textDecoration: 'none'
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        signInPaper: {
            padding: '0px 50px',
            [theme.breakpoints.down('xs')]: {
                margin: '0px 20px',
                padding: '0px 30px'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends RouteComponentProps<any>, WithStyles<typeof styles> {
    user: ApplicationUser | null;
    onSignIn: (user: ApplicationUser) => void;
    config: AppConfig;
}

type State = {
    redirectToReferrer: boolean;
    enableSubmit: boolean,
    email: string;
    password: string;
    errors: FieldValidationError[];
};

class SignIn extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            enableSubmit: true,
            email: '',
            password: '',
            errors: [],
        };
    }

    componentDidMount() {
        document.title = "SermonCentral Account - Sign In";
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.email && this.state.password) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AuthenticationService.signIn(this.state.email, this.state.password, false);

                if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
                    this.setState({ redirectToReferrer: true }, () => this.props.onSignIn(response.data));
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {
        const classes = this.props.classes;

        let stateObject: any = this.props.location.state || { from: { pathname: "/" } };
        let from = stateObject.from;
        let { redirectToReferrer } = this.state;

        if (redirectToReferrer) {
            return <Redirect to={from} />;
        }

        if (this.props.user) {
            return <Redirect to="/" />;
        }

        const glooRedirectUrl = this.props.config.accountSiteUrl + from?.pathname;
        const glooUrl = this.props.config.mainSiteUrl + '/account/gloo/login?redirectUri=' + encodeURIComponent(glooRedirectUrl);
        
        return (
            <Grid className={classes.signInContainer} container direction="column" justify="center" alignItems="center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Grid item>
                    <Paper className={classes.signInPaper}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <img src={Logo} className={classes.logoImage} />
                            <Typography variant="h5">Sign in</Typography>
                            <Typography variant="h6">to your SermonCentral account</Typography>
                        </Grid>
                        <form onSubmit={e => this.handleFormSubmit(e)}>
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Email')}
                                required
                                autoFocus
                                fullWidth
                                id="email"
                                label="Email"
                                type="email"
                                margin="normal"
                                onChange={e => this.setState({ email: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Email') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Password')}
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                margin="normal"
                                onChange={e => this.setState({ password: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Password') }} />}
                            />
                            <Hidden smDown>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Link to="/auth/register" className={classes.link}>
                                        <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Create Account</Button>
                                    </Link>
                                    <Link to="/auth/forgot-password" className={classes.link}>
                                        <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Forgot Password?</Button>
                                    </Link>
                                </Grid>
                                <Grid container direction="row" justify="space-around" spacing={3} alignItems="center" className={classes.buttonContainer}>
                                    <Button variant="contained" size="large" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButton}>Sign In</Button>
                                    <GlooButton url={glooUrl} disabled={!this.state.enableSubmit} style={{minWidth: 220, marginLeft: 10}} />
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Link to="/auth/register" className={classes.link}>
                                        <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Create Account</Button>
                                    </Link>
                                    <Link to="/auth/forgot-password" className={classes.link}>
                                        <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Forgot Password?</Button>
                                    </Link>
                                </Grid>
                                <Box paddingBottom={5}>
                                    <Button fullWidth variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButtonMobile}>Sign In</Button>
                                    <GlooButton url={glooUrl} disabled={!this.state.enableSubmit} fullWidth style={{marginTop: 20}} />
                                </Box>
                            </Hidden>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(SignIn));