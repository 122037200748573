import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TablePagination from '@material-ui/core/TablePagination';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { Redirect, RouteComponentProps } from "react-router-dom";
import ApplicationUser from '../../entities/ApplicationUser';
import SearchResult from '../../entities/SearchResult';
import User from '../../entities/User';
import { AdminService } from '../../services/AdminService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        sort: {
            display: "flex"
        },
        sortButton: {
            paddingRight: "6px",
            fontSize: "13px",
            opacity: .86,
            whiteSpace: "nowrap"
        },
        menuItem: {
            minWidth: "200px",
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px"
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        pos: {
            marginBottom: 12,
        },
        [theme.breakpoints.down('sm')]: {
            cardContainer: {
                width: '100%'
            },
            toolbar: {
                display: 'block',
                padding: 0
            },
            title: {
                float: 'left'
            },
            sort: {
                display: 'block',
                float: 'right'
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
    user: ApplicationUser;
    onSignIn: (user: ApplicationUser) => void;
}

type State = {
    anchorSort: undefined;
    enableSubmit: boolean;
    queryText: string;
    skip: number;
    take: number;
    orderBy: string;
    sortOrder: string;
    searchResult?: SearchResult<User>;
    errors: FieldValidationError[];
};

class Impersonate extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            anchorSort: undefined,
            enableSubmit: true,
            queryText: '',
            skip: 0,
            take: 10,
            orderBy: 'UserId',
            sortOrder: 'Descending',
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Impersonate User";
        await this.search();
    }

    async handleSearchFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        await this.search();
    }

    async impersonate(userId: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const response = await AdminService.impersonate(userId);

            if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
                this.props.onSignIn(response.data);
                this.props.history.push('/');
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    async search() {

        if (!this.state.enableSubmit) {
            return;
        }
        try {
            this.setState({ enableSubmit: false });

            const searchResult = await AdminService.searchUsers(this.state.queryText, this.state.skip, this.state.take, this.state.orderBy, this.state.sortOrder);
            if (searchResult) {
                this.setState({ errors: [], searchResult: searchResult, enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    sortColumn(orderBy: string) {

        var sortOrder = this.state.sortOrder === 'Ascending' ? 'Descending' : 'Ascending';
        if (orderBy !== this.state.orderBy) {
            sortOrder = 'Ascending';
        }

        this.setState({ anchorSort: undefined, orderBy: orderBy, sortOrder: sortOrder }, this.search);
    }

    highlightText(text: string, match: string) {

        if (!text) {
            return '';
        }

        if (!match) {
            return text;
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    render() {
        var classes = this.props.classes;

        if (!this.props.user.isAdmin || this.props.user.inAdminImpersonationMode) {
            return (
                <Redirect to="/" />
            );
        }

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Impersonate User
                            </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={this.state.anchorSort ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorSort: e.currentTarget })}>
                            {this.state.orderBy === 'UserId' &&
                                'Id'}
                            {this.state.orderBy === 'FirstName' &&
                                'First Name'}
                            {this.state.orderBy === 'LastName' &&
                                'Last Name'}
                            {this.state.orderBy === 'Email' &&
                                'Email'}
                            {this.state.orderBy === 'DateCreated' &&
                                'Date Created'}
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={this.state.anchorSort}
                            open={Boolean(this.state.anchorSort)}
                            onClose={(e: any) => this.setState({ anchorSort: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('UserId')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'UserId' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'User Id'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('FirstName')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'FirstName' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'First Name'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('LastName')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'LastName' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Last Name'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Email')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Email' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Email'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('DateCreated')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'DateCreated' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Date Created'} />
                            </MenuItem>

                        </Menu>
                        <IconButton aria-label="Reverse Direction" onClick={e => this.sortColumn(this.state.orderBy)}>
                            {this.state.sortOrder === 'Ascending' ? <ArrowDownward style={{ fontSize: "14px" }} /> : <ArrowUpward style={{ fontSize: "14px" }} />}
                        </IconButton>
                    </div>
                    <div className={classes.search}>
                        <form onSubmit={(e) => this.handleSearchFormSubmit(e)}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={this.state.queryText}
                                onChange={(e) => this.setState({ queryText: e.target.value })}
                            />
                        </form>
                    </div>
                </Toolbar>

                <Grid container direction="column" justify="center" alignItems="center">

                    {!this.state.searchResult &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }

                    {this.state.searchResult && this.state.searchResult.items.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <SearchIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            No results found. Try another query.
                        </Typography>
                    }

                    {this.state.searchResult && this.state.searchResult.items.length > 0 && this.state.searchResult.items.map(item =>
                        <Card className={classes.cardContainer} key={item.id}>
                            <CardContent>
                                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom dangerouslySetInnerHTML={{ __html: 'Id: ' + this.highlightText(item.id.toString(10), this.state.queryText) }} />
                                <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: this.highlightText(item.firstName + ' ' + item.lastName, this.state.queryText) }} />
                                <Typography className={classes.pos} color="textSecondary" dangerouslySetInnerHTML={{ __html: this.highlightText(item.email, this.state.queryText) }} />
                                <Typography component="p">
                                    Member since {new Date(item.dateCreated).toLocaleDateString()}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <div style={{ marginLeft: "auto" }}>
                                    <Button disabled={!this.state.enableSubmit} onClick={() => this.impersonate(item.id)} color="primary">Impersonate</Button>
                                </div>
                            </CardActions>
                        </Card>
                    )}

                </Grid>

                {this.state.searchResult && this.state.searchResult.items.length > 0 &&
                    <TablePagination
                        component="div"
                        count={this.state.searchResult.totalCount}
                        rowsPerPage={this.state.take}
                        page={(this.state.skip / this.state.take)}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={(e, page) => this.setState({ skip: Math.round(page * this.state.take) }, this.search)}
                        onChangeRowsPerPage={(e) => this.setState({ take: parseInt(e.target.value, 10) }, this.search)}
                    />
                }
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(Impersonate));