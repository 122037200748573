import * as React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../../withRoot';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { FieldValidationError, ServerResponse, ServerModelValidationResponse } from '../../services/ServiceHelper';

import { AuthenticationService } from '../../services/AuthenticationService';

import ApplicationUser from '../../entities/ApplicationUser';

import Logo from '../../resources/logo.png';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            paddingTop: theme.spacing() * 20,
        },
        resetPasswordContainer: {
            height: '100vh'
        },
        resetPasswordPaper: {
            padding: '0px 50px',
            maxWidth: 500
        },
        logoImage: {
            maxWidth: "90px",
            margin: "30px 0px 20px 0px"
        },
        textButton: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        submitButton: {
            minWidth: '150px',
            marginBottom: '30px'
        },
        submitButtonMobile: {
            margin: '20px 0px 70px 0px'
        },
        buttonContainer: {
            padding: '30px 0px 70px 0px'
        },
        textLink: {
            textDecoration: 'none'
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            resetPasswordPaper: {
                margin: '0px 20px',
                padding: '0px 30px'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles>, RouteComponentProps<{ email: string, code: string }> {
    user: ApplicationUser | null;
    onSignIn: (user: ApplicationUser) => void;
}

type State = {
    enableSubmit: boolean;
    email: string;
    encodedEmail: string;
    code: string;
    password: string;
    confirmPassword: string;
    errors: FieldValidationError[];
};

class ResetPassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            email: '',
            encodedEmail: props.match.params.email,
            code: props.match.params.code,
            password: '',
            confirmPassword: '',
            errors: [],
        };
    }

    componentDidMount() {
        document.title = "SermonCentral Account - Reset password";
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.email && this.state.password && this.state.confirmPassword) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AuthenticationService.resetPassword(this.state.email, this.state.password, this.state.confirmPassword, this.state.code);

                if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
                    this.props.onSignIn(response.data);
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {

        const classes = this.props.classes;

        if (this.props.user) {
            return <Redirect to="/" />;
        }

        return (
            <Grid className={classes.resetPasswordContainer} container direction="column" justify="center" alignItems="center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbartMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Grid item>
                    <Paper className={classes.resetPasswordPaper}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <img src={Logo} className={classes.logoImage} />
                            <Typography variant="h5">Reset Password</Typography>
                            <Typography variant="subtitle1">Confirm your email address and enter a new password to gain access to your account.</Typography>
                        </Grid>
                        <form onSubmit={e => this.handleFormSubmit(e)}>
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Email')}
                                required
                                autoFocus
                                fullWidth
                                id="email"
                                label="Email"
                                type="email"
                                margin="normal"
                                onChange={e => this.setState({ email: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Email') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Password')}
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                margin="normal"
                                onChange={e => this.setState({ password: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Password') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'ConfirmPassword')}
                                required
                                fullWidth
                                id="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                margin="normal"
                                onChange={e => this.setState({ confirmPassword: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ConfirmPassword') }} />}
                            />
                            <Hidden smDown>
                                <Button variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButton}>Reset Password</Button>
                            </Hidden>
                            <Hidden mdUp>
                                <Button fullWidth variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButtonMobile}>Reset Password</Button>
                            </Hidden>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(ResetPassword));