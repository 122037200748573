import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import ApplicationUser from '../../entities/ApplicationUser';
import Logo from '../../resources/logo.png';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            paddingTop: theme.spacing() * 20,
        },
        forgotPasswordContainer: {
            height: '100vh',
        },
        forgotPasswordPaper: {
            padding: '0px 50px',
            maxWidth: 500
        },
        logoImage: {
            maxWidth: "90px",
            margin: "30px 0px 20px 0px"
        },
        textButton: {
            paddingLeft: '0px',
            paddingRight: '0px',
            marginTop: '50px',
            marginBottom: '50px'
        },
        buttonContainer: {
            padding: '30px 0px 70px 0px'
        },
        link: {
            textDecoration: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            forgotPasswordPaper: {
                margin: '0px 20px',
                padding: '0px 30px'
            }
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser | null;
}

class ForgotPasswordSuccess extends React.Component<Props, {}> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        document.title = "SermonCentral Account - Forgot Password";
    }

    render() {

        const classes = this.props.classes;

        if (this.props.user) {
            return <Redirect to="/" />;
        }

        return (
            <Grid className={classes.forgotPasswordContainer} container direction="column" justify="center" alignItems="center">
                <Grid item>
                    <Paper className={classes.forgotPasswordPaper}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <img src={Logo} className={classes.logoImage} />
                            <Typography variant="h5">Reset Password Link Sent</Typography>
                            <Typography variant="subtitle1">Check your email for a link to reset your password.</Typography>
                        </Grid>
                        <Hidden smDown>
                            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.buttonContainer}>
                                <Link to="/" className={classes.link}>
                                    <Button variant="text" size="medium" color="primary" type="button" className={classes.textButton}>Sign In</Button>
                                </Link>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Link to="/" className={classes.link}>
                                    <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Sign In</Button>
                                </Link>
                            </Grid>
                        </Hidden>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(ForgotPasswordSuccess));