import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import ApplicationUser from '../../entities/ApplicationUser';
import Newsletter from '../../entities/Newsletter';
import { PreferencesService } from '../../services/PreferencesService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';


const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            width: 550
        },
        form: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        submitButton: {
            marginLeft: 'auto',
            minWidth: '150px',
            display: 'block'
        },
        checkBox: {
            padding: '17px'
        },
        formControl: {
            marginTop: theme.spacing() * 1.5,
            marginBottom: theme.spacing() * 1.5
        },
        saveButton: {
            marginLeft: 'auto',
            display: 'block'
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        [theme.breakpoints.down('md')]: {
            root: {
                alignItems: 'normal'
            },
            paper: {
                width: '100%'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    newsletters: Newsletter[];
    selectedNewsletterIds: number[];
    showUpdateSuccessMessage: boolean;
    errors: FieldValidationError[];
}

class NewsletterSubscriptions extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            newsletters: [],
            selectedNewsletterIds: [],
            showUpdateSuccessMessage: false,
            errors: [],
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Newsletter Subscriptions";

        try {
            const newsletterSubscriptions = await PreferencesService.getNewsletterSubscriptions();

            if (newsletterSubscriptions) {
                this.setState({
                    loading: false,
                    enableSubmit: true,
                    newsletters: newsletterSubscriptions.newsletters,
                    selectedNewsletterIds: newsletterSubscriptions.selectedNewsletterIds,
                    errors: [],
                });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    toggleNewsletterSelection = (id: number) => {

        var selectedList: number[] = this.state.selectedNewsletterIds;
        var index = selectedList.indexOf(id);

        if (index < 0) {
            selectedList.push(id);
        } else {
            selectedList.splice(index, 1);
        }

        this.setState({
            selectedNewsletterIds: selectedList
        });
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }
        try {
            this.setState({ enableSubmit: false });
            const response = await PreferencesService.updateNewsletterSubscriptions(this.state.selectedNewsletterIds);

            if (ServerResponse.isServerResponse<null>(response)) {

                this.setState({
                    errors: [],
                    enableSubmit: true,
                    showUpdateSuccessMessage: true
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Newsletter subscriptions updated</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Grid item>
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading &&
                        <Paper className={classes.paper}>
                            <Toolbar>
                                <Typography variant="h5">
                                    Newsletter Subscriptions
                            </Typography>
                            </Toolbar>
                            <form className={classes.form} onSubmit={(e) => this.handleFormSubmit(e)}>
                                <Typography variant="body2" component="h3">
                                    Select the newsletter subscriptions you wish to receive.
                                </Typography>
                                <FormControl className={classes.formControl}>
                                    <FormGroup>
                                        {this.state.newsletters.filter(n => !n.proUsersOnly || this.props.user.proSubscription).map(newsletter => {
                                            return (
                                                <FormControlLabel
                                                    key={newsletter.id}
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.selectedNewsletterIds.indexOf(newsletter.id) >= 0}
                                                            className={classes.checkBox}
                                                            onChange={(e) => this.toggleNewsletterSelection(parseInt(e.target.value, 10))}
                                                            value={newsletter.id.toString()}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={(<span dangerouslySetInnerHTML={{ __html: '<strong>' + newsletter.name + '</strong> <br/> ' + newsletter.description }} />)}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </FormControl>
                                <Button type='submit' variant='contained' color='primary' className={classes.saveButton}>
                                    Save Changes
                            </Button>
                            </form>
                        </Paper>
                    }
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(NewsletterSubscriptions));