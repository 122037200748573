import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import FilterListIcon from '@material-ui/icons/FilterList';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, RouteComponentProps } from 'react-router-dom';
import 'url-search-params-polyfill';
import SermonIllustration from '../../entities/SermonIllustration';
import { ContributionsService } from '../../services/ContributionsService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) =>
    createStyles({
        root: {

        },
        header: {

        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing() * 5,
            },
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing() * 5,
            },
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        pos: {
            marginBottom: 12,
        },
        sortButton: {
            paddingRight: "6px",
            fontSize: "13px",
            opacity: .86,
            whiteSpace: "nowrap"
        },
        menuItem: {
            minWidth: "200px",
        },
        sort: {
            display: "flex"
        },
        infiniteScroll: {
            width: 'auto',
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px"
        },
        ratingStarContainer: {
            verticalAlign: "text-top",
            marginLeft: "4px"
        },
        ratingStar: {
            fontSize: "16px"
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 4,
            right: theme.spacing() * 4,
            [theme.breakpoints.down('md')]: {
                bottom: theme.spacing() * 2,
                right: theme.spacing() * 2,
            },
        },
        extendedFab: {
            marginRight: theme.spacing()
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        tagsAndScriptures: {
            fontStyle: 'italic',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            infiniteScroll: {
                width: '100%',
            },
            cardContainer: {
                width: "100%",
            },
            toolbar: {
                display: 'block',
                padding: 0
            },
            title: {
                float: 'left'
            },
            sort: {
                display: 'block',
                float: 'right'
            },
            filterContainer: {
                float: 'right'
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        },
        rejectionNotes: {
            paddingTop: 10,
            borderTop: '1px solid #d90000'
        }
    });

enum FilterValue {
    Rejected = 0,
    Approved = 1,
    Pending = 3,
    Deleted = 4,
    All = 5
}

interface Props extends RouteComponentProps<null>, WithStyles<typeof styles> {
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    sermonIllustrations: SermonIllustration[];
    filteredSermonIllustrations: SermonIllustration[];
    visibleSermonIllustrationCount: number;
    queryText: string;
    sortOrder: string;
    orderBy: string;
    anchorSort: undefined;
    anchorFilter: undefined;
    filterValue: FilterValue;
    showDeleteDialog: boolean;
    selectedSermonIllustrationIdToDelete?: number;
    showUpdateSuccessMessage: boolean;
    updateSuccessMessage: string;
    errors: FieldValidationError[];
}

class SermonIllustrations extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: true,
            sermonIllustrations: [],
            filteredSermonIllustrations: [],
            visibleSermonIllustrationCount: 10,
            queryText: '',
            sortOrder: 'Descending',
            orderBy: 'Date',
            anchorSort: undefined,
            anchorFilter: undefined,
            filterValue: FilterValue.All,
            showDeleteDialog: false,
            showUpdateSuccessMessage: false,
            updateSuccessMessage: '',
            errors: []
        };
    }

    async componentDidMount() {
        document.title = "SermonCentral Account - My Sermon Illustrations";

        try {
            const sermonIllustrations = await ContributionsService.getSermonIllustrations();

            let filterValue = FilterValue.All;
            const params = new URLSearchParams(this.props.location.search);
            if (params.get('status') && params.get('status')!.match(/rejected/i)) {
                filterValue = FilterValue.Rejected;
            }
            else if (params.get('status') && params.get('status')!.match(/approved/i)) {
                filterValue = FilterValue.Approved;
            }
            else if (params.get('status') && params.get('status')!.match(/pending/i)) {
                filterValue = FilterValue.Pending;
            }

            if (sermonIllustrations) {
                this.setState({ sermonIllustrations, filterValue, loading: false }, this.updateFilteredSermonIllustrations);
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async deleteSermonIllustration() {
        if (this.state.selectedSermonIllustrationIdToDelete) {
            try {
                const response = await ContributionsService.deleteSermonIllustration(this.state.selectedSermonIllustrationIdToDelete);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let sermonIllustrations = this.state.sermonIllustrations;
                    sermonIllustrations = sermonIllustrations.filter(l => l.id !== this.state.selectedSermonIllustrationIdToDelete!);

                    this.setState({
                        sermonIllustrations,
                        selectedSermonIllustrationIdToDelete: undefined,
                        enableSubmit: true,
                        showDeleteDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'The sermon illustration was deleted'
                    }, this.updateFilteredSermonIllustrations);

                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showDeleteDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }

    }

    highlightText(text: string, match: string) {

        if (!text) {
            return '';
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    sortColumn(orderBy: string) {

        var sortOrder = this.state.sortOrder === 'Ascending' ? 'Descending' : 'Ascending';
        if (orderBy !== this.state.orderBy) {
            sortOrder = 'Descending';
        }

        this.setState({ anchorSort: undefined, orderBy, sortOrder }, this.updateFilteredSermonIllustrations);
    }

    updateFilteredSermonIllustrations() {

        let filteredSermonIllustrations = this.state.sermonIllustrations;

        if (this.state.queryText.length >= 0) {
            const query = this.state.queryText.toLowerCase();
            filteredSermonIllustrations = filteredSermonIllustrations.filter(s => {

                if (s.title && s.title.toLowerCase().includes(query)) {
                    return true;
                }

                if (s.intro && s.intro.toLowerCase().includes(query)) {
                    return true;
                }

                if (s.scriptures && s.scriptures.some(sr => sr.toLowerCase().includes(query))) {
                    return true;
                }

                if (s.tags && s.tags.some(tg => tg.toLowerCase().includes(query))) {
                    return true;
                }

                return false;
            });
        }

        if (this.state.filterValue !== 5) {
            filteredSermonIllustrations = filteredSermonIllustrations.filter(s => +s.status === +this.state.filterValue);
        }

        const sortGreaterThanValue = this.state.sortOrder === 'Ascending' ? 1 : -1;
        const sortLessThanValue = this.state.sortOrder === 'Ascending' ? -1 : 1;
        const fieldName = this.state.orderBy.toLowerCase();

        filteredSermonIllustrations.sort((s1, s2) => {

            let s1FieldValue = s1[fieldName];
            if (typeof s1FieldValue === 'string') {
                s1FieldValue = s1FieldValue.toLowerCase();
            }

            let s2FieldValue = s2[fieldName];
            if (typeof s2FieldValue === 'string') {
                s2FieldValue = s2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof s1FieldValue === 'string' && typeof s2FieldValue === 'string') {
                const value = s1FieldValue.localeCompare(s2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (s1FieldValue > s2FieldValue) {
                return sortGreaterThanValue;
            }
            if (s1FieldValue < s2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        this.setState({ filteredSermonIllustrations });
    }

    renderStars = (rating: number) => {
        let val: JSX.Element[] = [];

        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                val.push(<StarIcon key={i} className={this.props.classes.ratingStar} />);
            } else if (i <= Math.ceil(rating)) {
                val.push(<StarHalfIcon key={i} className={this.props.classes.ratingStar} />);
            }
        }

        return val;
    }

    render() {

        const classes = this.props.classes;
        const visibleSermonIllustrations = this.state.filteredSermonIllustrations.slice(0, this.state.visibleSermonIllustrationCount);

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <CheckCircleIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            My Sermon Illustrations
                        </Typography>
                        {!this.state.loading && this.state.sermonIllustrations.length > 0 && this.state.sermonIllustrations.length === this.state.filteredSermonIllustrations.length &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.sermonIllustrations.length} shared sermon illustration{this.state.sermonIllustrations.length === 1 ? '' : 's'}
                            </Typography>
                        }
                        {!this.state.loading && this.state.sermonIllustrations.length > 0 && this.state.sermonIllustrations.length !== this.state.filteredSermonIllustrations.length &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.filteredSermonIllustrations.length} match{this.state.filteredSermonIllustrations.length === 1 ? '' : 'es'} of {this.state.sermonIllustrations.length} shared sermon illustration{this.state.sermonIllustrations.length === 1 ? '' : 's'}
                            </Typography>
                        }
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={this.state.anchorSort ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorSort: e.currentTarget })}>
                            {this.state.orderBy === 'Title' && 'Title'}
                            {this.state.orderBy === 'Date' && 'Date Created'}
                            {this.state.orderBy === 'Rating' && 'User Rating'}
                            {this.state.orderBy === 'Views' && 'Total Views'}
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={this.state.anchorSort}
                            open={Boolean(this.state.anchorSort)}
                            onClose={(e: any) => this.setState({ anchorSort: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Title')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Title' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Title'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Date')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Date' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Date Created'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Rating')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Rating' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'User Rating'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Views')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Views' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Total Views'} />
                            </MenuItem>

                        </Menu>

                        <IconButton aria-label="Reverse Direction" onClick={e => this.sortColumn(this.state.orderBy)}>
                            {this.state.sortOrder === 'Descending' ? <ArrowDownwardIcon style={{ fontSize: "14px" }} /> : <ArrowUpwardIcon style={{ fontSize: "14px" }} />}
                        </IconButton>
                    </div>
                    <div className={classes.filterContainer}>
                        <IconButton aria-label="Filter Sermon Illustrations" aria-haspopup="true" aria-owns={this.state.anchorFilter ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorFilter: e.currentTarget })}>
                            <FilterListIcon style={{ fontSize: "14px" }} />
                        </IconButton>
                        <Menu
                            id="filter-menu"
                            anchorEl={this.state.anchorFilter}
                            open={Boolean(this.state.anchorFilter)}
                            onClose={(e: any) => this.setState({ anchorFilter: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.All }, this.updateFilteredSermonIllustrations)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.All ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText className={this.state.filterValue !== FilterValue.All ? classes.unselectedListItem : ""} inset primary="All Sermon Illustrations" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.Approved }, this.updateFilteredSermonIllustrations)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.Approved ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText className={this.state.filterValue !== FilterValue.Approved ? classes.unselectedListItem : ""} inset primary="Approved" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.Pending }, this.updateFilteredSermonIllustrations)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.Pending ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText className={this.state.filterValue !== FilterValue.Pending ? classes.unselectedListItem : ""} inset primary="Pending" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.Rejected }, this.updateFilteredSermonIllustrations)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.Rejected ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText className={this.state.filterValue !== FilterValue.Rejected ? classes.unselectedListItem : ""} inset primary="Rejected" />
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={this.state.queryText}
                            onChange={(e) => this.setState({ queryText: e.target.value, visibleSermonIllustrationCount: 10 }, this.updateFilteredSermonIllustrations)}
                        />
                    </div>
                </Toolbar>
                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading && this.state.sermonIllustrations.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <InsertCommentIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You haven't shared any sermon illustrations yet. Add one below.
                        </Typography>
                    }
                    {!this.state.loading && this.state.sermonIllustrations.length > 0 && visibleSermonIllustrations.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <SearchIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            No matches found.
                        </Typography>
                    }
                    {!this.state.loading && visibleSermonIllustrations.length > 0 &&
                        <InfiniteScroll
                            className={classes.infiniteScroll}
                            pageStart={0}
                            threshold={0}
                            loadMore={() => this.setState({ visibleSermonIllustrationCount: this.state.visibleSermonIllustrationCount + 10 })}
                            hasMore={visibleSermonIllustrations.length < this.state.filteredSermonIllustrations.length}
                            useWindow={false}
                            getScrollParent={() => document.getElementById('main')}
                            loader={<div key="loader" style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading...
                                </Typography>
                            </div>}
                        >
                            {visibleSermonIllustrations.map(illustration =>
                                <Card className={classes.cardContainer} key={illustration.id}>
                                    <CardContent>
                                        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                            {new Date(illustration.date).toLocaleDateString()}
                                        </Typography>
                                        <Typography className={classes.cardStatus} variant="caption" color="textSecondary">
                                            {FilterValue[illustration.status]}
                                        </Typography>
                                        <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: this.highlightText(illustration.title, this.state.queryText) }} />

                                        <Typography className={classes.pos} color="textSecondary">
                                            {illustration.views} {illustration.views === 1 ? "view" : "views"} | {illustration.ratingCount} {illustration.ratingCount === 1 ? "rating" : "ratings"}
                                            <span className={classes.ratingStarContainer}>{this.renderStars(illustration.rating)}</span>
                                        </Typography>
                                        <Typography component="p" dangerouslySetInnerHTML={{ __html: this.highlightText(illustration.intro, this.state.queryText) }} />

                                    </CardContent>
                                    <CardActions>
                                        <Button color="secondary" onClick={e => this.setState({ showDeleteDialog: true, selectedSermonIllustrationIdToDelete: illustration.id })}>
                                            Delete
                                        </Button>
                                        <div style={{ marginLeft: "auto" }}>
                                            <Button href={illustration.url} target='_blank'>
                                                View
                                                </Button>
                                            <Link to={`/contributions/sermon-illustrations/edit/${illustration.id}`} style={{ textDecoration: 'none' }}>
                                                <Button color="primary" >
                                                    Edit
                                                </Button>
                                            </Link>
                                        </div>
                                    </CardActions>
                                    <Divider />
                                    <CardContent>
                                        <Typography className={classes.tagsAndScriptures} color="textSecondary">
                                            <strong>Scripture:</strong> {illustration.scriptures.length > 0 ? <span dangerouslySetInnerHTML={{ __html: this.highlightText(illustration.scriptures.join(', '), this.state.queryText) }} /> : 'None'}
                                            <br />
                                            <strong>Tags:</strong> {illustration.tags.length > 0 ? <span dangerouslySetInnerHTML={{ __html: this.highlightText(illustration.tags.join(', '), this.state.queryText) }} /> : 'None'}
                                        </Typography>
                                    </CardContent>
                                    {illustration.rejectionNotes &&
                                        <CardContent>
                                            <div className={classes.rejectionNotes}>
                                                <Typography variant="body2" color="secondary"><strong>Notes from your reviewer</strong></Typography>
                                                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: illustration.rejectionNotes.replace(/\n/g, '<br>') }} />
                                            </div>
                                        </CardContent>
                                    }
                                </Card>
                            )}
                        </InfiniteScroll>
                    }
                </Grid>
                <Dialog
                    open={this.state.showDeleteDialog}
                    onClose={e => this.setState({ showDeleteDialog: false })}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">Delete Sermon Illustration</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle2' id="delete-dialog-description">
                            Are you sure you wish to delete this sermon illustration?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.setState({ showDeleteDialog: false })} color="default">
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteSermonIllustration()} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Link to="/contributions/sermon-illustrations/add">
                    <Fab variant="extended" color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon className={classes.extendedFab} />
                        Add Illustration
                    </Fab>
                </Link>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(SermonIllustrations));