import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import LanguageIcon from '@material-ui/icons/Language';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import * as d3 from 'd3';
import * as d3GeoProjection from 'd3-geo-projection';
import * as React from 'react';
import * as topojson from "topojson-client";
import Country from '../../entities/Country';
import Sermon from '../../entities/Sermon';
import SermonIllustration from '../../entities/SermonIllustration';
import USState from '../../entities/USState';
import { ContributionsService, SermonIllustrationRegionViewSummary, SermonRegionViewSummary } from '../../services/ContributionsService';
import { FieldValidationError } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing() * 5,
            },
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing() * 5,
            },
        },
        gridContainer: {
            paddingTop: theme.spacing() * 2,
            paddingRight: theme.spacing() * 25,
            paddingBottom: theme.spacing() * 2,
            paddingLeft: theme.spacing() * 25,
            ['@media (max-width: 1700px)']: {
                paddingTop: theme.spacing() * 2,
                paddingRight: theme.spacing() * 10,
                paddingBottom: theme.spacing() * 2,
                paddingLeft: theme.spacing() * 10,
            },
            ['@media (max-width: 1500px)']: {
                paddingTop: theme.spacing() * 2,
                paddingRight: theme.spacing() * 5,
                paddingBottom: theme.spacing() * 2,
                paddingLeft: theme.spacing() * 5,
            },
            [theme.breakpoints.down('md')]: {
                padding: 0
            }
        },
        grid: {
            flexGrow: 1
        },
        mapCard: {
            height: '433px',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            }
        },
        mapContainer: {
            marginTop: '10px',
            display: 'inline-block',
            position: 'relative',
            paddingBottom: '100%',
            verticalAlign: 'top',
            overflow: 'hidden',
            height: "100%",
            width: '100%'
        },
        worldMap: {
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            "& path": {
                strokeLinejoin: "round",
                strokeLinecap: "round",
                fill: "rgb(163, 219, 241)",
                stroke: "#9d9d9d",
                strokeWidth: ".5px",
                opacity: 0.8
            },
            "& .names": {
                fill: "none",
                stroke: "#fff",
                strokeLinejoin: "round"
            },
        },
        stateMap: {
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            "& path": {
                strokeLinejoin: "round",
                strokeLinecap: "round",
                fill: "rgb(163, 219, 241)",
                stroke: "#9d9d9d",
                strokeWidth: ".5px",
                opacity: 0.8
            },
            "& .names": {
                fill: "none",
                stroke: "#fff",
                strokeLinejoin: "round"
            },
        },
        mapLoader: {
            textAlign: 'center'
        },
        tableWrapper: {
            overflowX: 'auto',
        },
        tableToolbar: {
            height: '2rem'
        },
        [theme.breakpoints.down('sm')]: {
            toolbar: {
                display: 'flex',
                flexWrap: 'wrap',
                padding: 0
            },
            title: {
                float: 'left'
            },
            regionContainer: {
                float: 'right'
            },
            timeframeContainer: {
                float: 'right'
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    width: Breakpoint;
}

type State = {
    anchorRegion: undefined;
    region: string;
    anchorTimeframe: undefined;
    timeframe: string;
    sermons: Sermon[] | undefined;
    sermonIllustrations: SermonIllustration[] | undefined;
    countries: Country[] | undefined;
    topRegions: { name: string, views: number, percent: number }[] | undefined,
    topRegionsQueryText: string;
    topRegionsOrderBy: string;
    topRegionsOrder: string;
    topRegionsRowsPerPage: number;
    topRegionsPage: number;
    topContributions: { id: number, name: string, type: string, date: Date, views: number, percent: number }[] | undefined,
    topContributionsQueryText: string;
    topContributionsOrderBy: string;
    topContributionsOrder: string;
    topContributionsRowsPerPage: number;
    topContributionsPage: number;
    usStates: USState[] | undefined;
    sermonRegionViewSummaries: SermonRegionViewSummary[] | undefined;
    sermonIllustrationRegionViewSummaries: SermonIllustrationRegionViewSummary[] | undefined;
    loadingMap: boolean;
    worldMapData: any;
    usMapData: any;
    errors: FieldValidationError[];
};

class Home extends React.Component<Props, State> {

    private mapRef: SVGGElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            anchorRegion: undefined,
            region: 'world',
            anchorTimeframe: undefined,
            timeframe: 'last7days',
            sermons: undefined,
            sermonIllustrations: undefined,
            countries: undefined,
            topRegions: undefined,
            topRegionsQueryText: '',
            topRegionsOrderBy: 'views',
            topRegionsOrder: 'desc',
            topRegionsRowsPerPage: 10,
            topRegionsPage: 0,
            topContributions: undefined,
            topContributionsQueryText: '',
            topContributionsOrderBy: 'views',
            topContributionsOrder: 'desc',
            topContributionsRowsPerPage: 10,
            topContributionsPage: 0,
            usStates: undefined,
            sermonRegionViewSummaries: undefined,
            sermonIllustrationRegionViewSummaries: undefined,
            loadingMap: true,
            worldMapData: undefined,
            usMapData: undefined,
            errors: [],
        };
    }

    componentDidMount() {

        document.title = "SermonCentral Account - Contribution Dashboard";

        ContributionsService.getSermons()
            .then((sermons) => {
                this.setState({ sermons: sermons || [] }, this.updateMap);
            });

        ContributionsService.getSermonIllustrations()
            .then((sermonIllustrations) => {
                this.setState({ sermonIllustrations: sermonIllustrations || [] }, this.updateMap);
            });

        ContributionsService.getCountries()
            .then((countries) => {
                this.setState({ countries: countries || [] }, this.updateMap);
            });

        ContributionsService.getUSStates()
            .then((usStates) => {
                this.setState({ usStates: usStates || [] }, this.updateMap);
            });

        this.updateRegion();

        axios.get('/data/world-data.json', { headers: { Pragma: 'no-cache' } })
            .then(response => {

                // Request of shadow-gm to remove Antartica from the map, done by country code (ATA)
                let data = response.data;
                data.features = data.features.filter((f: any) => f.id !== 'ATA');
                this.setState({ worldMapData: data }, this.updateMap);
            });

        axios.get('/data/usStates-data.json', { headers: { Pragma: 'no-cache' } })
            .then(response => {
                this.setState({ usMapData: response.data }, this.updateMap);
            });
    }

    updateRegion() {

        this.setState({
            loadingMap: true,
            sermonRegionViewSummaries: undefined,
            sermonIllustrationRegionViewSummaries: undefined,
            topRegionsQueryText: '',
            topRegionsOrder: 'desc',
            topRegionsOrderBy: 'views',
            topRegionsPage: 0,
            topRegionsRowsPerPage: 10,
            topContributionsQueryText: '',
            topContributionsOrder: 'desc',
            topContributionsOrderBy: 'views',
            topContributionsPage: 0,
            topContributionsRowsPerPage: 10
        }, () => { this.updateTopRegions(); this.updateTopContributions(); });

        ContributionsService.getSermonRegionViewSummaries(this.state.timeframe)
            .then((sermonRegionViewSummaries) => {
                this.setState({ sermonRegionViewSummaries: sermonRegionViewSummaries || [] }, this.updateMap);
            });

        ContributionsService.getSermonIllustrationRegionViewSummaries(this.state.timeframe)
            .then((sermonIllustrationRegionViewSummaries) => {
                this.setState({ sermonIllustrationRegionViewSummaries: sermonIllustrationRegionViewSummaries || [] }, this.updateMap);
            });
    }

    updateMap() {

        if (!this.mapRef) {
            return;
        }

        if (this.state.region === 'us') {
            this.updateUSMap();
        }
        else {
            this.updateWorldMap();
        }

        this.updateTopRegions();
        this.updateTopContributions();
    }

    updateTopRegions() {

        this.setState({ topRegions: undefined });

        if (!(this.state.countries && this.state.usStates && this.state.sermonRegionViewSummaries && this.state.sermonIllustrationRegionViewSummaries)) {
            return;
        }

        if (this.state.region === 'us') {
            this.updateTopStates();
        }
        else {
            this.updateTopCountries();
        }
    }

    updateTopStates() {

        let filteredUSStates = this.state.usStates!;

        if (this.state.topRegionsQueryText.length >= 0) {

            const query = this.state.topRegionsQueryText.toLowerCase();
            filteredUSStates = filteredUSStates.filter(c => {

                if (c.name && c.name.toLowerCase().includes(query)) {
                    return true;
                }

                return false;
            });
        }

        const grandTotalViews = this.getTotalViews()!;

        const topUSStates = filteredUSStates.map(c => {

            const name = c.name;
            let views = 0;

            const filteredSermonViews = this.state.sermonRegionViewSummaries!.filter(s => s.countryCode === 'US' && s.regionName === c.name);

            if (filteredSermonViews.length > 0) {
                views += filteredSermonViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const filteredSermonIllustrationViews = this.state.sermonIllustrationRegionViewSummaries!.filter(s => s.countryCode === 'US' && s.regionName === c.name);

            if (filteredSermonIllustrationViews.length > 0) {
                views += filteredSermonIllustrationViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const percent = +((views / grandTotalViews) * 100).toFixed(1);
            return {
                name,
                views,
                percent
            };
        });

        const sortGreaterThanValue = this.state.topRegionsOrder === 'asc' ? 1 : -1;
        const sortLessThanValue = this.state.topRegionsOrder === 'asc' ? -1 : 1;
        const fieldName = this.state.topRegionsOrderBy;

        topUSStates.sort((c1, c2) => {

            let c1FieldValue = c1[fieldName];
            if (typeof c1FieldValue === 'string') {
                c1FieldValue = c1FieldValue.toLowerCase();
            }

            let c2FieldValue = c2[fieldName];
            if (typeof c2FieldValue === 'string') {
                c2FieldValue = c2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof c1FieldValue === 'string' && typeof c2FieldValue === 'string') {
                const value = c1FieldValue.localeCompare(c2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (c1FieldValue > c2FieldValue) {
                return sortGreaterThanValue;
            }

            if (c1FieldValue < c2FieldValue) {
                return sortLessThanValue;
            }

            return 0;
        });

        this.setState({ topRegions: topUSStates });
    }

    updateTopCountries() {

        let filteredCountries = this.state.countries!;

        if (this.state.topRegionsQueryText.length >= 0) {

            const query = this.state.topRegionsQueryText.toLowerCase();
            filteredCountries = filteredCountries.filter(c => {

                if (c.name && c.name.toLowerCase().includes(query)) {
                    return true;
                }

                return false;
            });
        }

        const grandTotalViews = this.getTotalViews()!;

        const topCountries = filteredCountries.map(c => {

            const name = c.name;
            let views = 0;

            const filteredSermonViews = this.state.sermonRegionViewSummaries!.filter(s => s.countryCode === c.twoCharCode);

            if (filteredSermonViews.length > 0) {
                views += filteredSermonViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const filteredSermonIllustrationViews = this.state.sermonIllustrationRegionViewSummaries!.filter(s => s.countryCode === c.twoCharCode);

            if (filteredSermonIllustrationViews.length > 0) {
                views += filteredSermonIllustrationViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const percent = +((views / grandTotalViews) * 100).toFixed(1);
            return {
                name,
                views,
                percent
            };
        });

        const sortGreaterThanValue = this.state.topRegionsOrder === 'asc' ? 1 : -1;
        const sortLessThanValue = this.state.topRegionsOrder === 'asc' ? -1 : 1;
        const fieldName = this.state.topRegionsOrderBy;

        topCountries.sort((c1, c2) => {

            let c1FieldValue = c1[fieldName];
            if (typeof c1FieldValue === 'string') {
                c1FieldValue = c1FieldValue.toLowerCase();
            }

            let c2FieldValue = c2[fieldName];
            if (typeof c2FieldValue === 'string') {
                c2FieldValue = c2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof c1FieldValue === 'string' && typeof c2FieldValue === 'string') {
                const value = c1FieldValue.localeCompare(c2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (c1FieldValue > c2FieldValue) {
                return sortGreaterThanValue;
            }
            if (c1FieldValue < c2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        this.setState({ topRegions: topCountries });
    }

    updateTopContributions() {

        this.setState({ topContributions: undefined });

        if (!(this.state.sermons && this.state.sermonIllustrations && this.state.sermonRegionViewSummaries && this.state.sermonIllustrationRegionViewSummaries)) {
            return;
        }

        const grandTotalViews = this.getTotalViews()!;

        let filteredSermons = this.state.sermons!;

        if (this.state.topContributionsQueryText.length >= 0) {

            const query = this.state.topContributionsQueryText.toLowerCase();
            filteredSermons = filteredSermons.filter(c => {

                if (c.title && c.title.toLowerCase().includes(query)) {
                    return true;
                }

                return false;
            });
        }

        const topSermons = filteredSermons.map(c => {

            const id = c.id;
            const name = c.title;
            const type = 'Sermon';
            const date = new Date(c.date);
            let views = 0;

            let filteredSermonViews = this.state.sermonRegionViewSummaries!.filter(s => s.sermonId === c.id);

            if (this.state.region === 'us') {
                filteredSermonViews = filteredSermonViews.filter(s => s.countryCode === 'US');
            }

            if (filteredSermonViews.length > 0) {
                views += filteredSermonViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const percent = +((views / grandTotalViews) * 100).toFixed(1);
            return {
                id,
                name,
                type,
                date,
                views,
                percent
            };
        });

        let filteredSermonIllustrations = this.state.sermonIllustrations!;

        if (this.state.topContributionsQueryText.length >= 0) {

            const query = this.state.topContributionsQueryText.toLowerCase();
            filteredSermonIllustrations = filteredSermonIllustrations.filter(c => {

                if (c.title && c.title.toLowerCase().includes(query)) {
                    return true;
                }

                return false;
            });
        }

        const topSermonIllustrations = filteredSermonIllustrations.map(c => {

            const id = c.id;
            const name = c.title;
            const type = 'Illustration';
            const date = new Date(c.date);
            let views = 0;

            let filteredSermonIllustrationViews = this.state.sermonIllustrationRegionViewSummaries!.filter(s => s.sermonIllustrationId === c.id);

            if (this.state.region === 'us') {
                filteredSermonIllustrationViews = filteredSermonIllustrationViews.filter(s => s.countryCode === 'US');
            }

            if (filteredSermonIllustrationViews.length > 0) {
                views += filteredSermonIllustrationViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const percent = +((views / grandTotalViews) * 100).toFixed(1);
            return {
                id,
                name,
                type,
                date,
                views,
                percent
            };
        });

        const topContributions = topSermons.concat(topSermonIllustrations);

        const sortGreaterThanValue = this.state.topContributionsOrder === 'asc' ? 1 : -1;
        const sortLessThanValue = this.state.topContributionsOrder === 'asc' ? -1 : 1;
        const fieldName = this.state.topContributionsOrderBy;

        topContributions.sort((c1, c2) => {

            let c1FieldValue = c1[fieldName];
            if (typeof c1FieldValue === 'string') {
                c1FieldValue = c1FieldValue.toLowerCase();
            }

            let c2FieldValue = c2[fieldName];
            if (typeof c2FieldValue === 'string') {
                c2FieldValue = c2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof c1FieldValue === 'string' && typeof c2FieldValue === 'string') {
                const value = c1FieldValue.localeCompare(c2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (c1FieldValue > c2FieldValue) {
                return sortGreaterThanValue;
            }
            if (c1FieldValue < c2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        this.setState({ topContributions: topContributions });
    }

    updateUSMap() {

        var classes = this.props.classes;

        if (!(this.state.usMapData && this.state.usStates && this.state.sermonRegionViewSummaries && this.state.sermonIllustrationRegionViewSummaries)) {
            return;
        }

        this.setState({ loadingMap: true });

        this.state.usMapData.objects.states.geometries.forEach((geometry: any) => {

            let views: number = 0;
            const usState = this.state.usStates!.filter(c => c.postalAbbreviation === geometry.code)[0];
            if (!usState) {
                geometry.views = views;
                return;
            }

            const filteredSermonViews = this.state.sermonRegionViewSummaries!.filter(s => s.countryCode === 'US' && s.regionName === usState.name);

            if (filteredSermonViews.length > 0) {
                views += filteredSermonViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const filteredSermonIllustrationViews = this.state.sermonIllustrationRegionViewSummaries!.filter(s => s.countryCode === 'US' && s.regionName === usState.name);

            if (filteredSermonIllustrationViews.length > 0) {
                views += filteredSermonIllustrationViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            geometry.views = views;
        });

        const rangeArray: Array<any> = [];

        rangeArray.push('#ffffff');
        rangeArray.push('#cde8f3');
        rangeArray.push('#b4dcee');
        rangeArray.push('#9bd1e8');
        rangeArray.push('#82c6e3');
        rangeArray.push('#69badd');
        rangeArray.push('#50afd7');
        rangeArray.push('#36a3d2');
        rangeArray.push('#1d98cc');
        rangeArray.push('#058dc7');
        rangeArray.push('#0376ae');
        rangeArray.push('#026096');
        rangeArray.push('#01497e');
        rangeArray.push('#003366');

        const color = d3.scaleQuantile()
            .domain([0, 1, 5, 10, 20, 50, 100, 250, 400, 800, 1500, 4000, 10000, 20000])
            .range(rangeArray);

        const component = this;
        const svg = d3.select(this.mapRef);
        svg.html(null);

        const projection = d3.geoAlbersUsa();
        var path: any = d3.geoPath(projection);

        svg.attr("preserveAspectRatio", "xMidYMid meet")
            .attr("viewBox", "-150 0 1200 600")
            .attr('class', classes.stateMap)
            .append("g")
            .selectAll("path")
            .data(topojson.feature(this.state.usMapData, this.state.usMapData.objects.states).features)
            .enter()
            .append("path")
            .attr("d", path)
            .style('fill', (d: any) => {
                const geometry = component.state.usMapData.objects.states.geometries.find((geo: any) => geo.id === d.id);
                return color(geometry.views);
            })
            .append('svg:title')
            .text((d: any) => {
                const geometry = component.state.usMapData.objects.states.geometries.find((geo: any) => geo.id === d.id);
                return geometry.name + ' - ' + ('toLocaleString' in Number ? geometry.views.toLocaleString() : geometry.views.toString(10)) + ' view' + (geometry.views !== 1 ? 's' : '');
            });

        svg.append("path")
            .datum(topojson.mesh(this.state.usMapData, this.state.usMapData.objects.states, (a: any, b: any) => a.id !== b.id))
            .attr("class", "names")
            .attr("d", path);

        this.setState({ loadingMap: false });
    }

    updateWorldMap() {

        var classes = this.props.classes;

        if (!(this.state.worldMapData && this.state.countries && this.state.sermonRegionViewSummaries && this.state.sermonIllustrationRegionViewSummaries)) {
            return;
        }

        this.setState({ loadingMap: true });

        this.state.worldMapData.features.forEach((feature: any) => {

            let views: number = 0;
            const country = this.state.countries!.filter(c => c.threeCharCode === feature.id)[0];
            if (!country) {
                feature.views = views;
                return;
            }

            const filteredSermonViews = this.state.sermonRegionViewSummaries!.filter(s => s.countryCode === country.twoCharCode);

            if (filteredSermonViews.length > 0) {
                views += filteredSermonViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            const filteredSermonIllustrationViews = this.state.sermonIllustrationRegionViewSummaries!.filter(s => s.countryCode === country.twoCharCode);

            if (filteredSermonIllustrationViews.length > 0) {
                views += filteredSermonIllustrationViews
                    .map(s => s.viewCount)
                    .reduce((totalViews, currentViews) => totalViews + currentViews);
            }

            feature.views = views;
        });

        const rangeArray: Array<any> = [];

        rangeArray.push('#ffffff');
        rangeArray.push('#cde8f3');
        rangeArray.push('#b4dcee');
        rangeArray.push('#9bd1e8');
        rangeArray.push('#82c6e3');
        rangeArray.push('#69badd');
        rangeArray.push('#50afd7');
        rangeArray.push('#36a3d2');
        rangeArray.push('#1d98cc');
        rangeArray.push('#058dc7');
        rangeArray.push('#0376ae');
        rangeArray.push('#026096');
        rangeArray.push('#01497e');
        rangeArray.push('#003366');

        const color = d3.scaleQuantile()
            .domain([0, 1, 5, 10, 20, 50, 100, 250, 400, 800, 1500, 4000, 10000, 20000])
            .range(rangeArray);

        const component = this;
        const svg = d3.select(this.mapRef);
        svg.html(null);

        const projection = d3GeoProjection.geoRobinson();
        var path: any = d3.geoPath(projection);

        svg.attr("preserveAspectRatio", "xMidYMid meet")
            .attr("viewBox", "0 0 1000 600")
            .attr('class', classes.worldMap)
            .append("g")
            .selectAll("path")
            .data(this.state.worldMapData.features)
            .enter()
            .append("path")
            .attr("d", path)
            .style('fill', (d: any) => {
                return color(d.views);
            })
            .on('click', function (d: any) {
                if (d.id === "USA") {
                    component.setState({ region: 'us' }, component.updateMap);
                }
            })
            .append('svg:title')
            .text((d: any) => {
                return d.properties.name + ' - ' + ('toLocaleString' in Number ? d.views.toLocaleString() : d.views.toString(10)) + ' view' + (d.views !== 1 ? 's' : '');
            });

        svg.append("path")
            .datum(topojson.mesh(this.state.worldMapData, this.state.worldMapData.features, (a: any, b: any) => a.id !== b.id))
            .attr("class", "names")
            .attr("d", path);

        this.setState({ loadingMap: false });
    }

    getTimeframeDisplayName(timeframe: string) {

        switch (timeframe) {
            case 'realtime':
                return 'Real-Time';

            case 'last7days':
            default:
                return 'Last 7 Days';

            case 'last30days':
                return 'Last 30 Days';

            case 'last60days':
                return 'Last 60 Days';

            case 'last90days':
                return 'Last 90 Days';

            case 'last180days':
                return 'Last 180 Days';
        }
    }

    getTotalViews(): number | undefined {

        if (!this.state.sermonRegionViewSummaries || !this.state.sermonIllustrationRegionViewSummaries) {
            return undefined;
        }

        let retVal: number = 0;

        if (this.state.sermonRegionViewSummaries.length > 0) {

            retVal += this.state.sermonRegionViewSummaries.filter(s => this.state.region === 'us' ? s.countryCode === 'US' : true)
                .map(s => s.viewCount)
                .reduce((totalViews, currentViews) => totalViews + currentViews);
        }

        if (this.state.sermonIllustrationRegionViewSummaries.length > 0) {

            retVal += this.state.sermonIllustrationRegionViewSummaries.filter(s => this.state.region === 'us' ? s.countryCode === 'US' : true)
                .map(s => s.viewCount)
                .reduce((totalViews, currentViews) => totalViews + currentViews);
        }

        return retVal;
    }

    getSermonsViewed(): number | undefined {

        if (!this.state.sermonRegionViewSummaries) {
            return undefined;
        }

        let retVal: number = 0;

        retVal += this.state.sermonRegionViewSummaries.filter(s => this.state.region === 'us' ? s.countryCode === 'US' : true)
            .map(s => s.sermonId)
            .filter((value, index, self) => self.indexOf(value) === index)
            .length;

        return retVal;
    }

    getRegionsReached(): number | undefined {

        if (!this.state.sermonRegionViewSummaries || !this.state.sermonIllustrationRegionViewSummaries) {
            return undefined;
        }

        let retVal: number = 0;

        const sermonRegions = this.state.sermonRegionViewSummaries.filter(s => this.state.region === 'us' ? s.countryCode === 'US' : true)
            .map(s => this.state.region === 'us' ? s.regionName : s.countryCode)
            .filter((region, index, self) => self.indexOf(region) === index);

        const sermonIllustrationRegions = this.state.sermonIllustrationRegionViewSummaries.filter(s => this.state.region === 'us' ? s.countryCode === 'US' : true)
            .map(s => this.state.region === 'us' ? s.regionName : s.countryCode)
            .filter((region, index, self) => self.indexOf(region) === index);

        retVal += sermonRegions.concat(sermonIllustrationRegions).filter((region, index, self) => self.indexOf(region) === index).length;

        return retVal;
    }

    highlightText(text: string, match: string) {

        if (!text) {
            return '';
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    sortTopRegionsColumn(orderBy: string) {

        var sortOrder = this.state.topRegionsOrder === 'asc' ? 'desc' : 'asc';
        if (orderBy !== this.state.topRegionsOrderBy) {
            sortOrder = 'Descending';
        }

        this.setState({ topRegionsOrder: sortOrder, topRegionsOrderBy: orderBy }, this.updateTopRegions);
    }

    sortTopContributionsColumn(orderBy: string) {

        var sortOrder = this.state.topContributionsOrder === 'asc' ? 'desc' : 'asc';
        if (orderBy !== this.state.topContributionsOrderBy) {
            sortOrder = 'Descending';
        }

        this.setState({ topContributionsOrder: sortOrder, topContributionsOrderBy: orderBy }, this.updateTopContributions);
    }

    renderTotalViewsCard() {

        const totalViews = this.getTotalViews();
        return (
            <Card>
                <CardContent>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        Total Views
                                </Typography>
                    <Typography variant="h4" component="h2">
                        {totalViews !== undefined ? ('toLocaleString' in Number ? totalViews.toLocaleString() : totalViews.toString(10)) : '...'}
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        {this.getTimeframeDisplayName(this.state.timeframe)}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    renderSermonsViewedCard() {

        const sermonsViewed = this.getSermonsViewed();
        return (
            <Card>
                <CardContent>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        Sermons Viewed
                        </Typography>
                    <Typography variant="h4" component="h2">
                        {sermonsViewed !== undefined ? ('toLocaleString' in Number ? sermonsViewed.toLocaleString() : sermonsViewed.toString(10)) : '...'}
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        {this.getTimeframeDisplayName(this.state.timeframe)}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    renderRegionsReachedCard() {

        const regionsReached = this.getRegionsReached();

        return (
            <Card>
                <CardContent>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                        {this.state.region === 'world' ? 'Countries' : 'States'} Reached
                    </Typography>
                    <Typography variant="h4" component="h2">
                        {regionsReached !== undefined ? ('toLocaleString' in Number ? regionsReached.toLocaleString() : regionsReached.toString(10)) : '...'}
                    </Typography>
                    <Typography variant="subtitle2" component="p">
                        {this.getTimeframeDisplayName(this.state.timeframe)}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    renderMapCard() {
        var classes = this.props.classes;

        return (
            <Card className={classes.mapCard}>
                {this.state.loadingMap &&
                    <div className={classes.mapLoader}>
                        <CircularProgress style={{ marginTop: "150px" }} />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading Map...
                        </Typography>
                    </div>
                }
                <div className={classes.mapContainer}>
                    <svg style={{ display: (this.state.loadingMap ? 'none' : 'block'), maxHeight: '500px' }} className={classes.map} ref={(input) => { this.mapRef = input; }} />
                </div>
            </Card>
        );
    }

    renderTopCountriesCard() {

        var classes = this.props.classes;

        return (
            <Card style={{ overflow: 'visible' }}>
                <Toolbar className={classes.tableToolbar}>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            Top {this.state.region === 'us' ? 'States' : 'Countries'}
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    {this.state.topRegions &&
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={this.state.topRegionsQueryText}
                                onChange={(e) => this.setState({ topRegionsQueryText: e.target.value }, this.updateTopRegions)}
                            />
                        </div>
                    }
                </Toolbar>
                {!this.state.topRegions &&
                    <div style={{ textAlign: 'center', padding: '75px' }}>
                        <CircularProgress />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading {this.state.region === 'us' ? 'Top States' : 'Top Countries'}...
                        </Typography>
                    </div>
                }
                {this.state.topRegions && this.state.topRegions.length === 0 &&
                    <div style={{ textAlign: 'center', padding: '75px' }}>
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            No {this.state.region === 'us' ? 'states' : 'countries'} found.
                        </Typography>
                    </div>
                }
                {this.state.topRegions && this.state.topRegions.length > 0 &&
                    <React.Fragment>
                        <div className={classes.tableWrapper}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sortDirection={this.state.topRegionsOrderBy === 'name' ? (this.state.topRegionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topRegionsOrderBy === 'name'}
                                                direction={this.state.topRegionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopRegionsColumn('name')}
                                            >
                                                {this.state.region === 'us' ? 'State' : 'Country'}
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sortDirection={this.state.topRegionsOrderBy === 'views' ? (this.state.topRegionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topRegionsOrderBy === 'views'}
                                                direction={this.state.topRegionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopRegionsColumn('views')}
                                            >
                                                Views
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sortDirection={this.state.topRegionsOrderBy === 'percent' ? (this.state.topRegionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topRegionsOrderBy === 'percent'}
                                                direction={this.state.topRegionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopRegionsColumn('percent')}
                                            >
                                                Percent
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.topRegions.slice(this.state.topRegionsPage * this.state.topRegionsRowsPerPage, this.state.topRegionsPage * this.state.topRegionsRowsPerPage + this.state.topRegionsRowsPerPage).map(c =>
                                        <TableRow key={c.name}>
                                            <TableCell dangerouslySetInnerHTML={{ __html: this.highlightText(c.name, this.state.topRegionsQueryText) }} />
                                            <TableCell align="right">{('toLocaleString' in Number ? c.views.toLocaleString() : c.views.toString(10))}</TableCell>
                                            <TableCell align="right">{('toLocaleString' in Number ? (c.percent ? c.percent.toLocaleString() : 0) : (c.percent ? c.percent.toString(10) : 0))}%</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.topRegions.length}
                            rowsPerPage={this.state.topRegionsRowsPerPage}
                            page={this.state.topRegionsPage}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={(e, page) => this.setState({ topRegionsPage: page })}
                            onChangeRowsPerPage={(e) => this.setState({ topRegionsRowsPerPage: parseInt(e.target.value, 10) })}
                        />
                    </React.Fragment>
                }
            </Card>
        );
    }

    renderTopContributionsCard() {

        var classes = this.props.classes;

        return (
            <Card style={{ overflow: 'visible' }}>
                <Toolbar className={classes.tableToolbar}>
                    <div className={classes.title}>
                        <Typography variant="h6">
                            Top Contributions
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    {this.state.topContributions &&
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={this.state.topContributionsQueryText}
                                onChange={(e) => this.setState({ topContributionsQueryText: e.target.value }, this.updateTopContributions)}
                            />
                        </div>
                    }
                </Toolbar>
                {!this.state.topContributions &&
                    <div style={{ textAlign: 'center', padding: '75px' }}>
                        <CircularProgress />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading Top Contributions...
                        </Typography>
                    </div>
                }
                {this.state.topContributions && this.state.topContributions.length === 0 &&
                    <div style={{ textAlign: 'center', padding: '75px' }}>
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            No contributions found.
                        </Typography>
                    </div>
                }
                {this.state.topContributions && this.state.topContributions.length > 0 &&
                    <React.Fragment>
                        <div className={classes.tableWrapper}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sortDirection={this.state.topContributionsOrderBy === 'name' ? (this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topContributionsOrderBy === 'name'}
                                                direction={this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopContributionsColumn('name')}
                                            >
                                                Name
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            sortDirection={this.state.topContributionsOrderBy === 'type' ? (this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topContributionsOrderBy === 'type'}
                                                direction={this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopContributionsColumn('type')}
                                            >
                                                Contribution Type
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sortDirection={this.state.topContributionsOrderBy === 'date' ? (this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topContributionsOrderBy === 'date'}
                                                direction={this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopContributionsColumn('date')}
                                            >
                                                Date Contributed
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sortDirection={this.state.topContributionsOrderBy === 'views' ? (this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topContributionsOrderBy === 'views'}
                                                direction={this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopContributionsColumn('views')}
                                            >
                                                Views
                                            </TableSortLabel>
                                        </TableCell>
                                        <TableCell
                                            align="right"
                                            sortDirection={this.state.topContributionsOrderBy === 'percent' ? (this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc') : false}
                                        >
                                            <TableSortLabel
                                                active={this.state.topContributionsOrderBy === 'percent'}
                                                direction={this.state.topContributionsOrder === 'asc' ? 'asc' : 'desc'}
                                                onClick={() => this.sortTopContributionsColumn('percent')}
                                            >
                                                Percent
                                            </TableSortLabel>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.topContributions.slice(this.state.topContributionsPage * this.state.topContributionsRowsPerPage, this.state.topContributionsPage * this.state.topContributionsRowsPerPage + this.state.topContributionsRowsPerPage).map(c =>
                                        <TableRow key={c.id + '_' + c.type}>
                                            <TableCell dangerouslySetInnerHTML={{ __html: this.highlightText(c.name, this.state.topContributionsQueryText) }} />
                                            <TableCell>{c.type}</TableCell>
                                            <TableCell align="right">{('toLocaleDateString' in Date.prototype ? c.date.toLocaleDateString() : c.date.toString())}</TableCell>
                                            <TableCell align="right">{('toLocaleString' in Number ? c.views.toLocaleString() : c.views.toString(10))}</TableCell>
                                            <TableCell align="right">{('toLocaleString' in Number ? (c.percent ? c.percent.toLocaleString() : 0) : (c.percent ? c.percent.toString(10) : 0))}%</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={this.state.topContributions.length}
                            rowsPerPage={this.state.topContributionsRowsPerPage}
                            page={this.state.topContributionsPage}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                            }}
                            onChangePage={(e, page) => this.setState({ topContributionsPage: page })}
                            onChangeRowsPerPage={(e) => this.setState({ topContributionsRowsPerPage: parseInt(e.target.value, 10) })}
                        />
                    </React.Fragment>
                }
            </Card>
        );
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Contribution Dashboard
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.regionContainer}>
                        <IconButton aria-label="Select Region" aria-haspopup="true" aria-owns={this.state.anchorRegion ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorRegion: e.currentTarget })}>
                            <LanguageIcon />
                        </IconButton>
                        <Menu
                            id="filter-menu"
                            anchorEl={this.state.anchorRegion}
                            open={Boolean(this.state.anchorRegion)}
                            onClose={(e: any) => this.setState({ anchorRegion: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorRegion: undefined, region: 'world' }, this.updateMap)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.region === 'world' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="World" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorRegion: undefined, region: 'us' }, this.updateMap)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.region === 'us' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="USA" />
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.timeframeContainer}>
                        <IconButton aria-label="Select Timeframe" aria-haspopup="true" aria-owns={this.state.anchorTimeframe ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorTimeframe: e.currentTarget })}>
                            <AccessTimeIcon />
                        </IconButton>
                        <Menu
                            id="filter-menu"
                            anchorEl={this.state.anchorTimeframe}
                            open={Boolean(this.state.anchorTimeframe)}
                            onClose={(e: any) => this.setState({ anchorTimeframe: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorTimeframe: undefined, timeframe: 'realtime' }, this.updateRegion)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.timeframe === 'realtime' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={this.getTimeframeDisplayName('realtime')} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorTimeframe: undefined, timeframe: 'last7days' }, this.updateRegion)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.timeframe === 'last7days' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={this.getTimeframeDisplayName('last7days')} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorTimeframe: undefined, timeframe: 'last30days' }, this.updateRegion)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.timeframe === 'last30days' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={this.getTimeframeDisplayName('last30days')} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorTimeframe: undefined, timeframe: 'last60days' }, this.updateRegion)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.timeframe === 'last60days' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={this.getTimeframeDisplayName('last60days')} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorTimeframe: undefined, timeframe: 'last90days' }, this.updateRegion)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.timeframe === 'last90days' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={this.getTimeframeDisplayName('last90days')} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorTimeframe: undefined, timeframe: 'last180days' }, this.updateRegion)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.timeframe === 'last180days' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={this.getTimeframeDisplayName('last180days')} />
                            </MenuItem>
                        </Menu>
                    </div>
                </Toolbar>

                <div className={classes.gridContainer}>
                    <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                        <Grid item xs>
                            {this.renderMapCard()}
                        </Grid>
                        <Grid item xs={12} lg={3} xl={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} lg={12}>
                                    {this.renderTotalViewsCard()}
                                </Grid>
                                <Grid item xs={12} sm={4} lg={12}>
                                    {this.renderSermonsViewedCard()}
                                </Grid>
                                <Grid item xs={12} sm={4} lg={12}>
                                    {this.renderRegionsReachedCard()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={6} >
                            {this.renderTopCountriesCard()}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {this.renderTopContributionsCard()}
                        </Grid>
                    </Grid>

                </div>

            </div>
        );
    }
}

export default withRoot(withWidth()(withStyles(styles)(Home)));