import DiscussionNotification from '../entities/DiscussionNotification';
import Favorite from '../entities/Favorite';
import PrivateMessage from '../entities/PrivateMessage';
import SermonFolder from '../entities/SermonFolder';
import { ServerResponse, WebClient } from '../services/ServiceHelper';
import { ProducerPayoutData } from '../pages/account/mediaDashboard';

export class MiscService {

    public static async getPrivateMessages() {

        var response = await WebClient.Get<PrivateMessage[]>("api/Misc/GetPrivateMessages");
        if (ServerResponse.isServerResponse<PrivateMessage[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getDiscussionNotifications() {

        var response = await WebClient.Get<DiscussionNotification[]>("api/Misc/GetDiscussionNotifications");
        if (ServerResponse.isServerResponse<DiscussionNotification[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static unsubscribeFromDiscussionNotification(id: number, type: string) {
        return WebClient.Delete<null>("api/Misc/DeleteDiscussionNotification", { id, type });
    }

    public static async getSermonFolders() {

        var response = await WebClient.Get<SermonFolder[]>("api/Misc/GetSermonFolders");
        if (ServerResponse.isServerResponse<SermonFolder[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getFavorites(sermonFolderId: number) {

        var response = await WebClient.Get<Favorite[]>("api/Misc/GetFavorites/" + sermonFolderId);
        if (ServerResponse.isServerResponse<Favorite[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static deleteSermonFolder(id: number) {
        return WebClient.Delete<null>("api/Misc/DeleteSermonFolder", { id });
    }

    public static async saveSermonFolder(id: number | undefined, name: string, notes: string) {

        return WebClient.Post<SermonFolder>("api/Misc/SaveSermonFolder", { id, name, notes });
    }

    public static deleteFavorite(id: number) {
        return WebClient.Delete<null>("api/Misc/DeleteFavorite", { id });
    }

    public static async saveFavorite(id: number, sermonFolderId: number, sortOrder: number, notes: string) {

        return WebClient.Post<Favorite>("api/Misc/SaveFavorite", { id, sermonFolderId, sortOrder, notes });
    }

    public static async getProducerPayoutByYear(year: number) {

        var response = await WebClient.Get<ProducerPayoutData[]>("api/Misc/GetProducerPayout/" + year);
        if (ServerResponse.isServerResponse<ProducerPayoutData[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }
}