import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../withRoot';

import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import { green } from '@material-ui/core/colors';

const styles = (theme: Theme) =>
    createStyles({
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        snackBarText: {
            overflow: 'hidden'
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        close: {
            padding: theme.spacing() / 2,
        },
    });

interface Props extends WithStyles<typeof styles> {
    onClose?: () => void;
    message?: string;
    autoHideDuration?: number;
}

type State = {
};

class ForgotPassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const classes = this.props.classes;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.props.message != null}
                autoHideDuration={this.props.autoHideDuration}
                onClose={this.props.onClose}
            >
                <SnackbarContent
                    className={classes.successSnackbarContent}
                    aria-describedby="client-snackbar1"
                    classes={{
                        message: classes.snackBarText
                    }}
                    message={
                        <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                            <SuccessIcon className={classes.successSnackbarContentIcon} />
                            <span>{this.props.message}</span>
                        </span>}
                    action={
                        this.props.onClose
                        &&
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.props.onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    }
                />
            </Snackbar>
        );
    }
}

export default withRoot(withStyles(styles)(ForgotPassword));