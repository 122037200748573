import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ErrorIcon from "@material-ui/icons/Error";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import * as React from "react";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import Country from "../../entities/Country";
import CreditCard from "../../entities/CreditCard";
import { AccountService } from "../../services/AccountService";
import {
  FieldValidationError,
  ServerError,
  ServerModelValidationResponse,
  ServerResponse,
} from "../../services/ServiceHelper";
import withRoot from "../../withRoot";
import {ProSubscriptionSourceType} from "../../entities/ProSubscription";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    fab: {
      position: "absolute",
      bottom: theme.spacing() * 2,
      right: theme.spacing() * 2,
    },
    extendedFab: {
      marginRight: theme.spacing(),
    },
    header: {},
    title: {
      flex: "0 0 auto",
    },
    spacer: {
      flex: "1 1 100%",
    },
    ccTypeIcon: {
      width: "40px !important",
      height: "40px !important",
      color: theme.palette.text.secondary,
      marginRight: "10px",
    },
    cardTitle: {
      fontSize: 14,
      display: "inline-block",
      verticalAlign: "top",
    },
    cardStatus: {
      float: "right",
    },
    expansionCardStatus: {
      marginLeft: "auto",
    },
    pos: {
      marginBottom: 12,
    },
    cardContainer: {
      width: "500px",
      marginBottom: "16px",
      borderRadius: "4px",
    },
    errorSnackbarContent: {
      flexWrap: "nowrap",
      backgroundColor: theme.palette.error.dark,
    },
    errorSnackbarContentIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(),
    },
    errorSnackbarMessage: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      cardContainer: {
        width: "100%",
      },
      toolbar: {
        display: "block",
        padding: 0,
      },
      title: {
        float: "left",
      },
    },
    snackBarText: {
      overflow: "hidden",
    },
    addPaymentMethodDialogContent: {
      width: "500px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
      },
    },
    tableCard: {
      display: "inline-block",
      fontSize: 14,
      verticalAlign: "top",
      paddingTop: 8,
    },
    tableExpiry: {
      padding: 0,
    },
    successSnackbarContent: {
      backgroundColor: green[600],
    },
    successSnackbarContentIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(),
    },
    successSnackbarMessage: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
  });

interface Props extends WithStyles<typeof styles> {
  config: AppConfig;
  user: ApplicationUser;
}

type State = {
  loading: boolean;
  creditCards: CreditCard[];
  visibleCreditCardCount: number;
  errors: FieldValidationError[];
  addCreditCardErrors: FieldValidationError[];
  editCreditCardErrors: FieldValidationError[];
  showAddPaymentMethodDialog: boolean;
  addPaymentMethodCardHolderName: string;
  addPaymentMethodCreditCardNumber: string;
  addPaymentMethodCreditCardSecurityCode: string;
  addPaymentMethodCreditCardExpirationMonth: string;
  addPaymentMethodCreditCardExpirationYear: string;
  addPaymentMethodCreditCardPostalCode: string;
  showUpdatePaymentMethodDialog: boolean;
  updatePaymentMethodId: string;
  updatePaymentMethodCreditCardSecurityCode: string;
  updatePaymentMethodCreditCardExpirationMonth: string;
  updatePaymentMethodCreditCardExpirationYear: string;
  updatePaymentMethodCreditCardPostalCode: string;
  showChangePreferenceDialog: boolean;
  defaultCardId: string;
  disableAction: boolean;
  successMessage?: string;
  showDeleteCardConfirmation: boolean;
  cardForDeletion?: CreditCard;
  hasPayPal: boolean;
  countries: Country[];
  showCountries: boolean;
  selectedCountryShortCode?: string;
};

class PaymentMethods extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      creditCards: [],
      visibleCreditCardCount: 10,
      errors: [],
      addCreditCardErrors: [],
      editCreditCardErrors: [],
      showAddPaymentMethodDialog: false,
      addPaymentMethodCardHolderName: "",
      addPaymentMethodCreditCardExpirationMonth: "",
      addPaymentMethodCreditCardExpirationYear: "",
      addPaymentMethodCreditCardNumber: "",
      addPaymentMethodCreditCardPostalCode: "",
      addPaymentMethodCreditCardSecurityCode: "",
      showUpdatePaymentMethodDialog: false,
      updatePaymentMethodId: "",
      updatePaymentMethodCreditCardExpirationMonth: "",
      updatePaymentMethodCreditCardExpirationYear: "",
      updatePaymentMethodCreditCardPostalCode: "",
      updatePaymentMethodCreditCardSecurityCode: "",
      showChangePreferenceDialog: false,
      defaultCardId: "",
      disableAction: false,
      showDeleteCardConfirmation: false,
      hasPayPal: false,
      countries: [],
      showCountries: false,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Account - Payment Methods";

    try {
      const responses = await Promise.all([
        AccountService.getCreditCards(),
        AccountService.hasPayPal(),
        AccountService.getCountries(),
      ]);

      const [creditCards, hasPayPal, countries] = responses;
      this.setState({ loading: false });

      if (creditCards) {
        let defaultCard = creditCards.find((cc) => cc.useCardByDefault);
        this.setState({
          creditCards,
          hasPayPal: hasPayPal === true,
          loading: false,
          defaultCardId: defaultCard ? defaultCard.id : "",
        });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError] });
      }

      if (countries) {
        this.setState({
          countries: countries.data,
        });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve location. Please try again."],
        };
        this.setState({ errors: [serverError] });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({ errors: [serverError] });
    }
  }

  sortCreditCards(s1: CreditCard, s2: CreditCard): number {
    if (!s1.expirationYear) {
      return -1;
    }

    if (!s2.expirationYear) {
      return 1;
    }

    if (s1.expirationYear > s2.expirationYear) {
      return -1;
    }

    if (s2.expirationYear > s1.expirationYear) {
      return 1;
    }

    if (!s1.expirationMonth) {
      return -1;
    }

    if (!s2.expirationMonth) {
      return 1;
    }

    if (s1.expirationMonth > s2.expirationMonth) {
      return -1;
    }

    if (s2.expirationMonth > s1.expirationMonth) {
      return 1;
    }

    return 0;
  }

  getFontAwesomePaymentMethodType(type: string): IconProp {
    if (type === "AmericanExpress") {
      return ["fab", "cc-amex"];
    } else if (type === "Discover") {
      return ["fab", "cc-discover"];
    } else if (type === "MasterCard") {
      return ["fab", "cc-mastercard"];
    } else if (type === "Visa") {
      return ["fab", "cc-visa"];
    } else if (type === "PayPal") {
      return ["fab", "cc-paypal"];
    } else {
      return ["fas", "credit-card"];
    }
  }

  handleAddPaymentMethodFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    this.setState({
      disableAction: true,
      errors: [],
      addCreditCardErrors: [],
      editCreditCardErrors: [],
    });

    try {
      const response = await AccountService.addPaymentMethod(
        this.props.config,
        this.state.addPaymentMethodCardHolderName,
        this.state.addPaymentMethodCreditCardNumber,
        this.state.addPaymentMethodCreditCardExpirationMonth,
        this.state.addPaymentMethodCreditCardExpirationYear,
        this.state.addPaymentMethodCreditCardSecurityCode,
        this.state.addPaymentMethodCreditCardPostalCode,
        this.state.selectedCountryShortCode
      );
      this.setState({ disableAction: false });

      if (ServerResponse.isServerResponse(response)) {
        let newCard = response.data;

        this.setState({
          creditCards: [...this.state.creditCards, newCard],
          showAddPaymentMethodDialog: false,
          disableAction: false,
          addPaymentMethodCardHolderName: "",
          addPaymentMethodCreditCardNumber: "",
          addPaymentMethodCreditCardExpirationMonth: "",
          addPaymentMethodCreditCardExpirationYear: "",
          addPaymentMethodCreditCardPostalCode: "",
          addPaymentMethodCreditCardSecurityCode: "",
          selectedCountryShortCode: undefined,
          successMessage: `New ${newCard.type} added.`,
        });
      } else if (ServerError.isServerError(response)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response.message],
        };
        this.setState({
          addCreditCardErrors: [serverError],
        });
      } else if (ServerResponse.isModelValidation(response)) {
        if (response.errors.findIndex((a) => a.field === "PostalCode") > -1) {
          this.setState({ showCountries: true });
        }
        this.setState({
          errors: response.errors,
        });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({
          errors: [serverError],
          showAddPaymentMethodDialog: false,
        });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({
        errors: [serverError],
        showAddPaymentMethodDialog: false,
        disableAction: false,
      });
    }
  };

  handleUpdatePaymentMethodFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    this.setState({
      disableAction: true,
      errors: [],
      addCreditCardErrors: [],
      editCreditCardErrors: [],
    });

    try {
      const response = await AccountService.updatePaymentMethod(
        this.props.config,
        this.state.updatePaymentMethodId,
        this.state.updatePaymentMethodCreditCardExpirationMonth,
        this.state.updatePaymentMethodCreditCardExpirationYear,
        this.state.updatePaymentMethodCreditCardSecurityCode,
        this.state.updatePaymentMethodCreditCardPostalCode,
        this.state.selectedCountryShortCode
      );

      if (ServerResponse.isServerResponse(response)) {
        let updatedCard = response.data;

        const creditCards = this.state.creditCards.map((c) => {
          if (c.id === updatedCard.id) {
            return updatedCard;
          }
          return updatedCard;
        });

        this.setState({
          creditCards,
          showUpdatePaymentMethodDialog: false,
          disableAction: false,
          updatePaymentMethodId: "",
          updatePaymentMethodCreditCardExpirationMonth: "",
          updatePaymentMethodCreditCardExpirationYear: "",
          updatePaymentMethodCreditCardPostalCode: "",
          updatePaymentMethodCreditCardSecurityCode: "",
          selectedCountryShortCode: undefined,
          successMessage: `Updated ${updatedCard.type}.`,
        });
      } else if (ServerResponse.isModelValidation(response)) {
        if (response.errors.findIndex((a) => a.field === "PostalCode") > -1) {
          this.setState({ showCountries: true });
        }
        this.setState({
          errors: response.errors,
          disableAction: false,
        });
      } else if (
        ServerModelValidationResponse.isServerModelValidationResponse(response)
      ) {
        if (response.valid) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], disableAction: false });
        } else {
          this.setState({ errors: response.errors, disableAction: false });
        }
      } else if (ServerError.isServerError(response)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response.message],
        };
        this.setState({
          editCreditCardErrors: [serverError],
          disableAction: false,
        });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({
          errors: [serverError],
          showUpdatePaymentMethodDialog: false,
          disableAction: false,
        });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({
        errors: [serverError],
        showUpdatePaymentMethodDialog: false,
        disableAction: false,
      });
    }
  };

  handleUpdateDefaultPaymentMethod = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    if (this.state.defaultCardId.length === 0) {
      return;
    }

    this.setState({ disableAction: true });

    try {
      const creditCards = await AccountService.changeDefaultCreditCard(
        this.state.defaultCardId
      );

      if (creditCards) {
        var defaultCard = creditCards.find((cc) => cc.useCardByDefault);
        this.setState({
          creditCards,
          showChangePreferenceDialog: false,
          defaultCardId: defaultCard ? defaultCard.id : "",
          disableAction: false,
          successMessage: "Default card updated.",
        });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({
          errors: [serverError],
          showChangePreferenceDialog: false,
          disableAction: false,
        });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({
        errors: [serverError],
        showChangePreferenceDialog: false,
        disableAction: false,
      });
    }
  };

  handleDeleteCardMethod = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      !this.state.cardForDeletion ||
      this.state.cardForDeletion.useCardByDefault
      || this.state.disableAction
    ) {
      return;
    }

    this.setState({ disableAction: true });

    try {
      const creditCards = await AccountService.deleteCreditCard(
        this.state.cardForDeletion.id
      );

      if (creditCards) {
        var defaultCard = creditCards.find((cc) => cc.useCardByDefault);
        this.setState({
          creditCards,
          showDeleteCardConfirmation: false,
          defaultCardId: defaultCard ? defaultCard.id : "",
          disableAction: false,
          successMessage: "Credit card deleted",
          cardForDeletion: undefined,
        });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({
          errors: [serverError],
          showDeleteCardConfirmation: false,
          disableAction: false,
          cardForDeletion: undefined,
        });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({
        errors: [serverError],
        showDeleteCardConfirmation: false,
        disableAction: false,
        cardForDeletion: undefined,
      });
    }
  };

  renderPreferences(sortedCreditCards: CreditCard[]) {
    
    const classes = this.props.classes;

    if (sortedCreditCards.length === 0) {
      return (
        <Typography variant="body1">
          {" "}
          You do not have any cards on file{" "}
        </Typography>
      );
    }

    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Card</TableCell>
              <TableCell />
              <TableCell align="right">Default</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCreditCards.map((creditCard) => (
              <TableRow key={creditCard.id}>
                <TableCell>
                  <Hidden smDown>
                    <FontAwesomeIcon
                      className={classes.ccTypeIcon}
                      icon={this.getFontAwesomePaymentMethodType(
                        creditCard.type
                      )}
                    />
                  </Hidden>
                  <Typography
                    className={classes.tableCard}
                    color="textSecondary"
                    gutterBottom
                  >
                    {creditCard.type} ending in {creditCard.lastFour}
                  </Typography>
                </TableCell>
                <Hidden smDown>
                  <TableCell>
                    <Typography
                      className={classNames(
                        classes.tableCard,
                        classes.tableExpiry
                      )}
                      color={creditCard.isExpired ? "error" : "textSecondary"}
                    >
                      {creditCard.expirationMonth}/{creditCard.expirationYear}
                    </Typography>
                  </TableCell>
                </Hidden>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={creditCard.id === this.state.defaultCardId}
                    onChange={() =>
                      this.setState({ defaultCardId: creditCard.id })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  render() {
    
    const classes = this.props.classes;
    const sortedCreditCards = this.state.creditCards.sort(this.sortCreditCards);
    var defaultCreditCard = this.state.creditCards.find(
      (cc) => cc.useCardByDefault
    );

    return (
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={FieldValidationError.hasGenericError(this.state.errors)}
        >
          <SnackbarContent
            className={classes.errorSnackbarContent}
            aria-describedby="client-snackbar"
            classes={{
              message: classes.snackBarText,
            }}
            message={
              <span
                id="client-snackbar"
                className={classes.errorSnackbarMessage}
              >
                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: FieldValidationError.getGenericErrorSummary(
                      this.state.errors
                    ),
                  }}
                />
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() =>
                  this.setState({
                    errors: this.state.errors.filter((a) => a.field !== ""),
                  })
                }
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.successMessage !== undefined}
          autoHideDuration={6000}
          onClose={() => this.setState({ successMessage: undefined })}
        >
          <SnackbarContent
            className={classes.successSnackbarContent}
            aria-describedby="client-snackbar1"
            classes={{
              message: classes.snackBarText,
            }}
            message={
              <span
                id="client-snackbar1"
                className={classes.successSnackbarMessage}
              >
                <CheckCircleIcon
                  className={classes.successSnackbarContentIcon}
                />
                {this.state.successMessage ? this.state.successMessage : ""}
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={() => this.setState({ successMessage: undefined })}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>

        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <Typography variant="h5" component="h2">
              Payment Methods
            </Typography>
            {!this.state.loading && this.state.creditCards.length > 0 && (
              <Typography
                variant="body2"
                component="p"
                color="textSecondary"
                style={{ fontStyle: "italic", marginBottom: "5px" }}
              >
                {this.state.creditCards.length} payment method
                {this.state.creditCards.length === 1 ? "" : "s"}
              </Typography>
            )}
          </div>
          <div className={classes.spacer} />
        </Toolbar>

        <Grid container direction="column" justify="center" alignItems="center">
          {this.state.loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress style={{ marginTop: "50px" }} />
              <br />
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ textAlign: "center" }}
              >
                Loading...
              </Typography>
            </div>
          ) : (
            <>
              {(this.props.user.isGlooLogin && this.props.user.proSubscription?.source === ProSubscriptionSourceType.Gloo) &&
                  <Typography variant="body1" color="textSecondary" style={{ textAlign: 'left' }}>
                    <a href={this.props.config.glooSubscriptionsUrl}>Visit Gloo</a> for your Gloo PRO payment method.
                  </Typography>
              }
              {this.state.hasPayPal ? (
                <>
                  <Typography gutterBottom>
                    Your payment method
                    <a
                      href={`${this.props.config.paypalUrl}/myaccount/autopay/connect`}
                      style={{ paddingLeft: 3, textDecoration: "none" }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (Manage)
                    </a>
                  </Typography>
                  <Card className={classes.cardContainer}>
                    <CardContent>
                      <FontAwesomeIcon
                        className={classes.ccTypeIcon}
                        icon={this.getFontAwesomePaymentMethodType("PayPal")}
                      />
                      <Typography
                        className={classes.cardTitle}
                        color="textSecondary"
                        gutterBottom
                      >
                        Paying with PayPal
                      </Typography>
                      <Typography color={"textSecondary"}>
                        Expires: Never
                      </Typography>
                      {this.props.user.proSubscription &&
                        this.props.user.proSubscription.nextBillDate && (
                          <Typography
                            style={{ fontSize: 12 }}
                            color={"textSecondary"}
                          >
                            <em>
                              Disclaimer: If you cancel your subscription, you
                              will still be able to access it until{" "}
                              {new Date(
                                this.props.user.proSubscription.nextBillDate
                              ).toLocaleDateString()}
                              .
                            </em>
                          </Typography>
                        )}
                    </CardContent>
                    <CardActions>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          target="_blank"
                          href={`${this.props.config.paypalUrl}/myaccount/autopay/connect`}
                          variant="outlined"
                        >
                          Manage
                        </Button>
                      </div>
                    </CardActions>
                  </Card>
                </>
              ) : (
                <>
                  {this.state.creditCards.length === 0 && (
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      style={{ textAlign: "center" }}
                    >
                      <CreditCardIcon
                        style={{
                          marginTop: "50px",
                          width: "220px",
                          height: "220px",
                          color: "#CCC",
                        }}
                      />
                      <br />
                      You have no credit cards. Add one below.
                    </Typography>
                  )}
                  {sortedCreditCards.length > 0 && (
                    <React.Fragment>
                      {defaultCreditCard && (
                        <React.Fragment>
                          <Typography gutterBottom>
                            Your default payment method
                            <a
                              href="ChangePreference"
                              style={{ paddingLeft: 3, textDecoration: "none" }}
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  showChangePreferenceDialog: true,
                                });
                              }}
                            >
                              (change preference)
                            </a>
                          </Typography>
                          <Card className={classes.cardContainer}>
                            <CardContent>
                              <FontAwesomeIcon
                                className={classes.ccTypeIcon}
                                icon={this.getFontAwesomePaymentMethodType(
                                  defaultCreditCard.type
                                )}
                              />
                              <Typography
                                className={classes.cardTitle}
                                color="textSecondary"
                                gutterBottom
                              >
                                {defaultCreditCard.type} ending in{" "}
                                {defaultCreditCard.lastFour}
                              </Typography>
                              <Typography
                                className={classes.cardStatus}
                                color={
                                  defaultCreditCard.isExpired
                                    ? "error"
                                    : "textSecondary"
                                }
                              >
                                {defaultCreditCard.expirationMonth}/
                                {defaultCreditCard.expirationYear}
                              </Typography>
                              <Grid container>
                                <Grid item xs>
                                  <Typography variant="body2" component="p">
                                    Name on Card
                                    <br />
                                    {defaultCreditCard.cardHolderName}
                                  </Typography>
                                </Grid>
                                <Grid item xs>
                                  <Typography variant="body2" component="p">
                                    Postal Code
                                    <br />
                                    {defaultCreditCard.postalCode}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                            <CardActions>
                              <div style={{ marginLeft: "auto" }}>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    this.setState({
                                      updatePaymentMethodId:
                                        defaultCreditCard!.id,
                                      showUpdatePaymentMethodDialog: true,
                                    })
                                  }
                                >
                                  Edit
                                </Button>
                              </div>
                            </CardActions>
                          </Card>
                        </React.Fragment>
                      )}
                      {sortedCreditCards
                        .filter((cc) => !cc.useCardByDefault)
                        .map((creditCard) => (
                          <ExpansionPanel
                            className={classes.cardContainer}
                            key={creditCard.id}
                          >
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <FontAwesomeIcon
                                className={classes.ccTypeIcon}
                                icon={this.getFontAwesomePaymentMethodType(
                                  creditCard.type
                                )}
                              />
                              <Typography
                                className={classes.cardTitle}
                                color="textSecondary"
                                gutterBottom
                              >
                                {creditCard.type} ending in{" "}
                                {creditCard.lastFour}
                              </Typography>
                              <Typography
                                className={classes.expansionCardStatus}
                                color={
                                  creditCard.isExpired
                                    ? "error"
                                    : "textSecondary"
                                }
                              >
                                {creditCard.expirationMonth}/
                                {creditCard.expirationYear}
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Grid container>
                                <Grid item xs={6}>
                                  <Typography variant="body2" component="p">
                                    Name on Card
                                    <br />
                                    {creditCard.cardHolderName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant="body2" component="p">
                                    Postal Code
                                    <br />
                                    {creditCard.postalCode}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    onClick={() =>
                                      this.setState({
                                        showDeleteCardConfirmation: true,
                                        cardForDeletion: creditCard,
                                      })
                                    }
                                    color="secondary"
                                    style={{
                                      marginLeft: "-10px",
                                      marginTop: "20px",
                                      marginBottom: "-15px",
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    color="primary"
                                    style={{
                                      float: "right",
                                      marginRight: "-10px",
                                      marginTop: "20px",
                                      marginBottom: "-15px",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        updatePaymentMethodId: creditCard.id,
                                        showUpdatePaymentMethodDialog: true,
                                      })
                                    }
                                  >
                                    Edit
                                  </Button>
                                </Grid>
                              </Grid>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        ))}
                    </React.Fragment>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
        {!this.state.hasPayPal && !this.state.loading && (
          <Fab
            variant="extended"
            className={classes.fab}
            color="primary"
            onClick={() => this.setState({ showAddPaymentMethodDialog: true })}
          >
            <AddIcon className={classes.extendedFab} />
            Add Payment Method
          </Fab>
        )}
        <Dialog
          open={this.state.showAddPaymentMethodDialog}
          onClose={() =>
            this.setState({
              showAddPaymentMethodDialog:
                !this.state.showAddPaymentMethodDialog,
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={(e) => this.handleAddPaymentMethodFormSubmit(e)}>
            <DialogTitle id="form-dialog-title">
              Add a new credit card
            </DialogTitle>
            <DialogContent className={classes.addPaymentMethodDialogContent}>
              {this.state.addCreditCardErrors.length > 0 && (
                <Typography
                  style={{
                    backgroundColor: "#d32f2f",
                    padding: "10px",
                    borderRadius: 3,
                    marginBottom: "10px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: FieldValidationError.getGenericErrorSummary(
                      this.state.addCreditCardErrors
                    ),
                  }}
                />
              )}

              <TextField
                margin="dense"
                fullWidth
                label="Name on card"
                required
                variant="outlined"
                value={this.state.addPaymentMethodCardHolderName}
                onChange={(e) =>
                  this.setState({
                    addPaymentMethodCardHolderName: e.target.value,
                  })
                }
                error={FieldValidationError.isFieldInError(
                  this.state.errors,
                  "CardHolderName"
                )}
                helperText={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: FieldValidationError.getFieldErrorSummary(
                        this.state.errors,
                        "CardHolderName"
                      ),
                    }}
                  />
                }
              />
              <TextField
                margin="dense"
                fullWidth
                label="Credit card number"
                required
                variant="outlined"
                value={this.state.addPaymentMethodCreditCardNumber}
                onChange={(e) =>
                  this.setState({
                    addPaymentMethodCreditCardNumber: e.target.value,
                  })
                }
                error={FieldValidationError.isFieldInError(
                  this.state.errors,
                  "CreditCardNumber"
                )}
                helperText={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: FieldValidationError.getFieldErrorSummary(
                        this.state.errors,
                        "CreditCardNumber"
                      ),
                    }}
                  />
                }
              />

              <Grid container>
                <Grid item sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardExpirationMonth"
                    )}
                  >
                    <InputLabel htmlFor="expirationMonth" required>
                      Expiration Month
                    </InputLabel>
                    <Select
                      native
                      margin="dense"
                      required
                      value={
                        this.state.addPaymentMethodCreditCardExpirationMonth
                      }
                      onChange={(e) =>
                        this.setState({
                          addPaymentMethodCreditCardExpirationMonth: e.target
                            .value as string,
                        })
                      }
                      input={
                        <OutlinedInput
                          labelWidth={200}
                          name="expirationMonth"
                          id="expirationMonth"
                        />
                      }
                    >
                      <option />
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {
                        return (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardExpirationMonth"
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardExpirationYear"
                    )}
                  >
                    <InputLabel htmlFor="expirationYear" required>
                      Expiration Year
                    </InputLabel>
                    <Select
                      native
                      margin="dense"
                      required
                      value={
                        this.state.addPaymentMethodCreditCardExpirationYear
                      }
                      onChange={(e) =>
                        this.setState({
                          addPaymentMethodCreditCardExpirationYear: e.target
                            .value as string,
                        })
                      }
                      input={
                        <OutlinedInput
                          labelWidth={200}
                          name="expirationYear"
                          id="expirationYear"
                        />
                      }
                    >
                      <option />
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
                        var year = new Date().getFullYear();
                        return (
                          <option key={i + year} value={i + year}>
                            {i + year}
                          </option>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardExpirationYear"
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sm={6}>
                  <TextField
                    margin="dense"
                    fullWidth
                    label="CVC/CVV"
                    required
                    variant="outlined"
                    value={this.state.addPaymentMethodCreditCardSecurityCode}
                    onChange={(e) =>
                      this.setState({
                        addPaymentMethodCreditCardSecurityCode: e.target.value,
                      })
                    }
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardSecurityCode"
                    )}
                    helperText={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "CreditCardSecurityCode"
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="dense"
                    fullWidth
                    label="ZIP / Postal code"
                    required
                    variant="outlined"
                    value={this.state.addPaymentMethodCreditCardPostalCode}
                    onChange={(e) =>
                      this.setState({
                        addPaymentMethodCreditCardPostalCode: e.target.value,
                      })
                    }
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardPostalCode"
                    )}
                    helperText={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "CreditCardPostalCode"
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {this.state.showCountries && (
                <Grid container>
                  <Grid item sm={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.formControl}
                      error={FieldValidationError.isFieldInError(
                        this.state.errors,
                        "PostalCode"
                      )}
                    >
                      <InputLabel htmlFor="country" required>
                        Country
                      </InputLabel>
                      <Select
                        native
                        margin="dense"
                        required
                        value={
                          this.state.selectedCountryShortCode
                            ? this.state.selectedCountryShortCode
                            : ""
                        }
                        onChange={(e) =>
                          this.setState({
                            selectedCountryShortCode: e.target.value as string,
                          })
                        }
                        input={
                          <OutlinedInput
                            labelWidth={200}
                            name="country"
                            id="country"
                          />
                        }
                      >
                        {this.state.countries.map((c) => (
                          <option key={c.twoCharCode} value={c.threeCharCode}>
                            {c.name}
                          </option>
                        ))}
                      </Select>
                      <FormHelperText
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "PostalCode"
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({
                    showAddPaymentMethodDialog:
                      !this.state.showAddPaymentMethodDialog,
                  })
                }
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.disableAction}
                type="submit"
                color="primary"
              >
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.showUpdatePaymentMethodDialog}
          onClose={() =>
            this.setState({ showUpdatePaymentMethodDialog: false })
          }
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={(e) => this.handleUpdatePaymentMethodFormSubmit(e)}>
            <DialogTitle id="form-dialog-title">Edit credit card</DialogTitle>
            <DialogContent
              className={classes.addPaymentMethodDialogContent}
              style={{ paddingTop: 10 }}
            >
              {this.state.editCreditCardErrors.length > 0 && (
                <Typography
                  style={{
                    backgroundColor: "#d32f2f",
                    padding: "10px",
                    borderRadius: 3,
                    marginBottom: "10px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: FieldValidationError.getGenericErrorSummary(
                      this.state.editCreditCardErrors
                    ),
                  }}
                />
              )}

              <Grid container>
                <Grid item sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardExpirationMonth"
                    )}
                  >
                    <InputLabel htmlFor="expirationMonth" required>
                      Expiration Month
                    </InputLabel>
                    <Select
                      native
                      margin="dense"
                      required
                      value={
                        this.state.updatePaymentMethodCreditCardExpirationMonth
                      }
                      onChange={(e) =>
                        this.setState({
                          updatePaymentMethodCreditCardExpirationMonth: e.target
                            .value as string,
                        })
                      }
                      input={
                        <OutlinedInput
                          labelWidth={200}
                          name="expirationMonth"
                          id="expirationMonth"
                        />
                      }
                    >
                      <option />
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((i) => {
                        return (
                          <option key={i + 1} value={i + 1}>
                            {i + 1}
                          </option>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardExpirationMonth"
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item sm={6}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.formControl}
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardExpirationYear"
                    )}
                  >
                    <InputLabel htmlFor="expirationYear" required>
                      Expiration Year
                    </InputLabel>
                    <Select
                      native
                      margin="dense"
                      required
                      value={
                        this.state.updatePaymentMethodCreditCardExpirationYear
                      }
                      onChange={(e) =>
                        this.setState({
                          updatePaymentMethodCreditCardExpirationYear: e.target
                            .value as string,
                        })
                      }
                      input={
                        <OutlinedInput
                          labelWidth={200}
                          name="expirationYear"
                          id="expirationYear"
                        />
                      }
                    >
                      <option />
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => {
                        var year = new Date().getFullYear();
                        return (
                          <option key={i + year} value={i + year}>
                            {i + year}
                          </option>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      dangerouslySetInnerHTML={{
                        __html: FieldValidationError.getFieldErrorSummary(
                          this.state.errors,
                          "CreditCardExpirationYear"
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sm={6}>
                  <TextField
                    margin="dense"
                    fullWidth
                    label="CVC/CVV"
                    required
                    variant="outlined"
                    value={this.state.updatePaymentMethodCreditCardSecurityCode}
                    onChange={(e) =>
                      this.setState({
                        updatePaymentMethodCreditCardSecurityCode:
                          e.target.value,
                      })
                    }
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardSecurityCode"
                    )}
                    helperText={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "CreditCardSecurityCode"
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    margin="dense"
                    fullWidth
                    label="ZIP / Postal code"
                    required
                    variant="outlined"
                    value={this.state.updatePaymentMethodCreditCardPostalCode}
                    onChange={(e) =>
                      this.setState({
                        updatePaymentMethodCreditCardPostalCode: e.target.value,
                      })
                    }
                    error={FieldValidationError.isFieldInError(
                      this.state.errors,
                      "CreditCardPostalCode"
                    )}
                    helperText={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "CreditCardPostalCode"
                          ),
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
              {this.state.showCountries && (
                <Grid container>
                  <Grid item sm={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      className={classes.formControl}
                      error={FieldValidationError.isFieldInError(
                        this.state.errors,
                        "PostalCode"
                      )}
                    >
                      <InputLabel htmlFor="country" required>
                        Country
                      </InputLabel>
                      <Select
                        native
                        margin="dense"
                        required
                        value={
                          this.state.selectedCountryShortCode
                            ? this.state.selectedCountryShortCode
                            : ""
                        }
                        onChange={(e) =>
                          this.setState({
                            selectedCountryShortCode: e.target.value as string,
                          })
                        }
                        input={
                          <OutlinedInput
                            labelWidth={200}
                            name="country"
                            id="country"
                          />
                        }
                      >
                        {this.state.countries.map((c) => (
                          <option key={c.twoCharCode} value={c.threeCharCode}>
                            {c.name}
                          </option>
                        ))}
                      </Select>
                      <FormHelperText
                        dangerouslySetInnerHTML={{
                          __html: FieldValidationError.getFieldErrorSummary(
                            this.state.errors,
                            "PostalCode"
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ showUpdatePaymentMethodDialog: false })
                }
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={this.state.disableAction}
                type="submit"
                color="primary"
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.showChangePreferenceDialog}
          onClose={() => this.setState({ showChangePreferenceDialog: false })}
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleUpdateDefaultPaymentMethod}>
            <DialogTitle id="alert-dialog-title">
              {"Change default payment preference"}
            </DialogTitle>
            <DialogContent style={{ maxHeight: "400px" }}>
              {this.renderPreferences(sortedCreditCards)}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({ showChangePreferenceDialog: false })
                }
                color="primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={this.state.disableAction}
                color="primary"
                autoFocus
              >
                Update
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={this.state.showDeleteCardConfirmation}
          onClose={() =>
            this.setState({
              showDeleteCardConfirmation: false,
              cardForDeletion: undefined,
            })
          }
          aria-labelledby="form-dialog-title"
        >
          <form onSubmit={this.handleDeleteCardMethod}>
            <DialogTitle id="delete-dialog-title">Confirm deletion</DialogTitle>
            <DialogContent style={{ maxHeight: "400px" }}>
              <DialogContentText id="delete-dialog-description">
                This will remove the credit card from your account. Are you sure
                you wish to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.setState({
                    showDeleteCardConfirmation: false,
                    cardForDeletion: undefined,
                  })
                }
                color="primary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={this.state.disableAction}
                color="secondary"
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(PaymentMethods));
