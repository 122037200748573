import Newsletter from '../entities/Newsletter';
import { ServerResponse, WebClient } from '../services/ServiceHelper';
import { BibleTranslation } from '../entities/BibleTranslation';

export interface NewsletterSubscriptions {
    newsletters: Newsletter[];
    selectedNewsletterIds: number[];
}

export class PreferencesService {

    public static async getNewsletterSubscriptions() {

        var response = await WebClient.Get<NewsletterSubscriptions>("api/Preferences/GetNewsletterSubscriptions");
        if (ServerResponse.isServerResponse<NewsletterSubscriptions>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static updateNewsletterSubscriptions(newsletterIds: number[]) {
        return WebClient.Post<null>("api/Preferences/UpdateNewsletterSubscriptions", { newsletterIds });
    }

    public static async getBibleTranslations() {

        var response = await WebClient.Get<BibleTranslation[]>("api/Preferences/GetBibleTranslations");
        if (ServerResponse.isServerResponse<BibleTranslation[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getPreferredBibleTranslation() {

        var response = await WebClient.Get<BibleTranslation>("api/Preferences/GetPreferredBibleTranslation");
        if (ServerResponse.isServerResponse<BibleTranslation>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static updatePreferredBibleTranslation(translationId: string) {
        return WebClient.Post<null>("api/Preferences/UpdatePreferredBibleTranslation", { translationId });
    }

    public static async getNotificationPreferences() {

        var response = await WebClient.Get<{ receiveFeedbackMessageNotifications: boolean, receiveSermonCommentNotifications: boolean }>("api/Preferences/GetNotificationPreferences");
        if (ServerResponse.isServerResponse<{ receiveFeedbackMessageNotifications: boolean, receiveSermonCommentNotifications: boolean }>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static updateNotificationPreferences(receiveFeedbackMessageNotifications: boolean, receiveSermonCommentNotifications: boolean) {
        return WebClient.Post<null>("api/Preferences/UpdateNotificationPreferences", { receiveFeedbackMessageNotifications, receiveSermonCommentNotifications });
    }

}