import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import ApplicationUser from '../../entities/ApplicationUser';
import MyStory from '../../entities/MyStory';
import { ProfileService } from '../../services/ProfileService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            maxWidth: '700px',
            marginLeft: 'auto',
            marginRight: 'auto',
            minHeight: '75vh',
            overflowY: 'auto'
        },
        viewButton: {
            marginLeft: 'auto'
        },
        editForm: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        formButton: {
            marginTop: '15px',
            marginLeft: 'auto',
            display: 'flex'
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    family: string;
    hobbies: string;
    bestAdvice: string;
    sermonDifference: string;
    favoriteIllustration: string;
    bookImpact: string;
    somethingFunny: string;
    parentsThink: string;
    spouseThink: string;
    ifICould: string;
    tombstone: string;
    comment: string;
    savedMyStory?: MyStory;
    showUpdateSuccessMessage: boolean;
    errors: FieldValidationError[];
}

class MyStoryPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            family: '',
            hobbies: '',
            bestAdvice: '',
            sermonDifference: '',
            favoriteIllustration: '',
            bookImpact: '',
            somethingFunny: '',
            parentsThink: '',
            spouseThink: '',
            ifICould: '',
            tombstone: '',
            comment: '',
            showUpdateSuccessMessage: false,
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - My Story";

        try {
            const myStory = await ProfileService.getMyStory();

            if (myStory) {
                this.setState({
                    loading: false,
                    enableSubmit: true,
                    family: myStory.family,
                    hobbies: myStory.hobbies,
                    bestAdvice: myStory.bestAdvice,
                    sermonDifference: myStory.sermonDifference,
                    favoriteIllustration: myStory.favoriteIllustration,
                    bookImpact: myStory.bookImpact,
                    somethingFunny: myStory.somethingFunny,
                    parentsThink: myStory.parentsThink,
                    spouseThink: myStory.spouseThink,
                    ifICould: myStory.ifICould,
                    tombstone: myStory.tombstone,
                    comment: myStory.comment,
                    savedMyStory: myStory,
                    errors: [],
                });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }
        try {
            this.setState({ enableSubmit: false });
            const response = await ProfileService.updateMyStory(this.state.family, this.state.hobbies, this.state.bestAdvice, this.state.sermonDifference, this.state.favoriteIllustration, this.state.bookImpact, this.state.somethingFunny, this.state.parentsThink, this.state.spouseThink, this.state.ifICould, this.state.tombstone, this.state.comment);

            if (ServerResponse.isServerResponse<ApplicationUser>(response)) {

                this.setState({
                    savedMyStory: {
                        family: this.state.family,
                        hobbies: this.state.hobbies,
                        bestAdvice: this.state.bestAdvice,
                        sermonDifference: this.state.sermonDifference,
                        favoriteIllustration: this.state.favoriteIllustration,
                        bookImpact: this.state.bookImpact,
                        somethingFunny: this.state.somethingFunny,
                        parentsThink: this.state.parentsThink,
                        spouseThink: this.state.spouseThink,
                        ifICould: this.state.ifICould,
                        tombstone: this.state.tombstone,
                        comment: this.state.comment,
                    },
                    errors: [],
                    enableSubmit: true,
                    showUpdateSuccessMessage: true
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    renderEditMode() {

        const classes = this.props.classes;

        return (
            <React.Fragment>
                <form onSubmit={(e) => this.handleFormSubmit(e)}>
                    <div className={classes.editForm}>
                        <TextField
                            margin="dense"
                            fullWidth
                            id="family"
                            label="Family"
                            multiline
                            rows="4"
                            value={this.state.family}
                            onChange={(e) => this.setState({ family: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'Family')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Family') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="hobbies"
                            label="Hobbies"
                            multiline
                            rows="4"
                            value={this.state.hobbies}
                            onChange={(e) => this.setState({ hobbies: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'Hobbies')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Hobbies') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="sermonDifference"
                            label="Sermon or sermon series that made a difference in my life"
                            multiline
                            rows="4"
                            value={this.state.sermonDifference}
                            onChange={(e) => this.setState({ sermonDifference: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'SermonDifference')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'SermonDifference') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="bookImpact"
                            label="Books that have impacted my life"
                            multiline
                            rows="4"
                            value={this.state.bookImpact}
                            onChange={(e) => this.setState({ bookImpact: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'BookImpact')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'BookImpact') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="comment"
                            label="A comment to those reading my sermons"
                            multiline
                            rows="4"
                            value={this.state.comment}
                            onChange={(e) => this.setState({ comment: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'Comment')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Comment') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="tombstone"
                            label="What I want on my tombstone"
                            multiline
                            rows="4"
                            value={this.state.tombstone}
                            onChange={(e) => this.setState({ tombstone: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'Tombstone')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Tombstone') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="spouseThink"
                            label="What my spouse (really) thinks of my sermons"
                            multiline
                            rows="4"
                            value={this.state.spouseThink}
                            onChange={(e) => this.setState({ spouseThink: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'SpouseThink')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'SpouseThink') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="parentsThink"
                            label="What my parents think of my sermons"
                            multiline
                            rows="4"
                            value={this.state.parentsThink}
                            onChange={(e) => this.setState({ parentsThink: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'ParentsThink')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ParentsThink') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="bestAdvice"
                            label="Best advice I've ever received"
                            multiline
                            rows="4"
                            value={this.state.bestAdvice}
                            onChange={(e) => this.setState({ bestAdvice: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'BestAdvice')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'BestAdvice') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="somethingFunny"
                            label="Something funny that happened to me while preaching"
                            multiline
                            rows="4"
                            value={this.state.somethingFunny}
                            onChange={(e) => this.setState({ somethingFunny: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'SomethingFunny')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'SomethingFunny') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="favoriteIllustration"
                            label="One of my favorite sermon illustrations"
                            multiline
                            rows="4"
                            value={this.state.favoriteIllustration}
                            onChange={(e) => this.setState({ favoriteIllustration: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'FavoriteIllustration')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'FavoriteIllustration') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="ifICould"
                            label="If I could preach one more time, I would say"
                            multiline
                            rows="4"
                            value={this.state.ifICould}
                            onChange={(e) => this.setState({ ifICould: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'IfICould')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'IfICould') }} />}
                        />
                        <Button disabled={!this.state.enableSubmit} type="submit" color="primary" variant="contained" className={classes.formButton}>
                            Save Changes
                        </Button>
                    </div>
                </form>
            </React.Fragment>
        );
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Your profile has been updated</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                {this.state.loading &&
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ marginTop: "50px" }} />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading...
                        </Typography>
                    </div>
                }
                {!this.state.loading &&
                    <Paper elevation={1} className={classes.paper}>
                        <Toolbar>
                            <Typography variant="h5">
                                My Story
                        </Typography>
                            <Button href={this.props.user.publicProfileUrl} target="blank" className={classes.viewButton}>(View public profile)</Button>
                        </Toolbar>
                        {this.renderEditMode()}
                    </Paper>
                }
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(MyStoryPage));