import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import ApplicationUser from '../../entities/ApplicationUser';
import MyChurch from '../../entities/MyChurch';
import { ProfileService } from '../../services/ProfileService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AppConfig from '../../entities/AppConfig';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            maxWidth: '700px',
            marginLeft: 'auto',
            marginRight: 'auto',
            minHeight: '75vh',
            overflowY: 'auto'
        },
        viewButton: {
            marginLeft: 'auto'
        },
        lineWrapper: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        editForm: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        formButton: {
            marginTop: '15px',
            marginLeft: 'auto',
            display: 'flex'
        },
        formControl: {
            width: '100%'
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
    config: AppConfig;
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    name: string;
    description: string;
    denomination: string;
    age: string;
    attendance: string;
    medianAudienceAge: string;
    primaryEthnicity: string;
    serviceStyle: string;
    phoneNumber: string;
    emailAddress: string;
    website: string;
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    savedMyChurch?: MyChurch;
    showUpdateSuccessMessage: boolean;
    religiousConsentChecked: boolean;
    errors: FieldValidationError[];
    denominations: { name: string }[];
    churchAges: { name: string }[];
    churchAttendances: { name: string }[];
    churchMedianAudienceAges: { id: number, name: string }[];
    churchPrimaryEthnicities: { name: string }[];
    churchServiceStyles: { id: number, name: string }[];
    usStates: { name: string, postalAbbreviation: string }[];
    canadianProvinces: { name: string, postalAbbreviation: string }[];
    countries: { name: string, twoCharCode: string, threeCharCode: string }[];
}

class MyChurchPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            name: '',
            description: '',
            denomination: '',
            age: '',
            attendance: '',
            medianAudienceAge: '',
            primaryEthnicity: '',
            serviceStyle: '',
            phoneNumber: '',
            emailAddress: '',
            website: '',
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            showUpdateSuccessMessage: false,
            religiousConsentChecked: true,
            errors: [],
            denominations: [],
            churchAges: [],
            churchAttendances: [],
            churchMedianAudienceAges: [],
            churchPrimaryEthnicities: [],
            churchServiceStyles: [],
            usStates: [],
            canadianProvinces: [],
            countries: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - My Church";

        try {
            const myChurch = await ProfileService.getMyChurch();

            if (myChurch) {
                this.setState({
                    loading: false,
                    enableSubmit: true,
                    name: myChurch.name,
                    description: myChurch.description,
                    denomination: myChurch.denomination,
                    age: myChurch.age,
                    attendance: myChurch.attendance,
                    medianAudienceAge: myChurch.medianAudienceAge,
                    primaryEthnicity: myChurch.primaryEthnicity,
                    serviceStyle: myChurch.serviceStyle,
                    phoneNumber: myChurch.phoneNumber,
                    emailAddress: myChurch.emailAddress,
                    website: myChurch.website,
                    streetAddress: myChurch.streetAddress,
                    city: myChurch.city,
                    state: myChurch.state,
                    postalCode: myChurch.postalCode,
                    country: myChurch.country,
                    savedMyChurch: myChurch,
                    errors: [],
                });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }

        // The following calls aren't necessary to use the interface, so if there are any errors, ignore them
        ProfileService.getDenominations()
            .then(denominations => this.setState({ denominations: denominations! }));

        ProfileService.getChurchAges()
            .then(churchAges => this.setState({ churchAges: churchAges! }));

        ProfileService.getChurchAttendances()
            .then(churchAttendances => this.setState({ churchAttendances: churchAttendances! }));

        ProfileService.getChurchMedianAudienceAges()
            .then(churchMedianAudienceAges => this.setState({ churchMedianAudienceAges: churchMedianAudienceAges! }));

        ProfileService.getChurchPrimaryEthnicities()
            .then(churchPrimaryEthnicities => this.setState({ churchPrimaryEthnicities: churchPrimaryEthnicities! }));

        ProfileService.getChurchServiceStyles()
            .then(churchServiceStyles => this.setState({ churchServiceStyles: churchServiceStyles! }));

        ProfileService.getUSStates()
            .then(usStates => this.setState({ usStates: usStates! }));

        ProfileService.getCanadianProvinces()
            .then(canadianProvinces => this.setState({ canadianProvinces: canadianProvinces! }));

        ProfileService.getCountries()
            .then(countries => this.setState({ countries: countries! }));
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit || !this.state.religiousConsentChecked) {
            return;
        }
        try {
            this.setState({ enableSubmit: false });
            const response = await ProfileService.updateMyChurch(this.state.name, this.state.description, this.state.denomination, this.state.age, this.state.attendance, this.state.medianAudienceAge, this.state.primaryEthnicity, this.state.serviceStyle, this.state.phoneNumber, this.state.emailAddress, this.state.website, this.state.streetAddress, this.state.city, this.state.state, this.state.postalCode, this.state.country);

            if (ServerResponse.isServerResponse<ApplicationUser>(response)) {

                this.setState({
                    savedMyChurch: {
                        name: this.state.name,
                        description: this.state.description,
                        denomination: this.state.denomination,
                        age: this.state.age,
                        attendance: this.state.attendance,
                        medianAudienceAge: this.state.medianAudienceAge,
                        primaryEthnicity: this.state.primaryEthnicity,
                        serviceStyle: this.state.serviceStyle,
                        phoneNumber: this.state.phoneNumber,
                        emailAddress: this.state.emailAddress,
                        website: this.state.website,
                        streetAddress: this.state.streetAddress,
                        city: this.state.city,
                        state: this.state.state,
                        postalCode: this.state.postalCode,
                        country: this.state.country,
                    },
                    errors: [],
                    enableSubmit: true,
                    showUpdateSuccessMessage: true
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    renderEditMode() {
        const classes = this.props.classes;

        return (
            <React.Fragment>
                <form onSubmit={(e) => this.handleFormSubmit(e)}>
                    <div className={classes.lineWrapper}>
                        <Typography variant="h6">About My Church</Typography>
                        <Divider />
                    </div>
                    <div className={classes.editForm}>
                        <TextField
                            margin="dense"
                            fullWidth
                            id="name"
                            label="Name"
                            value={this.state.name}
                            onChange={(e) => this.setState({ name: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'Name')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Name') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="description"
                            label="Description"
                            multiline
                            rows="4"
                            value={this.state.description}
                            onChange={(e) => this.setState({ description: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'Description')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Description') }} />}
                        />
                        {this.state.denominations.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Denomination')}>
                                <InputLabel htmlFor="denomination">Denomination</InputLabel>
                                <Select
                                    value={this.state.denomination}
                                    onChange={(e) => this.setState({ denomination: e.target.value as string })}
                                    inputProps={{
                                        name: 'denomination',
                                        id: 'denomination',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.denominations.map(denomination => {
                                        return (
                                            <MenuItem
                                                value={denomination.name}
                                                key={denomination.name}
                                                selected={denomination.name === this.state.denomination}
                                            >
                                                {denomination.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Denomination') }} />
                            </FormControl>
                        }
                        {this.state.denominations.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="denomination"
                                label="Denomination"
                                value={this.state.denomination}
                                onChange={(e) => this.setState({ denomination: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Denomination')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Denomination') }} />}
                            />
                        }
                        {this.state.churchAges.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Age')}>
                                <InputLabel htmlFor="churchAge">Age</InputLabel>
                                <Select
                                    value={this.state.age}
                                    onChange={(e) => this.setState({ age: e.target.value as string })}
                                    inputProps={{
                                        name: 'churchAge',
                                        id: 'churchAge',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.churchAges.map(churchAge => {
                                        return (
                                            <MenuItem
                                                value={churchAge.name}
                                                key={churchAge.name}
                                                selected={churchAge.name === this.state.age}
                                            >
                                                {churchAge.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Age') }} />
                            </FormControl>
                        }
                        {this.state.churchAges.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="churchAge"
                                label="Age"
                                value={this.state.age}
                                onChange={(e) => this.setState({ age: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Age')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Age') }} />}
                            />
                        }
                        {this.state.churchServiceStyles.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'ServiceStyle')}>
                                <InputLabel htmlFor="serviceStyle">Service Style</InputLabel>
                                <Select
                                    value={this.state.serviceStyle}
                                    onChange={(e) => this.setState({ serviceStyle: e.target.value as string })}
                                    inputProps={{
                                        name: 'serviceStyle',
                                        id: 'serviceStyle',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.churchServiceStyles.map(serviceStyle => {
                                        return (
                                            <MenuItem
                                                value={serviceStyle.name}
                                                key={serviceStyle.name}
                                                selected={serviceStyle.name === this.state.serviceStyle}
                                            >
                                                {serviceStyle.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ServiceStyle') }} />
                            </FormControl>
                        }
                        {this.state.churchServiceStyles.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="serviceStyle"
                                label="Service Style"
                                value={this.state.serviceStyle}
                                onChange={(e) => this.setState({ serviceStyle: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'ServiceStyle')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ServiceStyle') }} />}
                            />
                        }
                        {this.state.churchPrimaryEthnicities.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'PrimaryEthnicity')}>
                                <InputLabel htmlFor="primaryEthnicity">Predominant Ethnicity</InputLabel>
                                <Select
                                    value={this.state.primaryEthnicity}
                                    onChange={(e) => this.setState({ primaryEthnicity: e.target.value as string })}
                                    inputProps={{
                                        name: 'primaryEthnicity',
                                        id: 'primaryEthnicity',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.churchPrimaryEthnicities.map(primaryEthnicity => {
                                        return (
                                            <MenuItem
                                                value={primaryEthnicity.name}
                                                key={primaryEthnicity.name}
                                                selected={primaryEthnicity.name === this.state.primaryEthnicity}
                                            >
                                                {primaryEthnicity.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'PrimaryEthnicity') }} />
                            </FormControl>
                        }
                        {this.state.churchPrimaryEthnicities.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="primaryEthnicity"
                                label="Predominant Ethnicity"
                                value={this.state.primaryEthnicity}
                                onChange={(e) => this.setState({ primaryEthnicity: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'PrimaryEthnicity')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'PrimaryEthnicity') }} />}
                            />
                        }
                        {this.state.churchAttendances.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Attendance')}>
                                <InputLabel htmlFor="attendance">Average Attendance</InputLabel>
                                <Select
                                    value={this.state.attendance}
                                    onChange={(e) => this.setState({ attendance: e.target.value as string })}
                                    inputProps={{
                                        name: 'attendance',
                                        id: 'attendance',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.churchAttendances.map(attendance => {
                                        return (
                                            <MenuItem
                                                value={attendance.name}
                                                key={attendance.name}
                                                selected={attendance.name === this.state.attendance}
                                            >
                                                {attendance.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Attendance') }} />
                            </FormControl>
                        }
                        {this.state.churchAttendances.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="attendance"
                                label="Average Attendance"
                                value={this.state.attendance}
                                onChange={(e) => this.setState({ attendance: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Attendance')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Attendance') }} />}
                            />
                        }
                        {this.state.churchMedianAudienceAges.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'MedianAudienceAge')}>
                                <InputLabel htmlFor="medianAudienceAge">Average Audience Age</InputLabel>
                                <Select
                                    value={this.state.medianAudienceAge}
                                    onChange={(e) => this.setState({ medianAudienceAge: e.target.value as string })}
                                    inputProps={{
                                        name: 'medianAudienceAge',
                                        id: 'medianAudienceAge',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.churchMedianAudienceAges.map(medianAudienceAge => {
                                        return (
                                            <MenuItem
                                                value={medianAudienceAge.name}
                                                key={medianAudienceAge.name}
                                                selected={medianAudienceAge.name === this.state.medianAudienceAge}
                                            >
                                                {medianAudienceAge.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'MedianAudienceAge') }} />
                            </FormControl>
                        }
                        {this.state.churchMedianAudienceAges.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="medianAudienceAge"
                                label="Average Audience Age"
                                value={this.state.medianAudienceAge}
                                onChange={(e) => this.setState({ medianAudienceAge: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'MedianAudienceAge')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'MedianAudienceAge') }} />}
                            />
                        }

                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    defaultChecked={true}
                                    onChange={(e) => this.setState({ religiousConsentChecked: e.target.checked })}
                                    size="small"
                                    value={this.state.religiousConsentChecked}
                                />
                            }
                            label={
                                <div style={{fontSize: 11}}>
                                    By checking this box, I affirm that I am voluntarily providing information concerning my religious beliefs to Outreach, and
                                    I hereby give my consent for Outreach to collect this information and to use, store, disclose, analyze, delete, or modify
                                    any data containing this information in accordance with
                                    <a href={this.props.config.mainSiteUrl + "/Content/privacy-policy" } style={{ color: "revert" }}> Outreach’s Privacy Policy</a>.
                                </div>
                            }
                            style={{marginTop: 15, marginBottom: 15}}
                        />

                        <Button disabled={!this.state.enableSubmit || !this.state.religiousConsentChecked} type="submit" color="primary" variant="contained" className={classes.formButton}>
                            Save Changes
                        </Button>
                    </div>
                    <div className={classes.lineWrapper}>
                        <Typography variant="h6">Church Location and Contact Info</Typography>
                        <Divider />
                    </div>
                    <div className={classes.editForm}>
                        <TextField
                            margin="dense"
                            fullWidth
                            id="phoneNumber"
                            label="Phone Number"
                            type="tel"
                            value={this.state.phoneNumber}
                            onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'ContactPhoneNumber')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ContactPhoneNumber') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="emailAddress"
                            label="Email Address"
                            type="tel"
                            value={this.state.emailAddress}
                            onChange={(e) => this.setState({ emailAddress: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'ContactEmailAddress')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ContactEmailAddress') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="website"
                            label="Website"
                            value={this.state.website}
                            onChange={(e) => this.setState({ website: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'WebsiteUrl')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'WebsiteUrl') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="streetAddress"
                            label="Street Address"
                            value={this.state.streetAddress}
                            onChange={(e) => this.setState({ streetAddress: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'StreetAddress')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'StreetAddress') }} />}
                        />
                        <TextField
                            margin="dense"
                            fullWidth
                            id="city"
                            label="City"
                            value={this.state.city}
                            onChange={(e) => this.setState({ city: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'City')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'City') }} />}
                        />
                        {this.state.usStates.length > 0 && this.state.country === 'United States of America' &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'State')}>
                                <InputLabel htmlFor="state">State</InputLabel>
                                <Select
                                    value={this.state.state}
                                    onChange={(e) => this.setState({ state: e.target.value as string })}
                                    inputProps={{
                                        name: 'state',
                                        id: 'state',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.usStates.map(state => {
                                        return (
                                            <MenuItem
                                                value={state.name}
                                                key={state.name}
                                                selected={state.name === this.state.state}
                                            >
                                                {state.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'State') }} />
                            </FormControl>
                        }
                        {this.state.canadianProvinces.length > 0 && this.state.country === 'Canada' &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'State')}>
                                <InputLabel htmlFor="state">Province</InputLabel>
                                <Select
                                    value={this.state.state}
                                    onChange={(e) => this.setState({ state: e.target.value as string })}
                                    inputProps={{
                                        name: 'state',
                                        id: 'state',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.canadianProvinces.map(province => {
                                        return (
                                            <MenuItem
                                                value={province.name}
                                                key={province.name}
                                                selected={province.name === this.state.state}
                                            >
                                                {province.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'State') }} />
                            </FormControl>
                        }
                        {(!(this.state.country === 'United States of America' || this.state.country === 'Canada') || this.state.usStates.length === 0 || this.state.canadianProvinces.length === 0) &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="state"
                                label="State/Province"
                                value={this.state.state}
                                onChange={(e) => this.setState({ state: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'State')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'State') }} />}
                            />
                        }
                        <TextField
                            margin="dense"
                            fullWidth
                            id="postalCode"
                            label="Postal Code"
                            value={this.state.postalCode}
                            onChange={(e) => this.setState({ postalCode: e.target.value })}
                            error={FieldValidationError.isFieldInError(this.state.errors, 'PostalCode')}
                            helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'PostalCode') }} />}
                        />
                        {this.state.countries.length > 0 &&
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Country')}>
                                <InputLabel htmlFor="country">Country</InputLabel>
                                <Select
                                    value={this.state.country}
                                    onChange={(e) => this.setState({ country: e.target.value as string })}
                                    inputProps={{
                                        name: 'country',
                                        id: 'country',
                                    }}
                                >
                                    <MenuItem value="" />
                                    {this.state.countries.map(country => {
                                        return (
                                            <MenuItem
                                                value={country.name}
                                                key={country.name}
                                                selected={country.name === this.state.country}
                                            >
                                                {country.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Country') }} />
                            </FormControl>
                        }
                        {this.state.countries.length === 0 &&
                            <TextField
                                margin="dense"
                                fullWidth
                                id="country"
                                label="Country"
                                value={this.state.country}
                                onChange={(e) => this.setState({ country: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Country')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Country') }} />}
                            />
                        }
                        <Button disabled={!this.state.enableSubmit || !this.state.religiousConsentChecked} type="submit" color="primary" variant="contained" className={classes.formButton}>
                            Save Changes
                        </Button>
                    </div>

                </form>
            </React.Fragment>
        );
    }

    render() {
        const classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Your profile has been updated</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                {this.state.loading &&
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ marginTop: "50px" }} />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading...
                        </Typography>
                    </div>
                }
                {!this.state.loading &&
                    <Paper elevation={1} className={classes.paper}>
                        <Toolbar>
                            <Typography variant="h5">
                                My Church
                        </Typography>
                            <Button href={this.props.user.publicProfileUrl} target="blank" className={classes.viewButton}>(View public profile)</Button>
                        </Toolbar>
                        {this.renderEditMode()}
                    </Paper>
                }
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(MyChurchPage));