import * as React from 'react';
import AvatarEditor from 'react-avatar-editor';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Slider from '@material-ui/core/Slider';
import withRoot from '../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        slider: {
            padding: '22px 0px',
        },
        cropDialog: {
            minWidth: '400px',
            overflow: 'hidden',
            [theme.breakpoints.down('xs')]: {
                minWidth: 'inherit'
            },
        },
    });

interface ImageExportConfiguration {
    width: number;
    height: number;
}

export interface ExportedImage {
    width: number;
    height: number;
    base64ImageData: string;
}

interface Props extends WithStyles<typeof styles> {
    open: boolean;
    onClose: () => void;
    imageToEdit: string;
    onImageEdited: (exportedImages: ExportedImage[]) => void;
    disabled?: boolean;
    editorWidth?: number;
    editorHeight?: number;
    exportConfigurations?: ImageExportConfiguration[];
    exportJpgQuality?: number;
}

interface State {
    cropScale: number;
    disabled: boolean;
}

class EditImageDialog extends React.Component<Props, State> {
    cropperRef: AvatarEditor | null;

    constructor(props: Props) {
        super(props);

        this.cropperRef = null;
        this.state = {
            cropScale: 1,
            disabled: false
        };
    }

    onAcceptEdit = () => {
        const { editorWidth, editorHeight, exportJpgQuality, exportConfigurations } = this.props;
        const disabled = this.props.disabled || this.state.disabled;
        const avatarEditor = this.cropperRef;
        if (disabled || !avatarEditor) {
            return;
        }
        this.setState({ disabled: true });

        if (exportConfigurations) {
            let exportedImages = exportConfigurations.map(c => this.exportJpg(c.width, c.height, avatarEditor, exportJpgQuality));
            this.setState({ disabled: false }, () => this.props.onImageEdited(exportedImages));
        } else {
            let width = editorWidth ? editorWidth : 500;
            let height = editorHeight ? editorHeight : 500;
            let exportedImage = this.exportJpg(width, height, avatarEditor, exportJpgQuality);
            this.setState({ disabled: false }, () => this.props.onImageEdited([exportedImage]));
        }
    }

    exportJpg = (width: number, height: number, avatarEditor: AvatarEditor, jpgQuality?: number) => {
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const image = avatarEditor.getImage();
        const ctx = canvas.getContext('2d');
        ctx!.drawImage(image, 0, 0, width, height);

        let exportQuality = jpgQuality || .7;
        const base64ImageData = canvas.toDataURL("image/jpeg", exportQuality);

        let exportedImage: ExportedImage = {
            width,
            height,
            base64ImageData
        };

        return exportedImage;
    }

    render() {
        let classes = this.props.classes;
        const { open, onClose, imageToEdit, editorWidth, editorHeight } = this.props;

        const disabled = this.props.disabled || this.state.disabled;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="crop-dialog-title"
                aria-describedby="crop-dialog-description"
                PaperProps={{ style: { maxWidth: 'unset' } }}
            >
                <DialogTitle id="crop-dialog-title">Crop Image</DialogTitle>
                <DialogContent className={classes.cropDialog}>
                    <AvatarEditor
                        style={{ margin: '0 auto', marginBottom: '30px', display: 'block' }}
                        ref={(cropper) => this.cropperRef = cropper}
                        image={imageToEdit}
                        width={editorWidth ? editorWidth : 250}
                        height={editorHeight ? editorHeight : 250}
                        border={10}
                        rotate={0}
                        scale={this.state.cropScale}
                    />
                    <Typography id="label">Zoom</Typography>
                    <Slider
                        className={classes.slider}
                        value={this.state.cropScale}
                        min={1}
                        max={3}
                        aria-labelledby="label"
                        onChange={(e, value) => this.setState({ cropScale: value as number })}
                        disabled={disabled}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={disabled} onClick={onClose} color="default">
                        Cancel
                    </Button>
                    <Button disabled={disabled} onClick={this.onAcceptEdit} color="primary">
                        Accept
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withRoot(withStyles(styles)(EditImageDialog));