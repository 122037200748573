import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppConfig from '../../../../entities/AppConfig';
import withRoot from '../../../../withRoot';
import VideoMediaDetail from './videoMediaDetail';
import { MediaStatus } from '../../../../entities/VideoMedia';


interface Props extends RouteComponentProps<{ id?: string, type?: string }> {
    config: AppConfig;
}

interface State {
    id: string | undefined;
    type: string | undefined;
}

class PendingVideoMedia extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
     
        this.state = {
            type: this.props.match.params.type,
            id: this.props.match.params.id
        };
    }

    onPathChange(path: string) {
        this.props.history.push(path);
    }

    onPathReplace(path: string) {
        this.props.history.replace(path);
    }

    render() {
        return (
            
            <VideoMediaDetail
                id={ this.state.id}
                type={ this.state.type}
                config={this.props.config} 
                status={MediaStatus.Pending}
                onPathChange={(path) => this.onPathChange(path)}
                onPathReplace={(path) => this.onPathReplace(path)}
            />
        );
    }
}

export default withRoot(PendingVideoMedia);