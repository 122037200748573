import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import ApplicationUser from '../../entities/ApplicationUser';
import FreeProSubscriptionAccess, { FreeProSubscriptionAccessStatus } from '../../entities/FreeProSubscriptionAccess';
import { AdminService } from '../../services/AdminService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        badState: {
            padding: theme.spacing() * 10
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 2,
            right: theme.spacing() * 2,
        },
        divider: {
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
        },
        dateField: {
            width: '45%',
            margin: '10px'
        },
        close: {
            padding: theme.spacing() / 2,
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        cardContainer: {
            marginBottom: "16px",
            width: "500px",
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        [theme.breakpoints.down('sm')]: {
            cardContainer: {
                width: '100%'
            },
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

type State = {
    enableSubmit: boolean;
    items?: FreeProSubscriptionAccess[];
    selectedItemId?: number;
    showAddAccessDialog: boolean;
    addAccessStartDate: string;
    addAccessEndDate: string;
    showAddAccessSuccessMessage: boolean;
    showDisableDialog: boolean;
    errors: FieldValidationError[];
};

class FreeProAccess extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            showAddAccessDialog: false,
            addAccessStartDate: this.getDateFormatDisplay(new Date().toLocaleDateString()),
            addAccessEndDate: this.getDateFormatDisplay(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString()),
            showAddAccessSuccessMessage: false,
            showDisableDialog: false,
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Free PRO Access";
        if (!this.state.enableSubmit) {
            return;
        }

        try {
            this.setState({ enableSubmit: false });
            const items = await AdminService.getFreeProSubscriptionAccess();
            if (items) {
                this.setState({ errors: [], items: items, enableSubmit: true });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    getDateFormatDisplay(dateString: string): string {

        const dateParts = dateString.split('/');
        // Clever pad start: https://stackoverflow.com/a/5366862
        return dateParts[2] + '-' + ('00' + dateParts[0]).substring(dateParts[0].length) + '-' + ('00' + dateParts[1]).substring(dateParts[1].length);
    }

    async disableFreeProAccess(id: number) {

        if (!this.state.enableSubmit) {
            return;
        }
        try {
            this.setState({ enableSubmit: false });
            const response = await AdminService.disableFreeProSubscriptionAccess(id);

            if (ServerResponse.isServerResponse<FreeProSubscriptionAccess[]>(response)) {

                this.setState({
                    errors: [],
                    items: response.data,
                    enableSubmit: true,
                    showDisableDialog: false
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDisableDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true, showDisableDialog: false });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showDisableDialog: false });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showDisableDialog: false });
        }
    }

    async handleAddAccessFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.addAccessStartDate && this.state.addAccessEndDate) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AdminService.addFreeProSubscriptionAccess(this.state.addAccessStartDate, this.state.addAccessEndDate);

                if (ServerResponse.isServerResponse<FreeProSubscriptionAccess[]>(response)) {

                    this.setState({
                        errors: [],
                        items: response.data,
                        enableSubmit: true,
                        addAccessStartDate: this.getDateFormatDisplay(new Date().toLocaleDateString()),
                        addAccessEndDate: this.getDateFormatDisplay(new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toLocaleDateString()),
                        showAddAccessDialog: false,
                        showAddAccessSuccessMessage: true
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showAddAccessDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showAddAccessDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showAddAccessDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true, showAddAccessDialog: false });
            }
        }
    }

    render() {
        const classes = this.props.classes;

        if (!this.props.user.isAdmin || !this.props.user.inAdminImpersonationMode) {
            return (
                <Redirect to="/" />
            );
        }

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showAddAccessSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showAddAccessSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Free pro was successfully changed.</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showAddAccessSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <div>
                    <Toolbar>
                        <Typography variant="h5">
                            Free PRO Access
                        </Typography>
                    </Toolbar>
                    {!this.state.items &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {this.state.items && this.state.items.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <CardMembershipIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            This user has not been given free PRO access. Add it below.
                        </Typography>
                    }
                    {this.state.items && this.state.items.length > 0 &&
                        <Grid container direction="column" justify="center" alignItems="center">
                            {this.state.items.map(access => {
                                return (
                                    <Card className={classes.cardContainer} key={access.id}>
                                        <CardContent>
                                            <Typography className={classes.cardStatus} variant="caption" color={access.status === FreeProSubscriptionAccessStatus.Active ? 'primary' : 'textSecondary'}>
                                                {access.status === FreeProSubscriptionAccessStatus.Expired && 'Expired'}
                                                {access.status === FreeProSubscriptionAccessStatus.Pending && 'Pending'}
                                                {access.status === FreeProSubscriptionAccessStatus.Active && 'Active'}
                                                {access.status === FreeProSubscriptionAccessStatus.Disabled && 'Disabled'}
                                            </Typography>
                                            <Typography variant="body1">
                                                Starts {access.startDate ? new Date(access.startDate).toLocaleDateString() : ''}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Ends {access.endDate ? new Date(access.endDate).toLocaleDateString() : ''}
                                            </Typography>
                                        </CardContent>
                                        <CardActions >
                                            <div style={{ marginLeft: "auto" }}>
                                                {(access.status === FreeProSubscriptionAccessStatus.Active || access.status === FreeProSubscriptionAccessStatus.Pending) &&
                                                    <Button disabled={!this.state.enableSubmit} color="secondary" size='small' onClick={() => this.setState({ selectedItemId: access.id, showDisableDialog: true })}>Disable</Button>
                                                }
                                            </div>
                                        </CardActions>
                                    </Card>
                                );
                            })}
                        </Grid>
                    }
                </div>
                <Fab className={classes.fab} color="primary" onClick={() => this.setState({ showAddAccessDialog: !this.state.showAddAccessDialog })}>
                    <AddIcon />
                </Fab>
                <Dialog
                    open={this.state.showAddAccessDialog}
                    onClose={() => this.setState({ showAddAccessDialog: !this.state.showAddAccessDialog })}
                    aria-labelledby="form-dialog-title"
                >
                    <form onSubmit={(e) => this.handleAddAccessFormSubmit(e)}>
                        <DialogTitle id="form-dialog-title">Add Free PRO Access</DialogTitle>
                        <DialogContent>
                            <Typography>Enter the date range that the user will be given free PRO access. All existing free PRO access that is active will be disabled.</Typography>
                            <TextField
                                type="date"
                                required
                                id="startDate"
                                label="Start Date"
                                className={classes.dateField}
                                value={this.state.addAccessStartDate}
                                onChange={e => this.setState({ addAccessStartDate: e.target.value })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                type="date"
                                required
                                id="endDate"
                                label="End Date"
                                className={classes.dateField}
                                value={this.state.addAccessEndDate}
                                onChange={e => this.setState({ addAccessEndDate: e.target.value })}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showAddAccessDialog: !this.state.showAddAccessDialog })} color="primary">
                                Cancel
                            </Button>
                            <Button disabled={!this.state.enableSubmit} type="submit" color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.showDisableDialog}
                    onClose={() => this.setState({ showDisableDialog: !this.state.showDisableDialog })}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Disable Free PRO Access</DialogTitle>
                    <DialogContent>
                        <Typography align="center">Are you sure you want to disable this free PRO access?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showDisableDialog: !this.state.showDisableDialog })} color="primary">
                            Cancel
                        </Button>
                        <Button color="secondary" disabled={!this.state.enableSubmit} onClick={() => this.disableFreeProAccess(this.state.selectedItemId!)}>
                            Disable
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(FreeProAccess));
