import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../withRoot';

import ErrorIcon from '@material-ui/icons/Error';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = (theme: Theme) =>
    createStyles({
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
    });

interface Props extends WithStyles<typeof styles> {
    onClose?: () => void;
    message?: string;
    autoHideDuration?: number;
}

type State = {
};

class ForgotPassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const classes = this.props.classes;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.props.message != null}
                autoHideDuration={this.props.autoHideDuration}
                onClose={this.props.onClose}
            >
                <SnackbarContent
                    className={classes.snackbarContent}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.snackbarMessage}>
                            <ErrorIcon className={classes.snackbarContentIcon} />
                            <span dangerouslySetInnerHTML={{ __html: this.props.message || "" }} />
                        </span>}
                />
            </Snackbar>
        );
    }
}

export default withRoot(withStyles(styles)(ForgotPassword));