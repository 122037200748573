export enum FreeProSubscriptionAccessStatus {
    Active = 0,
    Pending = 1,
    Expired = 2,
    Disabled = 3
}

export default interface FreeProSubscriptionAccess {
    id: number;
    startDate?: Date;
    endDate?: Date;
    status: FreeProSubscriptionAccessStatus;
    dateDisabled?: Date;
}