import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import withRoot from '../../../withRoot';

import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import TimerIcon from '@material-ui/icons/Timer';
import MovieCreationIcon from '@material-ui/icons/MovieCreation';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

import InfiniteScroll from 'react-infinite-scroller';

import AppConfig from '../../../entities/AppConfig';
import ApplicationUser from '../../../entities/ApplicationUser';
import { MediaItem, MediaType, MediaItemStatus } from '../../../entities/MediaItem';
import { MediaService, SearchMediaItemRequest, MediaDisableRequest, MediaEnableRequest } from '../../../services/MediaService';
import { ServerResponse } from '../../../services/ServiceHelper';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import SuccessSnackbar from '../../../components/SuccessSnackbar';
import ErrorSnackbar from '../../../components/ErrorSnackbar';
import { MediaStatus } from '../../../entities/VideoMedia';

const styles = (theme: Theme) =>
    createStyles({
        toolbar: {
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                padding: 0
            }
        },
        title: {
            flex: '0 0 auto',
            [theme.breakpoints.down('sm')]: {
                float: 'left'
            }
        },
        spacer: {
            flex: '1 1 100%',
        },
        sort: {
            display: "flex",
            [theme.breakpoints.down('sm')]: {
                display: 'block',
                float: 'right'
            }
        },
        switch: {
            width: 140
        },
        sortButton: {
            paddingRight: "6px",
            fontSize: "13px",
            opacity: .86,
            whiteSpace: "nowrap"
        },
        menuItem: {
            minWidth: "200px",
        },
        menuListItemIcon: {
            paddingLeft: "0px",
            paddingRight: "0px",
            marginRight: "0px"
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
            [theme.breakpoints.down('sm')]: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing() * 5,
            },
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing() * 5,
            },
        },
        infiniteScroll: {
            width: 'auto',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
            }
        },
        cardHeader: {
            paddingBottom: 0,
            paddingTop: 0
        },
        avatar: {
            margin: '5px 0px'
        },
        removeBorderRadius: {
            "&:first-child": {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
            },
            "&:last-child": {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            }
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 4,
            right: theme.spacing() * 4,
            [theme.breakpoints.down('md')]: {
                bottom: theme.spacing() * 2,
                right: theme.spacing() * 2,
            },
        },
    });

interface Props extends RouteComponentProps<{ type?: string }>, WithStyles<typeof styles> {
    config: AppConfig;
    user: ApplicationUser;
}

interface State {
    loading: boolean;
    mediaItems: MediaItem[];
    hasMore: boolean;
    sortBy: string;
    anchorSortMenu?: HTMLElement;
    sortDescending: boolean;
    filterMediaType: string;
    filterStatus: MediaItemStatus;
    anchorFilterMenu?: HTMLElement;
    anchorFilterStatusMenu?: HTMLElement;
    showSelectProducerDialog: boolean;
    showDisabledMedia: boolean;
    queryText: string;
    mediaItemToDisable?: MediaItem;
    mediaItemToEnable?: MediaItem;
    enableSubmit: boolean;
    openSpeedDial: boolean;
    disableReason: string;
    enableReason: string;
    successMessage?: string;
    errorMessage?: string;
    filterNeedsAttention?: boolean;
    filterNeedsaAttentionMenu?: HTMLElement;
}

class ManageMedia extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        let filterMediaType = "All";
        if (props.match.params.type) {
            switch (props.match.params.type) {
                case "preaching-slide":
                    filterMediaType = "Preaching Slide";
                    break;
                case "worship-lyric-slides":
                    filterMediaType = "Worship Lyric Slide";
                    break;
                case "video-illustrations":
                    filterMediaType = "Video Illustration";
                    break;
                case "motion-backgrounds":
                    filterMediaType = "Motion Background";
                    break;
                case "countdown-videos":
                    filterMediaType = "Countdown Video";
                    break;
                case "still-background":
                    filterMediaType = "Still Background";
                    break;
                case "documents":
                    filterMediaType = "Document";
                    break;
                default:
                    filterMediaType = "All";
            }
        }

        this.state = {
            loading: true,
            mediaItems: [],
            hasMore: true,
            sortBy: "title",
            sortDescending: false,
            filterMediaType: filterMediaType,
            showSelectProducerDialog: false,
            showDisabledMedia: false,
            queryText: "",
            enableSubmit: true,
            openSpeedDial: false,
            filterStatus: MediaItemStatus.Approved,
            disableReason: "",
            enableReason: "",
            filterNeedsAttention: undefined
        };
    }

    async componentDidMount() {
        document.title = "SermonCentral Admin - Manage All Media";
        this.searchMediaItems(true);
    }

    onChangeShowDisabledMedia = () => {
        const { showDisabledMedia } = this.state;
        this.setState({ showDisabledMedia: !showDisabledMedia }, () => this.searchMediaItems(true));
    }

    onChangeFilterBy(filterBy: string) {
        this.setState({ filterMediaType: filterBy, anchorFilterMenu: undefined }, () => this.searchMediaItems(true));
    }

    onChangeItemStatusBy(filterBy: MediaItemStatus) {
        this.setState({ filterStatus: filterBy, anchorFilterStatusMenu: undefined }, () => this.searchMediaItems(true));
    }

    onChangeSortBy(sortBy: string) {
        this.setState({ sortBy, anchorSortMenu: undefined }, () => this.searchMediaItems(true));
    }

    onFilterNeedsAttention(needsAttention: boolean | undefined){
        this.setState({filterNeedsAttention: needsAttention, filterNeedsaAttentionMenu: undefined}, () => this.searchMediaItems(true))
    }

    searchMediaItems = async (reset: boolean) => {
        const { mediaItems, hasMore, filterMediaType, showDisabledMedia, sortBy, sortDescending, queryText, enableSubmit, filterStatus, filterNeedsAttention } = this.state;

        if ((!reset && !hasMore) || !enableSubmit) {
            return;
        }
        if (reset) {
            this.setState({ loading: true });
        }

        let searchRequest: SearchMediaItemRequest = {
            query: queryText,
            skip: reset ? 0 : mediaItems.length,
            take: 10,
            sortBy: sortBy,
            sortDescending,
            type: filterMediaType,
            includeDisabled: showDisabledMedia,
            status: filterStatus,
            needsAttention: filterNeedsAttention
        };
        let response = await MediaService.searchMediaItems(searchRequest);
        if (ServerResponse.isSuccess(response)) {
            let mediaItemsResult = reset ? response.data : [...mediaItems, ...response.data];
            this.setState({ mediaItems: mediaItemsResult, loading: false, hasMore: response.data.length === 10 });
        }
    }

    onDisable = async () => {
        const { mediaItemToDisable, disableReason } = this.state;

        if (!mediaItemToDisable) {
            return;
        }

        this.setState({ enableSubmit: false });

        var request: MediaDisableRequest = {
            id: mediaItemToDisable.id,
            title: mediaItemToDisable.title,
            reason: disableReason
        };

        let response = await MediaService.requestDisable(request);

        if (ServerResponse.isSuccess(response)) {
            this.setState({
                enableSubmit: true,
                disableReason: "",
                mediaItemToDisable: undefined,
                successMessage: "A request has been sent to SermonCentral Admin. We will contact you as soon as possible."
            })
        } else {
            this.setState({
                enableSubmit: true,
                errorMessage: "We were unable to perform this request, please try again."
            })
        }
    }

    onEnable = async () => {
        const { mediaItemToEnable, enableReason } = this.state;

        if (!mediaItemToEnable) {
            return;
        }

        this.setState({ enableSubmit: false });

        var request: MediaEnableRequest = {
            id: mediaItemToEnable.id,
            title: mediaItemToEnable.title,
            reason: enableReason
        };

        let response = await MediaService.requestEnable(request);

        if (ServerResponse.isSuccess(response)) {
            this.setState({
                enableSubmit: true,
                enableReason: "",
                mediaItemToEnable: undefined,
                successMessage: "A request has been sent to SermonCentral Admin. We will contact you as soon as possible."
            })
        } else {
            this.setState({
                enableSubmit: true,
                errorMessage: "We were unable to perform this request, please try again."
            })
        }
    }

    highlightText(text: string, match: string) {
        if (!text) {
            return '';
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    getEditLink(mediaItem: MediaItem) {
        let status = mediaItem.status === MediaItemStatus.Approved ? 'approved' : 'pending';
        if (mediaItem.type === MediaType.Countdown_Video) {
            return `/media/video-media/${status}/edit/${mediaItem.id}`;
        } else if (mediaItem.type === MediaType.Motion_Background) {
            return `/media/video-media/${status}/edit/${mediaItem.id}`;
        } else if (mediaItem.type === MediaType.Video_Illustration) {
            return `/media/video-media/${status}/edit/${mediaItem.id}`;
        }

        return ``;
    }

    render() {
        const { classes } = this.props;
        const {
            loading,
            mediaItems,
            hasMore,
            filterMediaType,
            filterStatus,
            anchorFilterMenu,
            anchorFilterStatusMenu,
            showDisabledMedia,
            sortBy,
            sortDescending,
            anchorSortMenu,
            queryText,
            mediaItemToDisable,
            mediaItemToEnable,
            enableSubmit,
            openSpeedDial,
            disableReason,
            enableReason,
            filterNeedsAttention,
            filterNeedsaAttentionMenu
        } = this.state;

        return (
            <div>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Manage Media
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.sort}>
                        <FormControlLabel
                            control={<Switch color="primary" checked={showDisabledMedia} onChange={this.onChangeShowDisabledMedia} />}
                            label={<Typography style={{ width: 140 }}>Show Disabled Media</Typography>}
                        />
                    </div>
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={filterNeedsaAttentionMenu ? 'menu' : undefined} onClick={e => this.setState({ filterNeedsaAttentionMenu: e.currentTarget })}>
                            { filterNeedsAttention && "Needs Attention"}
                            { filterNeedsAttention === false && "Doesn't Need Attention"}
                            { filterNeedsAttention === undefined && "All Media"}
                        </Button>
                        <Menu
                            id="filter-status-menu"
                            anchorEl={filterNeedsaAttentionMenu}
                            open={filterNeedsaAttentionMenu !== undefined}
                            onClose={() => this.setState({ filterNeedsaAttentionMenu: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.onFilterNeedsAttention(undefined)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterNeedsAttention === undefined ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'All Media'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onFilterNeedsAttention(true)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterNeedsAttention ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Needs Attention'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onFilterNeedsAttention(false)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterNeedsAttention === false ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={`Doesn't Need Attention`} />
                            </MenuItem>

                        </Menu>
                    </div>
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={anchorFilterMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorFilterMenu: e.currentTarget })}>
                            {filterMediaType === 'All' && 'Media Type: All'}
                            {filterMediaType === 'Video Illustration' && 'Media Type: Video Illustrations'}
                            {filterMediaType === 'Motion Background' && 'Media Type: Motion Backgrounds'}
                            {filterMediaType === 'Countdown Video' && 'Media Type: Countdown Videos'}
                        </Button>
                        <Menu
                            id="filter-status-menu"
                            anchorEl={anchorFilterMenu}
                            open={anchorFilterMenu != null}
                            onClose={() => this.setState({ anchorFilterMenu: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeFilterBy('All')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterMediaType === 'All' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'All'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeFilterBy('Video Illustration')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterMediaType === 'Video Illustration' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Video Illustrations'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeFilterBy('Motion Background')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterMediaType === 'Motion Background' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Motion Background'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeFilterBy('Countdown Video')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterMediaType === 'Countdown Video' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Countdown Video'} />
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={anchorFilterStatusMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorFilterStatusMenu: e.currentTarget })}>
                            {filterStatus === MediaItemStatus.Approved && 'Status: Approved'}
                            {filterStatus === MediaItemStatus.Pending && 'Status: Pending'}
                            {filterStatus === MediaItemStatus.Rejected && 'Status: Rejected'}
                        </Button>
                        <Menu
                            id="filter-status-item-menu"
                            anchorEl={anchorFilterStatusMenu}
                            open={anchorFilterStatusMenu != null}
                            onClose={() => this.setState({ anchorFilterStatusMenu: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeItemStatusBy(MediaItemStatus.Approved)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterStatus === MediaItemStatus.Approved ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Approved'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeItemStatusBy(MediaItemStatus.Pending)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterStatus === MediaItemStatus.Pending ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Pending'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeItemStatusBy(MediaItemStatus.Rejected)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {filterStatus === MediaItemStatus.Rejected ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Rejected'} />
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={anchorSortMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorSortMenu: e.currentTarget })}>
                            {sortBy === 'dateAvailable' && 'Date Available'}
                            {sortBy === 'title' && 'Title'}
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={anchorSortMenu}
                            open={anchorSortMenu != null}
                            onClose={() => this.setState({ anchorSortMenu: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeSortBy('dateAvailable')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {sortBy === 'dateAvailable' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Date Available'} />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.onChangeSortBy('title')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {sortBy === 'title' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Title'} />
                            </MenuItem>
                        </Menu>

                        <IconButton aria-label="Reverse Direction" onClick={() => this.setState({ sortDescending: !sortDescending }, () => this.searchMediaItems(true))}>
                            {sortDescending ? <ArrowDownwardIcon style={{ fontSize: "14px" }} /> : <ArrowUpwardIcon style={{ fontSize: "14px" }} />}
                        </IconButton>
                    </div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={queryText}
                            onChange={(e) => this.setState({ queryText: e.target.value }, () => this.searchMediaItems(true))}
                        />
                    </div>
                </Toolbar>
                <Grid container direction="column" justify="center" alignItems="center">
                    {
                        loading
                        &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" align="center">
                                Loading...
                            </Typography>
                        </div>
                    }
                    {
                        !loading
                        &&
                        mediaItems.length === 0
                        &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <SearchIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            No matches found.
                        </Typography>
                    }
                    {
                        !loading
                        &&
                        mediaItems.length > 0
                        &&
                        <InfiniteScroll
                            className={classes.infiniteScroll}
                            pageStart={0}
                            threshold={0}
                            loadMore={() => this.searchMediaItems(false)}
                            hasMore={hasMore}
                            useWindow={false}
                            getScrollParent={() => document.getElementById('main')}
                            loader={<div key="loader" style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" align="center">
                                    Loading...
                            </Typography>
                            </div>}
                        >
                            {mediaItems.map(mediaItem =>
                                <Card className={classes.cardContainer} key={mediaItem.id}>
                                    <Grid container>
                                        <div style={{ flex: "1 1 auto" }} />
                                        { mediaItem.needsAttention &&
                                            <Typography align="right" variant="caption" color="error" style={{ margin: 10, marginBottom: 0 }}>
                                                Needs Attention
                                            </Typography>
                                        }
                                        <Typography align="right" variant="caption" color="primary" style={{ margin: 10, marginBottom: 0 }}>
                                            {mediaItem.status === MediaItemStatus.Approved && (mediaItem.isEnabled ? "Approved: Enabled" : "Approved: Disabled")}
                                            {mediaItem.status === MediaItemStatus.Pending && "Pending"}
                                            {mediaItem.status === MediaItemStatus.Rejected && "Rejected"}
                                        </Typography>
                                    </Grid>
                                    <CardHeader
                                        className={classes.cardHeader}
                                        avatar={<Avatar alt={mediaItem.mediaProducer} src={mediaItem.previewImageUrl} className={classes.avatar} />}
                                        title={<Typography variant="h6" dangerouslySetInnerHTML={{ __html: this.highlightText(mediaItem.title, queryText) }} />}
                                        subheader={<Typography variant="caption" component="div" >{mediaItem.mediaProducer} - {mediaItem.type}</Typography>}
                                    />
                                    <CardContent>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <Typography dangerouslySetInnerHTML={{ __html: mediaItem.summary }} />
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        {
                                            (!mediaItem.isEnabled && mediaItem.status === MediaItemStatus.Approved)
                                            &&
                                            <>
                                                <Button color="primary" disabled={!this.state.enableSubmit} onClick={() => this.setState({ mediaItemToEnable: mediaItem })}>
                                                    Enable
                                                </Button>
                                            </>
                                        }
                                        {
                                            this.getEditLink(mediaItem) &&
                                            (mediaItem.isEnabled && mediaItem.status === MediaItemStatus.Approved)
                                            &&
                                            <>
                                                <Button color="secondary" disabled={!this.state.enableSubmit} onClick={() => this.setState({ mediaItemToDisable: mediaItem })}>
                                                    Disable
                                                </Button>
                                            </>
                                        }
                                        <div style={{ marginLeft: "auto" }}>
                                            {mediaItem.status === MediaItemStatus.Approved &&
                                                <a href={mediaItem.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                    <IconButton color="primary" disabled={!this.state.enableSubmit}>
                                                        <EyeIcon />
                                                    </IconButton>
                                                </a>
                                            }
                                            {this.getEditLink(mediaItem) &&

                                                <Link to={this.getEditLink(mediaItem)} rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                                    <IconButton color="primary" disabled={!this.state.enableSubmit}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Link>
                                            }
                                        </div>
                                    </CardActions>
                                    <CardContent style={{ padding: 0, paddingBottom: 0 }}>
                                        <ExpansionPanel className={classes.removeBorderRadius}>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography style={{ fontStyle: "italic" }} color="textSecondary">
                                                    <strong>Date Available:</strong> {mediaItem.dateAvailable ? mediaItem.dateAvailable.toLocaleDateString() : "None"}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Typography style={{ fontStyle: "italic" }} color="textSecondary">
                                                    <strong>Topic{mediaItem.topics.length !== 1 && "s"}:</strong> {mediaItem.topics.length > 0 ? mediaItem.topics.join(', ') : 'None'}
                                                    <br />
                                                    <strong>Scripture{mediaItem.scriptureReferences.length !== 1 && "s"}:</strong> {mediaItem.scriptureReferences.length > 0 ? mediaItem.scriptureReferences.join(', ') : 'None'}
                                                    <br />
                                                    <strong>Style{mediaItem.styles.length !== 1 && "s"}:</strong> {mediaItem.styles.length > 0 ? mediaItem.styles.join(', ') : 'None'}
                                                    <br />
                                                    <strong>Genre{mediaItem.genres.length !== 1 && "s"}:</strong> {mediaItem.genres.length > 0 ? mediaItem.genres.join(', ') : 'None'}
                                                </Typography>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>

                                    </CardContent>
                                </Card>
                            )}
                        </InfiniteScroll>
                    }
                </Grid>
                {this.props.user.roles.some(a => ['HasCountdownVideoAccess', 'HasMotionBackgroundAccess', 'HasVideoIllustrationAccess'].indexOf(a) >= 0) &&
                    <SpeedDial
                        ariaLabel="Add Media"
                        className={classes.fab}
                        icon={<SpeedDialIcon />}
                        onClick={() => this.setState({ openSpeedDial: !openSpeedDial })}
                        onClose={() => this.setState({ openSpeedDial: false })}
                        open={openSpeedDial}
                    >
                        {this.props.user.roles.filter((r) => r === 'HasCountdownVideoAccess').length > 0 &&
                            <SpeedDialAction
                                icon={<TimerIcon />}
                                tooltipTitle={"Countdown Video"}
                                tooltipOpen
                                onClick={() => this.props.history.push("/media/video-media/pending/add/countdown-video")}
                            />
                        }
                        {this.props.user.roles.filter((r) => r === 'HasMotionBackgroundAccess').length > 0 &&
                            <SpeedDialAction
                                icon={<WallpaperIcon />}
                                tooltipTitle={"Motion Background"}
                                tooltipOpen
                                onClick={() => this.props.history.push("/media/video-media/pending/add/motion-background")}
                            />
                        }
                        {this.props.user.roles.filter((r) => r === 'HasVideoIllustrationAccess').length > 0 &&
                            <SpeedDialAction
                                icon={<MovieCreationIcon />}
                                tooltipTitle={"Video Illustration"}
                                tooltipOpen
                                onClick={() => this.props.history.push("/media/video-media/pending/add/video-illustration")}
                            />
                        }
                    </SpeedDial>
                }
                <Dialog
                    open={mediaItemToDisable != null}
                    onClose={() => this.setState({ mediaItemToDisable: undefined })}
                    aria-labelledby="disable-dialog-title"
                    aria-describedby="disable-dialog-description"
                >
                    <DialogTitle id="disable-dialog-title">Disable Media</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1' id="disable-dialog-description">
                            Are you sure you wish to request {mediaItemToDisable && mediaItemToDisable.title} to be disabled?
                        </DialogContentText>
                        <br />
                        <DialogContentText variant='subtitle2'>
                            Please fill in a comment to explain why this item should be disabled.
                        </DialogContentText>
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason"
                            multiline
                            fullWidth
                            rows="3"
                            required
                            defaultValue="Enter a reason..."
                            value={disableReason}
                            margin="normal"
                            variant="outlined"
                            onChange={e => this.setState({ disableReason: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!enableSubmit} onClick={() => this.setState({ mediaItemToDisable: undefined })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={!enableSubmit} onClick={async () => await this.onDisable()} color="secondary">
                            Request
                        </Button>
                    </DialogActions>
                </Dialog>
                <ErrorSnackbar
                    message={this.state.errorMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ errorMessage: undefined })}
                />
                <SuccessSnackbar
                    message={this.state.successMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ successMessage: undefined })}
                />
                <Dialog
                    open={mediaItemToEnable != null}
                    onClose={() => this.setState({ mediaItemToEnable: undefined })}
                    aria-labelledby="enable-dialog-title"
                    aria-describedby="enable-dialog-description"
                >
                    <DialogTitle id="enable-dialog-title">Enable Media</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle1' id="enable-dialog-description">
                            Are you sure you wish to request {mediaItemToEnable && mediaItemToEnable.title} to be enabled?
                        </DialogContentText>
                        <br />
                        <DialogContentText variant='subtitle2'>
                            Please fill in a comment to explain why this item should be enabled.
                        </DialogContentText>
                        <TextField
                            id="outlined-multiline-static"
                            label="Reason"
                            multiline
                            fullWidth
                            rows="3"
                            required
                            defaultValue="Enter a reason..."
                            value={enableReason}
                            margin="normal"
                            variant="outlined"
                            onChange={e => this.setState({ enableReason: e.target.value })}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!enableSubmit} onClick={() => this.setState({ mediaItemToEnable: undefined })} color="default">
                            Cancel
                        </Button>
                        <Button disabled={!enableSubmit} onClick={async () => await this.onEnable()} color="primary">
                            Request
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(ManageMedia));
