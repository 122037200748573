export interface VideoMedia {
    id: number;
    parentId?: number;
    type: VideoMediaType;
    title: string;
    summary: string;
    language: string;
    isEnabled: boolean;
    needsAttention: boolean;
    dateAvailable?: Date;
    listPrice?: number;
    discountedPrice?: number;
    thumbnailImageUrl?: string;
    posterImageUrl?: string;
    previewVideoUrl?: string;
    url: string;
    genres: string[];
    audiences: string[];
    styles: string[];
    scriptureReferences: string[];
    topics: string[];
    status: MediaStatus;
    adminComment?: string;
    producerComment?: string
}

export enum MediaStatus {
    Pending = 0,
    Rejected,
    Approved
}

export enum VideoMediaType {
    /// A countdown video.
    Countdown_Video = "Countdown Video",
    /// A video illustration.
    Video_Illustration = "Video Illustration",
    /// A motion background.
    Motion_Background = "Motion Background",
}