import { ServerResponse, WebClient } from '../services/ServiceHelper';
import DownloadAndViewCountByRegion from '../entities/DownloadAndViewCountByRegion';

export class MediaProducerService {
    public static async GetMediaProducersDownloadsAndViewsCount(year: number, month: number) {
        const response = await WebClient.Get<DownloadAndViewCountByRegion[]>(`api/MediaProducer/GetMediaProducersDownloadsAndViewsCount/${year}/${month}`);
        if (ServerResponse.isSuccess(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }
}