import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../../withRoot';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { FieldValidationError, ServerResponse, ServerModelValidationResponse } from '../../services/ServiceHelper';

import { AuthenticationService } from '../../services/AuthenticationService';

import Newsletter from '../../entities/Newsletter';
import ApplicationUser from '../../entities/ApplicationUser';

import Logo from '../../resources/logo.png';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
            paddingTop: theme.spacing() * 20,
        },
        registerContainer: {
            height: '100vh'
        },
        registerPaper: {
            padding: '0px 50px',
            maxWidth: 500
        },
        logoImage: {
            maxWidth: "90px",
            margin: "30px 0px 20px 0px"
        },
        textButton: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        submitButton: {
            minWidth: '150px'
        },
        submitButtonMobile: {
            margin: '20px 0px 70px 0px'
        },
        buttonContainer: {
            padding: '30px 0px 70px 0px'
        },
        link: {
            textDecoration: 'none'
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
            registerPaper: {
                margin: '0px 20px',
                padding: '0px 30px'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser | null;
    onSignIn: (user: ApplicationUser) => void;
}

type State = {
    enableSubmit: boolean;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    newsletters: Newsletter[];
    selectedNewsletterIds: number[];
    errors: FieldValidationError[];
};

class Register extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            newsletters: [],
            selectedNewsletterIds: [],
            errors: [],
        };
    }

    async componentDidMount() {
        document.title = "SermonCentral Account - Register";

        try {
            var newsletters = await AuthenticationService.getSignUpNewsletters();
            this.setState({ newsletters: newsletters, selectedNewsletterIds: newsletters.map(n => n.id) });
        }
        catch {
            // Not exactly catastrophic that they can't sign up for a newsletter, so ignore error
        }
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.firstName && this.state.lastName && this.state.email && this.state.password) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AuthenticationService.register(this.state.firstName, this.state.lastName, this.state.email, this.state.password, this.state.selectedNewsletterIds);

                if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
                    this.props.onSignIn(response.data);
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    toggleNewsletterSelection = (id: number) => {

        var selectedList: number[] = this.state.selectedNewsletterIds;
        var index = selectedList.indexOf(id);

        if (index < 0) {
            selectedList.push(id);
        } else {
            selectedList.splice(index, 1);
        }

        this.setState({
            selectedNewsletterIds: selectedList
        });
    }

    render() {

        const classes = this.props.classes;

        if (this.props.user) {
            return <Redirect to="/" />;
        }

        return (
            <Grid className={classes.registerContainer} container direction="column" justify="center" alignItems="center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbartMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Grid item>
                    <Paper className={classes.registerPaper}>
                        <Grid container direction="column" justify="center" alignItems="center">
                            <img src={Logo} className={classes.logoImage} />
                            <Typography variant="h5">Register</Typography>
                            <Typography variant="h6">Create a new SermonCentral account</Typography>
                        </Grid>
                        <form onSubmit={e => this.handleFormSubmit(e)}>
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'FirstName')}
                                required
                                autoFocus
                                fullWidth
                                id="firstName"
                                label="First Name"
                                type="text"
                                margin="normal"
                                onChange={e => this.setState({ firstName: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'FirstName') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'LastName')}
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                type="text"
                                margin="normal"
                                onChange={e => this.setState({ lastName: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'LastName') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Email')}
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                type="email"
                                margin="normal"
                                onChange={e => this.setState({ email: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Email') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Password')}
                                required
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                margin="normal"
                                onChange={e => this.setState({ password: e.target.value })}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Password') }} />}
                            />
                            {this.state.newsletters.map(newsletter => {
                                return (
                                    <Grid key={newsletter.id} direction="row">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.selectedNewsletterIds.indexOf(newsletter.id) >= 0}
                                                    onChange={(e) => this.toggleNewsletterSelection(parseInt(e.target.value, 10))}
                                                    value={newsletter.id.toString()}
                                                    color="primary"
                                                />
                                            }
                                            label={newsletter.name === 'Better Preaching Update' ? 'Sign up for the Better Preaching newsletter from SermonCentral.' : 'I\'d like to receive promotional offers for ministry resources.'}
                                        />
                                    </Grid>
                                );
                            })}
                            <Hidden smDown>
                                <Grid container direction="row" justify="space-between" alignItems="center" className={classes.buttonContainer}>
                                    <Link to="/" className={classes.link}>
                                        <Button variant="text" size="medium" color="primary" type="button" className={classes.textButton}>Already have an account?</Button>
                                    </Link>
                                    <Button variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButton}>Register</Button>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Link to="/" className={classes.link}>
                                        <Button variant="text" size="small" color="primary" type="button" className={classes.textButton}>Already have an account?</Button>
                                    </Link>
                                </Grid>
                                <Button fullWidth variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButtonMobile}>Register</Button>
                            </Hidden>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(Register));