import ApplicationUser from '../entities/ApplicationUser';
import Newsletter from '../entities/Newsletter';
import { ServerResponse, WebClient } from '../services/ServiceHelper';

export class AuthenticationService {

    public static decodeResetPasswordEmail(email: string) {

        return WebClient.Post<string>("api/Authentication/DecodeResetPasswordEmail", { email: email });
    }

    public static async getAuthenticatedUser() {

        var response = await WebClient.Get<ApplicationUser>("api/Authentication/GetAuthenticatedUser");
        if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getSignUpNewsletters() {

        var response = await WebClient.Get<Newsletter[]>("api/Authentication/GetSignUpNewsletters");
        if (ServerResponse.isServerResponse<Newsletter[]>(response)) {
            return response.data;
        }
        else {
            return [];
        }
    }

    public static register(firstName: string, lastName: string, email: string, password: string, newsletterIds: number[]) {
        return WebClient.Post<ApplicationUser>("api/Authentication/Register", { firstName: firstName, lastName: lastName, email: email, password: password, newsletterIds: newsletterIds });
    }

    public static resetPassword(email: string, password: string, confirmPassword: string, code: string) {
        return WebClient.Post<ApplicationUser>("api/Authentication/ResetPassword", { email: email, password: password, confirmPassword: confirmPassword, code: code });
    }

    public static sendResetPasswordEmail(email: string) {
        return WebClient.Post<null>("api/Authentication/SendResetPasswordEmail", { email: email });
    }

    public static signIn(email: string, password: string, rememberMe: boolean) {
        return WebClient.Post<ApplicationUser>("api/Authentication/SignIn", { email: email, password: password, rememberMe: rememberMe });
    }

    public static async signOut() {

        var response = await WebClient.Get<string>("api/Authentication/SignOut");
        if (ServerResponse.isServerResponse<string>(response)) {
            return response.data;
        }
        else {
            return '';
        }
    }
}