import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import * as React from 'react';
import EditImageDialog, { ExportedImage } from '../../../../components/EditImageDialog';
import { VideoMedia, MediaStatus } from '../../../../entities/VideoMedia';
import { FieldValidationError, ServerResponse } from '../../../../services/ServiceHelper';
import { CreateEncodingJobRequest, EncodingJobStatus, SaveMediaVideoAssetRequest, SaveVideoMediaPreviewImageRequest, VideoDownload, VideoMediaService } from '../../../../services/VideoMediaService';
import withRoot from '../../../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            minHeight: '70vh',
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto',
            [theme.breakpoints.up('sm')]: {
                minWidth: 800
            }
        }
    });

interface Props extends WithStyles<typeof styles> {
    videoMedia: VideoMedia;
    disableSubmit: boolean;
    status: MediaStatus
    onUpdate: (videoMedia: VideoMedia, onCompletion?: () => void) => void;
    onChangeDisableSubmit: (disableSubmit: boolean) => void;
    onError: (message: string) => void;
    onSuccess: (message: string) => void;
    activeReadOnlyPrompt: () => void;
}

interface State {
    videoDownloads: VideoDownload[];
    uploadedPreviewImage?: HTMLImageElement;
    newPreviewImage?: string;
    sourceVideoQuality: string;
    uploadVideoPercentage?: number;
    encodeVideoPercentage?: number;
    uploadVideoEnabled: boolean;
    errors: FieldValidationError[];
}

class VideoMediaUpload extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            videoDownloads: [],
            sourceVideoQuality: "",
            uploadVideoEnabled: props.videoMedia.previewVideoUrl == null,
            errors: []
        };
    }

    async componentDidMount() {
        let downloadResponse = this.props.status !== MediaStatus.Approved ? 
            await VideoMediaService.getPendingVideoMediaAvailableVideoDownloads(this.props.videoMedia.id) :    
            await VideoMediaService.getVideoMediaAvailableVideoDownloads(this.props.videoMedia.id);
        if (ServerResponse.isSuccess(downloadResponse)) {
            this.setState({ videoDownloads: downloadResponse.data });
        }
    }

    async handlePreviewImageUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.status === MediaStatus.Approved || this.props.status === MediaStatus.Rejected) {
            this.props.activeReadOnlyPrompt();
            return;
        }
        let file = e.target.files && e.target.files[0];
        // clear the value of the selected file to uploaded so that it can be selected again if there is an error 
        e.target.value = "";

        if (file) {
            let dataUrl = await this.readFileAsDataUrl(file);
            try {
                let image = await this.loadImage(dataUrl);
                this.setState({ uploadedPreviewImage: image });
            } catch (error) {
                this.props.onError("Unsupported image type");
            }
        }
    }

    onSavePreviewImage = async (exportedImages: ExportedImage[]) => {
        const { videoMedia, disableSubmit } = this.props;

        if (disableSubmit) {
            return;
        }

        this.props.onChangeDisableSubmit(true);

        const thumbnail = exportedImages.find(i => i.width === 512);
        const poster = exportedImages.find(i => i.width === 854);

        if (!thumbnail || !poster) {
            this.props.onError("Preview image could not be cropped");
            return;
        }

        this.setState({ errors: [] });

        let request: SaveVideoMediaPreviewImageRequest = {
            videoMediaId: videoMedia.id,
            base64ThumbnailImage: thumbnail.base64ImageData,
            base64PosterImage: poster.base64ImageData
        };
        let response = await VideoMediaService.saveVideoMediaPreviewImage(request);
        if (ServerResponse.isSuccess(response)) {
            const message = response.message;
            this.props.onUpdate(response.data, () => {
                this.setState({ uploadedPreviewImage: undefined, newPreviewImage: thumbnail.base64ImageData });
                this.props.onSuccess(message);
            });
        } else if (ServerResponse.isModelValidation(response)) {
            let errors = response.errors;
            let hasGenericError = FieldValidationError.hasGenericError(errors);
            this.setState({ errors });
            let errorMessage = hasGenericError ? FieldValidationError.getGenericErrorSummary(errors) : `${errors.length} Field${errors.length > 1 ? "s" : ""} Is Invalid`;
            this.props.onError(errorMessage);
        } else {
            this.props.onError(response.message);
        }
        this.props.onChangeDisableSubmit(false);
    }

    async handleVideoUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const { sourceVideoQuality } = this.state;
        const { videoMedia, disableSubmit } = this.props;

        let file = e.target.files && e.target.files[0];
        // clear the value of the selected file to uploaded so that it can be selected again if there is an error 
        e.target.value = "";

        if (!file || disableSubmit) {
            return;
        }

        if (!sourceVideoQuality) {
            let error: FieldValidationError = { field: "VideoPreview", errors: ["Must select source video quality"] };
            this.setState({ errors: [error] });
            this.props.onError(`Video quality not set`);
            return;
        }

        this.props.onChangeDisableSubmit(true);

        let uploadedFileNameSplit = file.name.split(".");
        let extension = uploadedFileNameSplit[uploadedFileNameSplit.length - 1];
        let fileName = videoMedia.title.replace(/[^A-Z0-9]/ig, "") + `.${extension}`;

        let uploadUrlResponse = await VideoMediaService.generateVideoUploadUrl(videoMedia.id, videoMedia.type, fileName);
        if (ServerResponse.isError(uploadUrlResponse)) {
            this.props.onChangeDisableSubmit(false);
            this.props.onError(`(${uploadUrlResponse.statusCode}) ${uploadUrlResponse.message}`);
            return;
        } else if (ServerResponse.isSuccess(uploadUrlResponse)) {
            let uploadResponse = await VideoMediaService.uploadSourceVideo(uploadUrlResponse.data.url, file, uploadVideoPercentage => this.setState({ uploadVideoPercentage }));
            if (ServerResponse.isError(uploadResponse)) {
                this.setState({ uploadVideoPercentage: undefined });
                this.props.onChangeDisableSubmit(false);
                this.props.onError(`(${uploadResponse.statusCode}) ${uploadResponse.message}`);
                return;
            }
    
            let encodingJobRequest: CreateEncodingJobRequest = {
                videoMediaId: videoMedia.id,
                videoMediaType: videoMedia.type,
                sourceFileName: fileName,
                sourceQuality: sourceVideoQuality
            };
            let encodeJobResponse = await VideoMediaService.createEncodingJob(encodingJobRequest);
            if (ServerResponse.isError(encodeJobResponse) || ServerResponse.isModelValidation(encodeJobResponse)) {
                this.setState({ uploadVideoPercentage: undefined });
                this.props.onChangeDisableSubmit(false);
                this.props.onError("Failed to create encoding job");
                return;
            }
    
            const encodeJobId = encodeJobResponse.data.jobId;
            this.setState({ uploadVideoPercentage: undefined, encodeVideoPercentage: 0 }, () => this.monitorEncodingJob(encodeJobId));
        }
    }

    monitorEncodingJob = (encodeJobId: string) => {
        const interval = setInterval(async () => {
            let response = await VideoMediaService.getEncodingJobStatus(encodeJobId);
            if (ServerResponse.isSuccess(response)) {
                let status = response.data;
                if (status.isComplete && status.error) {
                    this.setState({ encodeVideoPercentage: undefined });
                    this.props.onChangeDisableSubmit(false);
                    this.props.onError(status.error);
                    clearInterval(interval);
                } else if (status.isComplete) {
                    this.setState({ encodeVideoPercentage: 100 });
                    this.onVideoEncodingComplete(status);
                    clearInterval(interval);
                } else if (!status.isComplete && status.completionPercentage >= 0){
                    this.setState({ encodeVideoPercentage: status.completionPercentage });
                }
            }
        }, 15 * 1000);
    }

    onVideoEncodingComplete = async (status: EncodingJobStatus) => {
        const { videoMedia } = this.props;
        if (!status.isComplete || status.error || !status.preview || !status.downloads) {
            this.setState({ encodeVideoPercentage: undefined });
            this.props.onChangeDisableSubmit(false);
            this.props.onError("Video encode failed");
            return;
        }

        let request: SaveMediaVideoAssetRequest = {
            videoMediaId: videoMedia.id,
            previewVideoPath: status.preview.path,
            downloadDetails: status.downloads.map(d => ({
                path: d.path,
                extension: d.extension,
                quality: d.quality,
                duration: d.duration
            }))
        };
        let response = await VideoMediaService.saveVideoMediaVideoMedia(request);
        if (ServerResponse.isSuccess(response)) {
            this.props.onUpdate(response.data, this.onEncodingSuccess);

            let videoDownloadResponse = await VideoMediaService.getPendingVideoMediaAvailableVideoDownloads(videoMedia.id);
            if (ServerResponse.isSuccess(videoDownloadResponse)) {
                this.setState({ videoDownloads: videoDownloadResponse.data });
            }
        } else {
            this.setState({ encodeVideoPercentage: undefined });
            this.props.onError("Video encode failed");
        }
        this.props.onChangeDisableSubmit(false);
    }

    onEncodingSuccess = () => {
        this.setState({ uploadVideoEnabled: false, encodeVideoPercentage: undefined });
        this.props.onSuccess("Video successfully encoded");
    }

    loadImage(src: string) {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            let img = new Image();
            img.onload = function () {
                resolve(img);
            };
            img.onerror = function () {
                reject(img);
            };
            img.src = src;
        });
    }

    readFileAsDataUrl(file: File) {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                // FileReader.readAsDataURL always returns strings
                let dataUrl = reader.result as string;
                resolve(dataUrl);
            };
            reader.onabort = e => {
                reject(e);
            };
            reader.onerror = e => {
                reject(e);
            };
            reader.readAsDataURL(file);
        });
    }

    onDownload = async (videoDownload: VideoDownload) => {
        window.location.href = "api/VideoMedia/GetVideoDownload/" + videoDownload.id;
    }

    readOnlyState () {
        this.props.activeReadOnlyPrompt();
    }

    render() {
        const classes = this.props.classes;
        const {
            uploadedPreviewImage,
            newPreviewImage,
            sourceVideoQuality,
            videoDownloads,
            uploadVideoEnabled,
            uploadVideoPercentage,
            encodeVideoPercentage,
            errors,
        } = this.state;
        const { videoMedia, disableSubmit } = this.props;

        return (
            <div>
                <Grid container direction="column" justify="center" alignItems="center">
                    <Paper elevation={1} className={classes.paper} style={{ width: "100%", minHeight: 'unset' }}>
                        <CardHeader
                            title={<Typography variant="h6">Upload Media</Typography>}
                        />
                        <div style={{ marginBottom: 10 }}>
                            <FormHelperText
                                style={{ paddingBottom: 5 }}
                                error={FieldValidationError.isFieldInError(errors, 'Base64EncodedThumbnailImage') || FieldValidationError.isFieldInError(errors, 'Base64EncodedVideoPosterImage')}
                            >
                                Preview Image *
                            </FormHelperText>
                            {
                                videoMedia.thumbnailImageUrl || newPreviewImage
                                    ?
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <div style={{ height: 216, width: 384, overflow: "hidden" }}>
                                                <img alt="" style={{ display: "block", width: "100%" }} src={newPreviewImage ? newPreviewImage : videoMedia.thumbnailImageUrl} />
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <input
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                id="upload-preview-image-input"
                                                type="file"
                                                onChange={(e) => this.handlePreviewImageUpload(e)}
                                            />
                                            <InputLabel htmlFor="upload-preview-image-input">
                                                <IconButton
                                                    color="primary"
                                                    disabled={disableSubmit}
                                                    component="span"
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                    :
                                    <>
                                        {
                                            <input
                                                accept="image/*"
                                                style={{ display: "none" }}
                                                id="upload-preview-image-input"
                                                type="file"
                                                onChange={(e) => this.handlePreviewImageUpload(e)}
                                            />
                                        }
                                        <InputLabel htmlFor="upload-preview-image-input">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="primary"
                                                disabled={disableSubmit}
                                                component="span"
                                            >
                                                Add Preview Image
                                            </Button>
                                        </InputLabel>
                                    </>
                            }
                            <FormHelperText error={FieldValidationError.isFieldInError(errors, 'Base64EncodedThumbnailImage')} dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'Base64EncodedThumbnailImage') }} />
                            <FormHelperText error={FieldValidationError.isFieldInError(errors, 'Base64EncodedVideoPosterImage')} dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'Base64EncodedVideoPosterImage') }} />
                        </div>
                        <div>
                            <FormHelperText style={{ paddingBottom: 5 }} error={FieldValidationError.isFieldInError(errors, 'VideoPreview')} >Video *</FormHelperText>
                            {
                                videoMedia.previewVideoUrl
                                &&
                                !uploadVideoEnabled
                                &&
                                <>
                                    <Grid container alignItems="center">
                                        <Grid item>
                                            <InputLabel shrink>Preview Video</InputLabel>
                                            <br />
                                            <video controls controlsList="nodownload" preload="none" style={{ minHeight: 480, maxWidth: 854 }}>
                                                <source src={videoMedia.previewVideoUrl} type="video/mp4" />
                                            </video>
                                        </Grid>
                                        <Grid item>
                                            <InputLabel>
                                                <IconButton
                                                    color="primary"
                                                    disabled={disableSubmit}
                                                    component="span"
                                                    onClick={() => {this.props.status === MediaStatus.Approved || this.props.status === MediaStatus.Rejected ? this.props.activeReadOnlyPrompt() : this.setState({ uploadVideoEnabled: true })}}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                    {
                                        videoDownloads.length > 0
                                        &&
                                        <>
                                            <br />
                                            <InputLabel>Video Downloads</InputLabel>
                                            <br />
                                            {videoDownloads.map(videoDownload =>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    key={videoDownload.id}
                                                    onClick={() => this.onDownload(videoDownload)}
                                                    disabled={disableSubmit}
                                                    style={{ margin: 5 }}
                                                >
                                                    Download {videoDownload.extension.toUpperCase()} ({videoDownload.quality})
                                                </Button>
                                            )}
                                        </>
                                    }
                                </>
                            }
                            {
                                uploadVideoEnabled
                                &&
                                encodeVideoPercentage == null
                                &&
                                uploadVideoPercentage == null
                                &&
                                <>
                                    <FormControl style={{ width: 150, marginBottom: 10 }}>
                                        <InputLabel>Select Quality</InputLabel>
                                        <Select
                                            value={sourceVideoQuality}
                                            onChange={e => this.setState({ sourceVideoQuality: e.target.value as string })}
                                        >
                                            <MenuItem value={""}>Select Quality</MenuItem>
                                            <MenuItem value={"720p"}>720p</MenuItem>
                                            <MenuItem value={"1080p"}>1080p</MenuItem>
                                            <MenuItem value={"4K"}>4K</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <br />
                                    <input
                                        accept="video/x-matroska,video/mp4,video/x-m4v,video/*"
                                        style={{ display: "none" }}
                                        id="upload-video-input"
                                        type="file"
                                        onChange={(e) => this.handleVideoUpload(e)}
                                    />
                                    <InputLabel htmlFor="upload-video-input">
                                        <Button
                                            variant="contained"
                                            size="small"
                                            color="primary"
                                            disabled={disableSubmit}
                                            component="span"
                                        >
                                            Add Video
                                        </Button>
                                    </InputLabel>
                                </>
                            }
                            {
                                uploadVideoPercentage != null
                                &&
                                <>
                                    <InputLabel shrink>Upload Progress: {uploadVideoPercentage}%</InputLabel>
                                    <LinearProgress variant="determinate" value={uploadVideoPercentage} />
                                </>
                            }
                            {
                                encodeVideoPercentage != null
                                &&
                                <>
                                    <InputLabel shrink>Encoding Progress: {encodeVideoPercentage}%</InputLabel>
                                    <LinearProgress variant="determinate" value={encodeVideoPercentage} />
                                </>
                            }
                            <FormHelperText error={FieldValidationError.isFieldInError(errors, 'VideoPreview')} dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, 'VideoPreview') }} />
                        </div>
                    </Paper>
                </Grid>
                <EditImageDialog
                    open={uploadedPreviewImage != null}
                    onClose={() => this.setState({ uploadedPreviewImage: undefined })}
                    imageToEdit={uploadedPreviewImage ? uploadedPreviewImage.src : ""}
                    onImageEdited={this.onSavePreviewImage}
                    editorWidth={854}
                    editorHeight={480}
                    exportConfigurations={[{ width: 854, height: 480 }, { width: 512, height: 288 }]}
                    disabled={disableSubmit}
                />
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(VideoMediaUpload));