import {Button, Chip, CircularProgress, Divider, FormControl, FormHelperText, IconButton, Input, InputAdornment, 
    InputLabel, MenuItem, Paper, Select, Snackbar, SnackbarContent, Theme, createStyles, withStyles, WithStyles, TextField, Toolbar, Typography} from '@material-ui/core';

import green from '@material-ui/core/colors/green';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Sermon, { SermonStatus } from '../../entities/Sermon';
import SermonSeries from '../../entities/SermonSeries';
import { ContributionsService } from '../../services/ContributionsService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import { ArrayHelper } from '../../utilities/ArrayHelper';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            minHeight: '72vh',
            maxWidth: '900px',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto'
        },
        viewButton: {
            marginLeft: 'auto'
        },
        form: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        formButton: {
            marginTop: '15px',
            marginLeft: 'auto',
            display: 'flex'
        },
        formControl: {
            width: '100%'
        },
        chip: {
            margin: theme.spacing() / 2,
            color: "#FFF",
            backgroundColor: theme.palette.primary.light,
            "& svg": {
                color: "rgb(255,255,255,.7)",
                "&:hover": {
                    color: theme.palette.primary.main
                }
            }
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
            flexWrap: 'nowrap'
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center'
        },
        close: {
            padding: theme.spacing() / 2,
        },
        mainToolbar: {
            flexWrap: 'wrap'
        },
        snackBarText: {
            overflow: 'hidden'
        },
        rejectionNotes: {
            paddingTop: 15
        },
        sermonSeriesTitle: {
            maxWidth: 801
        }
    });

interface Props extends RouteComponentProps<{ id: string }>, WithStyles<typeof styles> {

}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    id?: number;
    originalSermonSeriesId?: number;
    sermonSeriesId?: number;
    originalSermonSeriesName: string;
    sermonSeriesName: string;
    title: string;
    intro: string;
    body: string;
    type: string;
    language: string;
    targetAudience: string;
    scriptures: string[];
    addScriptureReferenceText: string;
    tags: string[];
    addTopicText: string;
    date: Date;
    status: number;
    url: string;
    rejectionNotes?: string;
    updateSuccessMessage: string;
    showUpdateSuccessMessage: boolean;
    errors: FieldValidationError[];
    languages: { name: string }[];
    types: { name: string }[];
    targetAudiences: { id: number, name: string }[];
    sermonSeries: SermonSeries[];
}

class EditSermon extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            id: props.match.params.id ? parseInt(props.match.params.id, 10) : undefined,
            originalSermonSeriesId: undefined,
            sermonSeriesId: undefined,
            originalSermonSeriesName: '',
            sermonSeriesName: '',
            title: '',
            intro: '',
            body: '',
            type: 'Full Sermon',
            language: 'English',
            targetAudience: 'All',
            scriptures: [],
            addScriptureReferenceText: '',
            tags: [],
            addTopicText: '',
            date: new Date(),
            status: SermonStatus.Pending,
            url: '',
            updateSuccessMessage: '',
            showUpdateSuccessMessage: false,
            errors: [],
            languages: [],
            types: [],
            targetAudiences: [],
            sermonSeries: [],
        };
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        if (params.get('sermonSeriesId')) {
            this.setState({ sermonSeriesId: parseInt(params.get('sermonSeriesId')!, 10) });
        }

        document.title = "SermonCentral Account - Add Sermon";

        if (this.state.id) {
            document.title = "SermonCentral Account - Edit Sermon";

            try {
                const sermon = await ContributionsService.getSermon(this.state.id);

                if (sermon) {

                    this.setState({
                        loading: false,
                        enableSubmit: true,
                        originalSermonSeriesId: sermon.sermonSeriesId,
                        sermonSeriesId: sermon.sermonSeriesId,
                        originalSermonSeriesName: sermon.sermonSeriesName,
                        sermonSeriesName: sermon.sermonSeriesName,
                        title: sermon.title,
                        intro: sermon.intro,
                        body: sermon.body,
                        type: sermon.type,
                        language: sermon.language,
                        targetAudience: sermon.targetAudience,
                        scriptures: sermon.scriptures,
                        tags: sermon.tags,
                        date: new Date(sermon.date),
                        status: sermon.status,
                        url: sermon.url,
                        rejectionNotes: sermon.rejectionNotes
                    });
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError] });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        else {
            this.setState({ loading: false, enableSubmit: true });
        }

        // The following calls aren't necessary to use the interface, so if there are any errors, ignore them
        ContributionsService.getChurchServiceLanguages()
            .then(languages => this.setState({ languages: languages! }));

        ContributionsService.getSermonTypes()
            .then(types => this.setState({ types: types! }));

        ContributionsService.getChurchServiceTargetAudiences()
            .then(targetAudiences => this.setState({ targetAudiences: targetAudiences! }));

        ContributionsService.getAllSermonSeries()
            .then(sermonSeries => this.setState({ sermonSeries: sermonSeries! }));
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.title && this.state.intro && this.state.body) {
            try {
                this.setState({ enableSubmit: false });
                const sermonSeriesId = this.state.sermonSeriesId === 0 ? undefined : this.state.sermonSeriesId;
                const sermonSeriesName = this.state.sermonSeriesId === 0 ? '' : this.state.sermonSeriesName;
                const response = await ContributionsService.saveSermon(this.state.id, sermonSeriesId, sermonSeriesName, this.state.title, this.state.intro, this.state.body, this.state.language, this.state.targetAudience, this.state.type, this.state.tags, this.state.scriptures);

                if (ServerResponse.isServerResponse<Sermon>(response)) {

                    const sermon = response.data;

                    document.title = "SermonCentral Account - Edit Sermon";
                    this.props.history.replace(`/contributions/sermons/edit/${response.data.id}`);

                    this.setState({
                        loading: false,
                        enableSubmit: true,
                        id: sermon.id,
                        originalSermonSeriesId: sermon.sermonSeriesId,
                        sermonSeriesId: sermon.sermonSeriesId,
                        originalSermonSeriesName: sermon.sermonSeriesName,
                        sermonSeriesName: sermon.sermonSeriesName,
                        title: sermon.title,
                        intro: sermon.intro,
                        body: sermon.body,
                        type: sermon.type,
                        language: sermon.language,
                        targetAudience: sermon.targetAudience,
                        scriptures: sermon.scriptures,
                        tags: sermon.tags,
                        date: new Date(sermon.date),
                        status: sermon.status,
                        url: sermon.url,
                        updateSuccessMessage: response.message,
                        showUpdateSuccessMessage: true,
                        errors: []
                    });

                    // If the user added a new series, we need to reload list
                    ContributionsService.getAllSermonSeries()
                        .then(sermonSeries => this.setState({ sermonSeries: sermonSeries! }));
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true })
                            ;
                        document.getElementById('mainContainer')!.scrollIntoView();
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });

                    document.getElementById('mainContainer')!.scrollIntoView();
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });

                document.getElementById('mainContainer')!.scrollIntoView();
            }
        }
    }

    addScriptureReference() {

        if (this.state.addScriptureReferenceText.length > 0) {
            const updatedScriptures = ArrayHelper.addString(this.state.addScriptureReferenceText, this.state.scriptures);
            this.setState({
                scriptures: updatedScriptures,
                addScriptureReferenceText: ''
            });
        }
        else {
            this.setState({
                addScriptureReferenceText: ''
            });
        }
    }

    removeScriptureReference(scriptureReference: string) {

        if (scriptureReference.length > 0) {

            let scriptures = ArrayHelper.removeString(scriptureReference, this.state.scriptures);
            this.setState({ scriptures });
        }
    }

    addTopic() {

        if (this.state.addTopicText.length > 0) {
            const updatedTags = ArrayHelper.addString(this.state.addTopicText, this.state.tags);
            this.setState({
                tags: updatedTags,
                addTopicText: ''
            });
        }
        else {
            this.setState({
                addTopicText: ''
            });
        }
    }

    removeTopic(topic: string) {

        if (topic.length > 0) {
            let tags = ArrayHelper.removeString(topic, this.state.tags);
            this.setState({ tags });
        }
    }

    onSeriesChange(e: React.ChangeEvent<HTMLSelectElement>) {
        if (parseInt(e.target.value, 10) === -1) {
            this.setState({ originalSermonSeriesId: this.state.sermonSeriesId, sermonSeriesName: '', sermonSeriesId: parseInt(e.target.value, 10) });
        }
        else {
            this.setState({ sermonSeriesId: parseInt(e.target.value, 10) });
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                {this.state.loading &&
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ marginTop: "50px" }} />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading...
                        </Typography>
                    </div>
                }
                {!this.state.loading &&
                    <Paper elevation={1} className={classes.paper}>
                        <Toolbar className={classes.mainToolbar} id="mainContainer">
                            <IconButton onClick={() => this.props.history.goBack()}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography variant="h5">
                                {this.state.id ? 'Edit' : 'Share a new'} Sermon
                            </Typography>
                            {this.state.url &&
                                <Button href={this.state.url} target="blank" className={classes.viewButton}>(View on SermonCentral)</Button>
                            }
                        </Toolbar>
                        <form className={classes.form} onSubmit={(e) => this.handleFormSubmit(e)}>
                            {!this.state.id &&
                                <Typography variant="body2" component="h3">
                                    Thank you for sharing your sermon, which will inspire others in the process of preaching.
                                    Please complete the following submission form to upload your sermon.
                                </Typography>
                            }

                            {this.state.sermonSeriesId === -1 &&
                                <React.Fragment>
                                    <TextField
                                        margin="dense"
                                        id="sermonSeries"
                                        label="Enter New Sermon Series"
                                        value={this.state.sermonSeriesName}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={(e) => this.setState({ sermonSeriesName: e.target.value })}
                                        error={FieldValidationError.isFieldInError(this.state.errors, 'SermonSeriesTitle')}
                                        helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'SermonSeriesTitle') }} />}
                                    />
                                    <Button style={{ marginTop: '17px' }} onClick={() => this.setState({ sermonSeriesId: this.state.originalSermonSeriesId, sermonSeriesName: this.state.originalSermonSeriesName })}>Cancel</Button>
                                </React.Fragment>
                            }

                            {this.state.sermonSeriesId !== -1 && this.state.sermonSeries.length > 0 &&
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'SermonSeriesId')}>
                                    <InputLabel shrink={true} htmlFor="sermonSeries">Sermon Series</InputLabel>
                                    <Select
                                        margin="dense"
                                        value={this.state.sermonSeriesId}
                                        onChange={(e) => this.onSeriesChange(e as React.ChangeEvent<HTMLSelectElement>)}
                                        inputProps={{
                                            name: 'sermonSeries',
                                            id: 'sermonSeries',
                                        }}
                                    >
                                        <MenuItem value={0} />
                                        {this.state.sermonSeries.map(sermonSeries => {
                                            return (
                                                <MenuItem
                                                    value={sermonSeries.id}
                                                    key={sermonSeries.id}
                                                    selected={sermonSeries.id === this.state.sermonSeriesId}
                                                    className={classes.sermonSeriesTitle}
                                                >
                                                    <Typography variant="inherit" noWrap>
                                                        {sermonSeries.title}
                                                    </Typography>
                                                </MenuItem>
                                            );
                                        })}
                                        <Divider />
                                        <MenuItem value="-1">Add to New Sermon Series</MenuItem>
                                    </Select>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'SermonSeriesId') }} />
                                </FormControl>
                            }

                            {this.state.sermonSeriesId !== -1 && this.state.sermonSeries.length === 0 &&
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    id="sermonSeries"
                                    label="Sermon Series"
                                    value={this.state.sermonSeriesName}
                                    onChange={(e) => this.setState({ sermonSeriesName: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'SermonSeriesId')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'SermonSeriesId') }} />}
                                />
                            }

                            <TextField
                                margin="dense"
                                fullWidth
                                id="title"
                                label="Title"
                                required
                                value={this.state.title}
                                onChange={(e) => this.setState({ title: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Title')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Title') }} />}
                            />
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="intro"
                                label="Intro"
                                multiline
                                rows={3}
                                value={this.state.intro}
                                onChange={(e) => this.setState({ intro: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Description')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Description') }} />}
                            />
                            {this.state.types.length > 0 &&
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Type')}>
                                    <InputLabel htmlFor="type" required>Type</InputLabel>
                                    <Select
                                        margin="dense"
                                        required
                                        value={this.state.type}
                                        onChange={(e) => this.setState({ type: e.target.value as string })}
                                        inputProps={{
                                            name: 'type',
                                            id: 'type',
                                        }}
                                    >
                                        {this.state.types.map(type => {
                                            return (
                                                <MenuItem
                                                    value={type.name}
                                                    key={type.name}
                                                    selected={type.name === this.state.type}
                                                >
                                                    {type.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Type') }} />
                                </FormControl>
                            }
                            {this.state.types.length === 0 &&
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    id="type"
                                    label="Type"
                                    required
                                    value={this.state.type}
                                    onChange={(e) => this.setState({ type: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Type')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Type') }} />}
                                />
                            }
                            {this.state.languages.length > 0 &&
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Language')}>
                                    <InputLabel htmlFor="language" required>Language</InputLabel>
                                    <Select
                                        margin="dense"
                                        required
                                        value={this.state.language}
                                        onChange={(e) => this.setState({ language: e.target.value as string })}
                                        inputProps={{
                                            name: 'language',
                                            id: 'language',
                                        }}
                                    >
                                        {this.state.languages.map(language => {
                                            return (
                                                <MenuItem
                                                    value={language.name}
                                                    key={language.name}
                                                    selected={language.name === this.state.language}
                                                >
                                                    {language.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Language') }} />
                                </FormControl>
                            }
                            {this.state.languages.length === 0 &&
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    id="language"
                                    label="Language"
                                    required
                                    value={this.state.language}
                                    onChange={(e) => this.setState({ language: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Language')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Language') }} />}
                                />
                            }
                            {this.state.targetAudiences.length > 0 &&
                                <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'TargetAudience')}>
                                    <InputLabel htmlFor="targetAudience" required>Target Audience</InputLabel>
                                    <Select
                                        margin="dense"
                                        required
                                        value={this.state.targetAudience}
                                        onChange={(e) => this.setState({ targetAudience: e.target.value as string })}
                                        inputProps={{
                                            name: 'targetAudience',
                                            id: 'targetAudience',
                                        }}
                                    >
                                        {this.state.targetAudiences.map(targetAudience => {
                                            return (
                                                <MenuItem
                                                    value={targetAudience.name}
                                                    key={targetAudience.name}
                                                    selected={targetAudience.name === this.state.targetAudience}
                                                >
                                                    {targetAudience.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'TargetAudience') }} />
                                </FormControl>
                            }
                            {this.state.targetAudiences.length === 0 &&
                                <TextField
                                    margin="dense"
                                    fullWidth
                                    id="targetAudience"
                                    label="Target Audience"
                                    required
                                    value={this.state.targetAudience}
                                    onChange={(e) => this.setState({ targetAudience: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'TargetAudience')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'TargetAudience') }} />}
                                />
                            }
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'ScriptureReferences')}>
                                <InputLabel htmlFor="addScriptureReference" required>Scripture References <small>(At least 1 scripture reference must be added)</small></InputLabel>
                                <Input
                                    margin="dense"
                                    id="add-scripture-adornment"
                                    type='text'
                                    value={this.state.addScriptureReferenceText}
                                    onChange={e => this.setState({ addScriptureReferenceText: e.currentTarget.value })}
                                    onBlur={() => this.addScriptureReference()}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.addScriptureReference();
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Add"
                                                onClick={() => this.addScriptureReference()}
                                                disabled={this.state.addScriptureReferenceText.length === 0}
                                            >
                                                <AddCircleOutlineIcon color={this.state.addScriptureReferenceText.length === 0 ? "disabled" : "primary"} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        name: 'addScriptureReference',
                                        id: 'addScriptureReference',
                                    }}
                                />
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ScriptureReferences') }} />
                            </FormControl>
                            <div style={{ paddingBottom: 10 }}>
                                {this.state.scriptures.map(scriptureReference => {
                                    return (
                                        <Chip
                                            key={scriptureReference}
                                            label={scriptureReference}
                                            onDelete={e => this.removeScriptureReference(scriptureReference)}
                                            className={classes.chip}
                                        />
                                    );
                                })}
                            </div>
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Topics')}>
                                <InputLabel htmlFor="addTopic" required>Topics <small>(At least 2 topics must be added)</small></InputLabel>
                                <Input
                                    margin="dense"
                                    id="add-topic-adornment"
                                    type='text'
                                    value={this.state.addTopicText}
                                    onChange={e => this.setState({ addTopicText: e.currentTarget.value })}
                                    onBlur={() => this.addTopic()}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.addTopic();
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Add"
                                                onClick={() => this.addTopic()}
                                                disabled={this.state.addTopicText.length === 0}
                                            >
                                                <AddCircleOutlineIcon color={this.state.addTopicText.length === 0 ? "disabled" : "primary"} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        name: 'addTopic',
                                        id: 'addTopic',
                                    }}
                                />
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Topics') }} />
                            </FormControl>
                            <div style={{ paddingBottom: 10 }}>
                                {this.state.tags.map(topic => {
                                    return (
                                        <Chip
                                            key={topic}
                                            label={topic}
                                            onDelete={e => this.removeTopic(topic)}
                                            className={classes.chip}
                                        />
                                    );
                                })}
                            </div>
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="body"
                                label="Body"
                                multiline
                                inputProps={{
                                    style: { minHeight: "200px" }
                                }}
                                value={this.state.body}
                                onChange={(e) => this.setState({ body: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Body')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Body') }} />}
                            />
                            {this.state.rejectionNotes &&
                                <div className={classes.rejectionNotes}>
                                    <Typography variant="body2" color="secondary"><strong>Notes from your reviewer</strong></Typography>
                                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: this.state.rejectionNotes.replace(/\n/g, '<br>') }} />
                                </div>
                            }
                            {!this.state.id &&
                                <React.Fragment>
                                    <Typography variant="body2" component="h3">
                                        Your uploading of the above content to SermonCentral affirms it is your property, and you have appropriate permissions and rights to upload it. SermonCentral prohibits the posting of copyrighted material without proper and required permissions. If any portion of material is copyrighted please explicitly indicate it.
                                    </Typography>
                                    <br />
                                    <Typography variant="body2" component="h3">
                                        By clicking the submit button you are agreeing to the <a href="https://www.sermoncentral.com/content/termsandconditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>.
                                    </Typography>
                                </React.Fragment>
                            }
                            <Button disabled={!this.state.enableSubmit} type="submit" color="primary" variant="contained" className={classes.formButton}>
                                {this.state.id ? 'Save Changes' : 'Submit'}
                            </Button>
                        </form>
                    </Paper>
                }
            </div>
        );
    }

}

export default withRoot(withStyles(styles)(EditSermon));