import AdministratorNote from '../entities/AdministratorNote';
import ApplicationUser from '../entities/ApplicationUser';
import FreeProSubscriptionAccess from '../entities/FreeProSubscriptionAccess';
import SearchResult from '../entities/SearchResult';
import User from '../entities/User';
import { ServerResponse, WebClient } from '../services/ServiceHelper';

export class AdminService {

    public static makeAdmin(userId: number) {

        return WebClient.Post<ApplicationUser>("api/Admin/MakeAdmin", { userId: userId });
    }

    public static unmakeAdmin(userId: number) {

        return WebClient.Post<ApplicationUser>("api/Admin/UnmakeAdmin", { userId: userId });
    }

    public static impersonate(userId: number) {

        return WebClient.Post<ApplicationUser>("api/Admin/Impersonate", { userId: userId });
    }

    public static sendResetPasswordEmail(userId: number) {

        return WebClient.Post<null>("api/Admin/SendResetPasswordEmail", { userId: userId });
    }

    public static closeAccount(userId: number) {

        return WebClient.Post<null>("api/Admin/CloseAccount", { userId: userId });
    }

    public static async getAdministratorNotes() {

        var response = await WebClient.Get<AdministratorNote[]>("api/Admin/GetAdministratorNotes");
        if (ServerResponse.isServerResponse<AdministratorNote[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async addAdministratorNote(noteText: string) {

        return WebClient.Post<AdministratorNote>("api/Admin/AddAdministratorNote", { text: noteText });
    }

    public static async getFreeProSubscriptionAccess() {

        var response = await WebClient.Get<FreeProSubscriptionAccess[]>("api/Admin/GetFreeProSubscriptionAccess");
        if (ServerResponse.isServerResponse<FreeProSubscriptionAccess[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async addFreeProSubscriptionAccess(startDate: string, endDate: string) {

        return WebClient.Post<FreeProSubscriptionAccess[]>("api/Admin/AddFreeProSubscriptionAccess", { startDate, endDate });
    }

    public static async disableFreeProSubscriptionAccess(id: number) {

        return WebClient.Post<FreeProSubscriptionAccess[]>("api/Admin/DisableFreeProSubscriptionAccess", { id });
    }

    public static async stopImpersonation() {

        var response = await WebClient.Get<ApplicationUser>("api/Admin/StopImpersonation");
        if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async searchUsers(queryText: string, skip: number, take: number, orderBy: string, sortOrder: string) {

        var queryString = '?queryText=' + encodeURIComponent(queryText) + '&skip=' + skip + '&take=' + take + '&orderBy=' + orderBy + '&sortOrder=' + sortOrder;
        var response = await WebClient.Get<SearchResult<User>>("api/Admin/SearchUsers" + queryString);
        if (ServerResponse.isServerResponse<SearchResult<User>>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }
}
