import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import PaymentIcon from '@material-ui/icons/Payment';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ApplicationUser from '../../entities/ApplicationUser';
import BillingStatement from '../../entities/BillingStatement';
import { AccountService } from '../../services/AccountService';
import { FieldValidationError } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import { CurrencyHelper } from '../../utilities/CurrencyHelper';
import {ProSubscriptionSourceType} from "../../entities/ProSubscription";

const styles = (theme: Theme) =>
    createStyles({
        root: {

        },
        header: {

        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        pos: {
            marginBottom: 12,
        },
        sortButton: {
            paddingRight: "6px",
            fontSize: "13px",
            opacity: .86,
            whiteSpace: "nowrap"
        },
        menuItem: {
            minWidth: "200px",
        },
        sort: {
            display: "flex"
        },
        infiniteScroll: {
            width: 'auto',
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px"
        },
        cardImage: {
            height: 250,
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            cardContainer: {
                width: '100%'
            },
            toolbar: {
                display: 'block',
                padding: 0
            },
            title: {
                float: 'left'
            },
            sort: {
                display: 'block',
                float: 'right'
            },
            filterContainer: {
                float: 'right'
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            },
            infiniteScroll: {
                width: '100%',
            },
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

type State = {
    loading: boolean;
    billingStatements: BillingStatement[];
    visibleBillingStatementCount: number;
    sortOrder: string;
    orderBy: string;
    anchorSort: undefined;
    errors: FieldValidationError[];
};

class BillingStatements extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            billingStatements: [],
            visibleBillingStatementCount: 10,
            sortOrder: 'Descending',
            orderBy: 'InvoiceDate',
            anchorSort: undefined,
            errors: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Billing Statements";

        try {
            const billingStatements = await AccountService.getBillingStatements();

            if (billingStatements) {
                this.setState({ billingStatements, loading: false }, this.updateBillingStatements);
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    sortColumn(orderBy: string) {

        var sortOrder = this.state.sortOrder === 'Ascending' ? 'Descending' : 'Ascending';
        if (orderBy !== this.state.orderBy) {
            sortOrder = 'Descending';
        }

        this.setState({ anchorSort: undefined, orderBy, sortOrder }, this.updateBillingStatements);
    }

    updateBillingStatements() {

        let billingStatements = this.state.billingStatements;

        const sortGreaterThanValue = this.state.sortOrder === 'Ascending' ? 1 : -1;
        const sortLessThanValue = this.state.sortOrder === 'Ascending' ? -1 : 1;
        const fieldName = this.state.orderBy.toLowerCase() === 'invoicedate' ? 'invoiceDate' : this.state.orderBy.toLowerCase();

        billingStatements.sort((s1, s2) => {

            let s1FieldValue = s1[fieldName];
            if (typeof s1FieldValue === 'string') {
                s1FieldValue = s1FieldValue.toLowerCase();
            }

            let s2FieldValue = s2[fieldName];
            if (typeof s2FieldValue === 'string') {
                s2FieldValue = s2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof s1FieldValue === 'string' && typeof s2FieldValue === 'string') {
                const value = s1FieldValue.localeCompare(s2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (s1FieldValue > s2FieldValue) {
                return sortGreaterThanValue;
            }
            if (s1FieldValue < s2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        this.setState({ billingStatements });
    }

    render() {

        const classes = this.props.classes;
        const visibleBillingStatements = this.state.billingStatements.slice(0, this.state.visibleBillingStatementCount);
        var currency = this.props.user.currency

        return (
            <div className={classes.root}>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Billing Statements
                        </Typography>
                        {!this.state.loading && this.state.billingStatements.length > 0 &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.billingStatements.length} billing statement{this.state.billingStatements.length === 1 ? '' : 's'}
                            </Typography>
                        }
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={this.state.anchorSort ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorSort: e.currentTarget })}>
                            {this.state.orderBy === 'InvoiceDate' && 'Invoice Date'}
                            {this.state.orderBy === 'Balance' && 'Balance'}
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={this.state.anchorSort}
                            open={Boolean(this.state.anchorSort)}
                            onClose={(e: any) => this.setState({ anchorSort: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('InvoiceDate')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'InvoiceDate' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Invoice Date'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Balance')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Balance' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Balance'} />
                            </MenuItem>

                        </Menu>

                        <IconButton aria-label="Reverse Direction" onClick={e => this.sortColumn(this.state.orderBy)}>
                            {this.state.sortOrder === 'Descending' ? <ArrowDownwardIcon style={{ fontSize: "14px" }} /> : <ArrowUpwardIcon style={{ fontSize: "14px" }} />}
                        </IconButton>
                    </div>

                </Toolbar>

                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading && this.state.billingStatements.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <PaymentIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You have no billing statements.
                        </Typography>
                    }

                    { (this.props.user.isGlooLogin || this.props.user.proSubscription?.source == ProSubscriptionSourceType.Gloo) &&
                    <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                        <br/>
                        Visit Gloo for more billing statements.
                    </Typography>
                    }
                    
                    {!this.state.loading && visibleBillingStatements.length > 0 &&
                        <InfiniteScroll
                            className={classes.infiniteScroll}
                            pageStart={0}
                            threshold={0}
                            loadMore={() => this.setState({ visibleBillingStatementCount: this.state.visibleBillingStatementCount + 10 })}
                            hasMore={visibleBillingStatements.length < this.state.billingStatements.length}
                            useWindow={false}
                            getScrollParent={() => document.getElementById('main')}
                            loader={<div key="loader" style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading...
                                </Typography>
                            </div>}
                        >
                            {visibleBillingStatements.map(billingStatement =>
                                <Card className={classes.cardContainer} key={billingStatement.invoiceId}>
                                    <CardContent>
                                        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                            {new Date(billingStatement.invoiceDate).toLocaleDateString()}
                                        </Typography>
                                        <Typography variant="h5" component="h2">{billingStatement.invoiceNumber}</Typography>
                                        {billingStatement.invoiceAmount !== 0 &&
                                            <Typography variant="body2" component="p">Amount Invoiced: {CurrencyHelper.GetCurrencySymbol(currency)}{billingStatement.invoiceAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Typography>
                                        }
                                        {billingStatement.paymentAmount !== 0 &&
                                            <Typography variant="body2" component="p">Amount Paid: {CurrencyHelper.GetCurrencySymbol(currency)}{billingStatement.paymentAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Typography>
                                        }
                                        {billingStatement.refundAmount !== 0 &&
                                            <Typography variant="body2" component="p">Amount Refunded: {CurrencyHelper.GetCurrencySymbol(currency)}{billingStatement.refundAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Typography>
                                        }
                                        <Typography variant="body2" component="p">Balance: {CurrencyHelper.GetCurrencySymbol(currency)}{billingStatement.balance.toLocaleString(undefined, { minimumFractionDigits: 2 })}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <div style={{ marginLeft: "auto" }}>
                                            <Button color="primary" href={`/api/Account/DownloadBillingStatement/${billingStatement.invoiceId}`}>
                                                Download
                                            </Button>
                                        </div>
                                    </CardActions>
                                </Card>
                            )}
                        </InfiniteScroll>
                    }
                </Grid>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(BillingStatements));