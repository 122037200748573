import HistoryResource from '../entities/HistoryResource';
import { ServerResponse, WebClient } from '../services/ServiceHelper';

export class ResourceViewingHistoryService {

    public static async getResourceViewingHistory() {
        var response = await WebClient.Get<HistoryResource[]>("api/ResourceViewingHistory/GetResourceViewingHistory");
        if (ServerResponse.isServerResponse<HistoryResource[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static deleteResourceViewingHistory(id: number) {
        return WebClient.Delete<null>("api/ResourceViewingHistory/DeleteResourceViewingHistory", { id });
    }

    public static deleteResourceViewingHistories() {
        return WebClient.Delete<null>("api/ResourceViewingHistory/DeleteResourceViewingHistories", null);
    }

    public static enableResourceViewingHistory() {
        return WebClient.Post<null>("api/ResourceViewingHistory/EnableResourceViewingHistory", null);
    }

    public static disableResourceViewingHistory() {
        return WebClient.Post<null>("api/ResourceViewingHistory/DisableResourceViewingHistory", null);
    }
}