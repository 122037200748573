import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import green from "@material-ui/core/colors/green";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import * as React from "react";
import ApplicationUser from "../../entities/ApplicationUser";
import { AccountService } from "../../services/AccountService";
import {
    FieldValidationError,
    ServerModelValidationResponse,
    ServerResponse,
} from "../../services/ServiceHelper";
import withRoot from "../../withRoot";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      paddingTop: theme.spacing() * 2,
      paddingBottom: theme.spacing() * 2,
      marginBottom: theme.spacing() * 2,
      width: 450,
    },
    form: {
      paddingTop: theme.spacing() * 1,
      paddingBottom: theme.spacing() * 1,
      paddingRight: theme.spacing() * 3,
      paddingLeft: theme.spacing() * 3,
    },
    submitButton: {
      marginLeft: "auto",
      minWidth: "150px",
      display: "block",
    },
    errorSnackbarContent: {
      flexWrap: "nowrap",
      backgroundColor: theme.palette.error.dark,
    },
    errorSnackbarContentIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(),
    },
    errorSnackbarMessage: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    successSnackbarContent: {
      backgroundColor: green[600],
    },
    successSnackbarContentIcon: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(),
    },
    successSnackbarMessage: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      root: {
        alignItems: "normal",
      },
      paper: {
        width: "100%",
      },
    },
    snackBarText: {
      overflow: "hidden",
    },
  });

interface Props extends WithStyles<typeof styles> {
  user: ApplicationUser;
  onSignIn: (user: ApplicationUser) => void;
}

type State = {
  enableSubmit: boolean;
  currentPhone: string;
  newPhone: string;
  errors: FieldValidationError[];
  showSuccessMessage: boolean;
  successMessage: string;
};

class ChangePhone extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      enableSubmit: true,
      currentPhone: props.user.phoneNumber ?? "",
      newPhone: "",
      errors: [],
      showSuccessMessage: false,
      successMessage: "",
    };
  }

  componentDidMount() {
    document.title = "SermonCentral Account - Change Phone";
  }

  async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!this.state.enableSubmit) {
      return;
    }

    if (this.state.newPhone) {
      try {
        this.setState({ enableSubmit: false });
        const response = await AccountService.changePhone(this.state.newPhone);

        if (ServerResponse.isServerResponse<ApplicationUser>(response)) {
          this.props.onSignIn(response.data);
          this.setState({
            errors: [],
            newPhone: "",
            currentPhone: response.data.phoneNumber ?? "",
            enableSubmit: true,
            showSuccessMessage: true,
            successMessage: response.message,
          });
        }
        else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
            if (response.valid) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
            else {
                this.setState({ errors: response.errors, enableSubmit: true });
            }
        }
        else {
            const serverError: FieldValidationError = { field: "", errors: [response.message] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
      } catch (errorResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    }
  }

  render() {
    const classes = this.props.classes;

    return (
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={FieldValidationError.hasGenericError(this.state.errors)}
        >
          <SnackbarContent
            className={classes.errorSnackbarContent}
            aria-describedby="client-snackbar"
            classes={{
              message: classes.snackBarText,
            }}
            message={
              <span
                id="client-snackbar"
                className={classes.errorSnackbarMessage}
              >
                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                <span
                  dangerouslySetInnerHTML={{
                    __html: FieldValidationError.getGenericErrorSummary(
                      this.state.errors
                    ),
                  }}
                />
              </span>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={this.state.showSuccessMessage}
          autoHideDuration={6000}
          onClose={() => this.setState({ showSuccessMessage: false })}
        >
          <SnackbarContent
            className={classes.successSnackbarContent}
            aria-describedby="client-snackbar1"
            classes={{
              message: classes.snackBarText,
            }}
            message={
              <span
                id="client-snackbar1"
                className={classes.successSnackbarMessage}
              >
                <SuccessIcon className={classes.successSnackbarContentIcon} />
                <span>{this.state.successMessage}</span>
              </span>
            }
          />
        </Snackbar>
        <Grid item>
          <Paper className={classes.paper}>
            <Toolbar>
              <Typography variant="h5">Change Phone</Typography>
            </Toolbar>
            <form
              onSubmit={(e) => this.handleFormSubmit(e)}
              className={classes.form}
            >
              <Typography variant="body2">Current Phone</Typography>
              <Typography variant="body1">{this.state.currentPhone}</Typography>

              <TextField
                error={FieldValidationError.isFieldInError(
                  this.state.errors,
                  "NewPhone"
                )}
                required
                autoFocus
                fullWidth
                id="NewPhone"
                label="New Phone"
                type="phone"
                margin="normal"
                onChange={(e) => this.setState({ newPhone: e.target.value })}
                value={this.state.newPhone}
                helperText={
                  <span
                    dangerouslySetInnerHTML={{
                      __html: FieldValidationError.getFieldErrorSummary(
                        this.state.errors,
                        "NewPhone"
                      ),
                    }}
                  />
                }
              />
              <Button
                variant="contained"
                size="medium"
                color="primary"
                type="submit"
                disabled={!this.state.enableSubmit}
                className={classes.submitButton}
              >
                Change Phone
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withRoot(withStyles(styles)(ChangePhone));
