import * as React from 'react';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import withRoot from '../../withRoot';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Toolbar from '@material-ui/core/Toolbar';

import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import green from '@material-ui/core/colors/green';

import { FieldValidationError, ServerResponse, ServerModelValidationResponse } from '../../services/ServiceHelper';
import { AccountService } from '../../services/AccountService';

import ApplicationUser from '../../entities/ApplicationUser';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            width: 450
        },
        form: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        submitButton: {
            marginLeft: 'auto',
            minWidth: '150px',
            display: 'block'
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('md')]: {
            root: {
                alignItems: 'normal'
            },
            paper: {
                width: '100%'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

type State = {
    enableSubmit: boolean,
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
    errors: FieldValidationError[];
    showSuccessMessage: boolean;
    successMessage: string;
};

class ChangePassword extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            enableSubmit: true,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            errors: [],
            showSuccessMessage: false,
            successMessage: '',
        };
    }

    componentDidMount() {
        document.title = "SermonCentral Account - Change Password";
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if ((this.state.currentPassword || this.props.user.inAdminImpersonationMode) && this.state.newPassword && this.state.confirmNewPassword) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AccountService.changePassword(this.state.currentPassword, this.state.newPassword, this.state.confirmNewPassword);

                if (ServerResponse.isServerResponse<null>(response)) {

                    this.setState({ errors: [], currentPassword: '', newPassword: '', confirmNewPassword: '', enableSubmit: true, showSuccessMessage: true, successMessage: response.message });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {

        if (this.props.user.isGlooLogin){
            return (
                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                    Visit Gloo to change your password.
                </Typography>
            )
        }
        const classes = this.props.classes;

        return (
            <Grid className={classes.root} container direction="column" justify="center" alignItems="center">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.showSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.successMessage}</span>
                            </span>}
                    />
                </Snackbar>
                <Grid item>
                    <Paper className={classes.paper}>
                        <Toolbar>
                            <Typography variant="h5">
                                Change Password
                            </Typography>
                        </Toolbar>

                        <form className={classes.form} onSubmit={e => this.handleFormSubmit(e)}>

                            {!this.props.user.inAdminImpersonationMode &&
                                <TextField
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'CurrentPassword')}
                                    required
                                    autoFocus
                                    fullWidth
                                    id="currentPassword"
                                    label="Current Password"
                                    type="password"
                                    margin="normal"
                                    onChange={e => this.setState({ currentPassword: e.target.value })}
                                    value={this.state.currentPassword}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'CurrentPassword') }} />}
                                />
                            }
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'NewPassword')}
                                required
                                fullWidth
                                id="newPassword"
                                label="New Password"
                                type="password"
                                margin="normal"
                                onChange={e => this.setState({ newPassword: e.target.value })}
                                value={this.state.newPassword}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'NewPassword') }} />}
                            />
                            <TextField
                                error={FieldValidationError.isFieldInError(this.state.errors, 'ConfirmNewPassword')}
                                required
                                fullWidth
                                id="confirmNewPassword"
                                label="Confirm New Password"
                                type="password"
                                margin="normal"
                                onChange={e => this.setState({ confirmNewPassword: e.target.value })}
                                value={this.state.confirmNewPassword}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ConfirmNewPassword') }} />}
                            />
                            <Button variant="contained" size="medium" color="primary" type="submit" disabled={!this.state.enableSubmit} className={classes.submitButton}>Change Password</Button>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}

export default withRoot(withStyles(styles)(ChangePassword));