import {Button, Chip, CircularProgress , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, 
    FormHelperText, Hidden, IconButton, Input, InputAdornment, InputBase, InputLabel, List, ListItem, ListItemSecondaryAction, 
    ListItemText, Paper, Snackbar, SnackbarContent, TextField, Toolbar, Typography, fade, Theme, createStyles, withStyles, 
    WithStyles } from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import Sermon, { SermonStatus } from '../../entities/Sermon';
import SermonSeries from '../../entities/SermonSeries';
import { ContributionsService } from '../../services/ContributionsService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import { ArrayHelper } from '../../utilities/ArrayHelper';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2,
            minHeight: '72vh',
            maxWidth: '900px',
            marginLeft: 'auto',
            marginRight: 'auto',
            overflowY: 'auto'
        },
        viewButton: {
            marginLeft: 'auto'
        },
        form: {
            paddingTop: theme.spacing() * 1,
            paddingBottom: theme.spacing() * 1,
            paddingRight: theme.spacing() * 3,
            paddingLeft: theme.spacing() * 3,
        },
        formButton: {
            marginTop: '15px',
            marginLeft: 'auto',
            display: 'flex'
        },
        formControl: {
            width: '100%'
        },
        chip: {
            margin: theme.spacing() / 2,
            color: "#FFF",
            backgroundColor: theme.palette.primary.light,
            "& svg": {
                color: "rgb(255,255,255,.7)",
                "&:hover": {
                    color: theme.palette.primary.main
                }
            }
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        link: {
            textDecoration: 'none',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
        },
        sermonActions: {
            [theme.breakpoints.down('sm')]: {
                top: '18px',
                right: '0',
                position: 'relative'
            }
        },
        addExistingDialogContent: {
            width: '500px',
            [theme.breakpoints.down('xs')]: {
                width: 'auto'
            },
        },
        addExistingDialogList: {
            height: '400px',
            overflow: 'auto'
        },
        mainToolbar: {
            flexWrap: 'wrap'
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends RouteComponentProps<{ id: string }>, WithStyles<typeof styles> {

}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    id?: number;
    title: string;
    intro: string;
    scriptures: string[];
    addScriptureReferenceText: string;
    tags: string[];
    addTopicText: string;
    date: Date;
    status: number;
    url: string;
    updateSuccessMessage: string;
    showUpdateSuccessMessage: boolean;
    errors: FieldValidationError[];
    sermonsInSeries?: Sermon[];
    showDeleteDialog: boolean;
    selectedSermonIdToDelete?: number;
    showAddExistingDialog: boolean;
    sermons: Sermon[];
    unassignedSermons: Sermon[];
    queryText: string;
    loadingSermons: boolean;
}

class EditSeriesSermon extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            id: props.match.params.id ? parseInt(props.match.params.id, 10) : undefined,
            title: '',
            intro: '',
            scriptures: [],
            addScriptureReferenceText: '',
            tags: [],
            addTopicText: '',
            date: new Date(),
            status: SermonStatus.Pending,
            url: '',
            updateSuccessMessage: '',
            showUpdateSuccessMessage: false,
            errors: [],
            sermonsInSeries: undefined,
            showDeleteDialog: false,
            selectedSermonIdToDelete: undefined,
            showAddExistingDialog: false,
            sermons: [],
            unassignedSermons: [],
            queryText: '',
            loadingSermons: true
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Add Sermon Series";

        if (this.state.id) {
            document.title = "SermonCentral Account - Edit Sermon Series";

            try {
                const sermonSeries = await ContributionsService.getSermonSeries(parseInt(this.props.match.params.id, 10));
                if (sermonSeries) {

                    this.setState({
                        loading: false,
                        enableSubmit: true,
                        id: sermonSeries.id,
                        title: sermonSeries.title,
                        intro: sermonSeries.intro,
                        scriptures: sermonSeries.scriptures,
                        tags: sermonSeries.tags,
                        url: sermonSeries.url,
                    });

                    const sermonsInSeries = await ContributionsService.getSermonSeriesSermons(sermonSeries.id);
                    if (sermonsInSeries) {
                        this.setState({ sermonsInSeries });
                    }

                    const sermons = await ContributionsService.getSermons();
                    if (sermons) {
                        this.setState({ sermons, unassignedSermons: sermons.filter(s => !s.sermonSeriesId), loadingSermons: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError] });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        else {
            this.setState({ loading: false, enableSubmit: true });
        }
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.title && this.state.intro) {
            try {
                this.setState({ enableSubmit: false });
                const response = await ContributionsService.saveSermonSeries(this.state.id, this.state.title, this.state.intro, this.state.tags, this.state.scriptures);

                if (ServerResponse.isServerResponse<SermonSeries>(response)) {

                    const sermonSeries = response.data;

                    document.title = "SermonCentral Account - Edit Sermon Series";
                    this.props.history.replace(`/contributions/sermon-series/edit/${response.data.id}`);

                    this.setState({
                        loading: false,
                        enableSubmit: true,
                        id: sermonSeries.id,
                        title: sermonSeries.title,
                        intro: sermonSeries.intro,
                        scriptures: sermonSeries.scriptures,
                        tags: sermonSeries.tags,
                        url: sermonSeries.url,
                        updateSuccessMessage: response.message,
                        showUpdateSuccessMessage: true,
                        errors: []
                    });

                    const sermonsInSeries = await ContributionsService.getSermonSeriesSermons(sermonSeries.id);
                    if (sermonsInSeries) {
                        this.setState({ sermonsInSeries });
                    }
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    addScriptureReference() {

        if (this.state.addScriptureReferenceText.length > 0) {
            const updatedScriptures = ArrayHelper.addString(this.state.addScriptureReferenceText, this.state.scriptures);
            this.setState({
                scriptures: updatedScriptures,
                addScriptureReferenceText: ''
            });
        }
        else {
            this.setState({
                addScriptureReferenceText: ''
            });
        }
    }

    removeScriptureReference(scriptureReference: string) {

        if (scriptureReference.length > 0) {

            let scriptures = ArrayHelper.removeString(scriptureReference, this.state.scriptures);
            this.setState({ scriptures });
        }
    }

    addTopic() {

        if (this.state.addTopicText.length > 0) {
            const updatedTags = ArrayHelper.addString(this.state.addTopicText, this.state.tags);
            this.setState({
                tags: updatedTags,
                addTopicText: ''
            });
        }
        else {
            this.setState({
                addTopicText: ''
            });
        }
    }

    removeTopic(topic: string) {

        if (topic.length > 0) {
            let tags = ArrayHelper.removeString(topic, this.state.tags);
            this.setState({ tags });
        }
    }

    async deleteSermon() {
        if (this.state.selectedSermonIdToDelete) {
            try {
                const response = await ContributionsService.removeSermonSeriesSermon(this.state.selectedSermonIdToDelete, this.state.id!);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let sermonsInSeries = this.state.sermonsInSeries;
                    sermonsInSeries = sermonsInSeries!.filter(l => l.id !== this.state.selectedSermonIdToDelete);

                    let sermons = this.state.sermons;
                    let sermon = sermons.find(s => s.id === this.state.selectedSermonIdToDelete!)!;
                    sermon.sermonSeriesId = undefined;

                    this.setState({
                        unassignedSermons: sermons.filter(s => !s.sermonSeriesId),
                        sermonsInSeries,
                        selectedSermonIdToDelete: undefined,
                        enableSubmit: true,
                        showDeleteDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'The sermon was removed from the series',
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showDeleteDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
    }

    async moveSermon(sermonId: number, index: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {

            this.setState({ enableSubmit: false });
            let sermonsInSeries = this.state.sermonsInSeries;
            const currentIndex = sermonsInSeries!.findIndex(f => f.id === sermonId);
            let sermon = sermonsInSeries![currentIndex];

            const response = await ContributionsService.updateSermonSeriesSermon(sermonId, this.state.id!, index);

            if (ServerResponse.isServerResponse<Sermon>(response)) {

                sermon = response.data;

                sermonsInSeries!.splice(currentIndex, 1);
                sermonsInSeries!.splice(index, 0, sermon);

                this.setState({
                    sermonsInSeries,
                    errors: [],
                    enableSubmit: true,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'Sermon moved.',
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
            }

        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
        }
    }

    async addSermonToSeries(id: number) {
        if (!this.state.enableSubmit) {
            return;
        }

        try {

            this.setState({ enableSubmit: false });

            const response = await ContributionsService.addSermonSeriesSermon(id, this.state.id!);

            if (ServerResponse.isServerResponse<Sermon>(response)) {

                let sermonsInSeries = this.state.sermonsInSeries!;
                sermonsInSeries.push(response.data);

                let sermons = this.state.sermons;
                let sermon = sermons.find(s => s.id === id)!;
                sermon.sermonSeriesId = this.state.id;

                this.setState({
                    unassignedSermons: sermons.filter(s => !s.sermonSeriesId),
                    showAddExistingDialog: false,
                    sermonsInSeries,
                    errors: [],
                    enableSubmit: true,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'Sermon added to Series.',
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showAddExistingDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showAddExistingDialog: false });
            }

        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showAddExistingDialog: false });
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                {this.state.loading &&
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress style={{ marginTop: "50px" }} />
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading...
                    </Typography>
                    </div>
                }
                {!this.state.loading &&
                    <Paper elevation={1} className={classes.paper}>
                        <Toolbar className={classes.mainToolbar}>
                            <IconButton onClick={() => this.props.history.goBack()}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Typography variant="h5">
                                {this.state.id ? 'Edit' : 'Share a new'} Sermon Series
                            </Typography>
                            {this.state.url &&
                                <Button href={this.state.url} target="blank" className={classes.viewButton}>(View on SermonCentral)</Button>
                            }
                        </Toolbar>
                        <form className={classes.form} onSubmit={(e) => this.handleFormSubmit(e)}>
                            <TextField
                                margin="dense"
                                fullWidth
                                id="title"
                                label="Title"
                                required
                                value={this.state.title}
                                onChange={(e) => this.setState({ title: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Title')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Title') }} />}
                            />
                            <TextField
                                margin="dense"
                                required
                                fullWidth
                                id="Description"
                                label="Description"
                                multiline
                                rows={3}
                                value={this.state.intro}
                                onChange={(e) => this.setState({ intro: e.target.value })}
                                error={FieldValidationError.isFieldInError(this.state.errors, 'Summary')}
                                helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Summary') }} />}
                            />
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'ScriptureReferences')}>
                                <InputLabel htmlFor="addScriptureReference" required>Scripture References <small>(At least 1 scripture reference must be added)</small></InputLabel>
                                <Input
                                    margin="dense"
                                    id="add-scripture-adornment"
                                    type='text'
                                    value={this.state.addScriptureReferenceText}
                                    onChange={e => this.setState({ addScriptureReferenceText: e.currentTarget.value })}
                                    onBlur={() => this.addScriptureReference()}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.addScriptureReference();
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Add"
                                                onClick={() => this.addScriptureReference()}
                                                disabled={this.state.addScriptureReferenceText.length === 0}
                                            >
                                                <AddCircleOutlineIcon color={this.state.addScriptureReferenceText.length === 0 ? "disabled" : "primary"} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        name: 'addScriptureReference',
                                        id: 'addScriptureReference',
                                    }}
                                />
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'ScriptureReferences') }} />
                            </FormControl>
                            <div style={{ paddingBottom: 10 }}>
                                {this.state.scriptures.map(scriptureReference => {
                                    return (
                                        <Chip
                                            key={scriptureReference}
                                            label={scriptureReference}
                                            onDelete={e => this.removeScriptureReference(scriptureReference)}
                                            className={classes.chip}
                                        />
                                    );
                                })}
                            </div>
                            <FormControl className={classes.formControl} error={FieldValidationError.isFieldInError(this.state.errors, 'Topics')}>
                                <InputLabel htmlFor="addTopic" required>Topics <small>(At least 2 topics must be added)</small></InputLabel>
                                <Input
                                    margin="dense"
                                    id="add-topic-adornment"
                                    type='text'
                                    value={this.state.addTopicText}
                                    onChange={e => this.setState({ addTopicText: e.currentTarget.value })}
                                    onBlur={() => this.addTopic()}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.addTopic();
                                        }
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Add"
                                                onClick={() => this.addTopic()}
                                                disabled={this.state.addTopicText.length === 0}
                                            >
                                                <AddCircleOutlineIcon color={this.state.addTopicText.length === 0 ? "disabled" : "primary"} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    inputProps={{
                                        name: 'addTopic',
                                        id: 'addTopic',
                                    }}
                                />
                                <FormHelperText dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Topics') }} />
                            </FormControl>
                            <div style={{ paddingBottom: 10 }}>
                                {this.state.tags.map(topic => {
                                    return (
                                        <Chip
                                            key={topic}
                                            label={topic}
                                            onDelete={e => this.removeTopic(topic)}
                                            className={classes.chip}
                                        />
                                    );
                                })}
                            </div>
                            <Button disabled={!this.state.enableSubmit} type="submit" color="primary" variant="contained" className={classes.formButton}>
                                {this.state.id ? 'Save Changes' : 'Submit'}
                            </Button>
                        </form>
                        {!this.state.sermonsInSeries && this.state.id &&
                            <div style={{ textAlign: 'center', padding: '75px' }}>
                                <CircularProgress />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading Sermons...
                                </Typography>
                            </div>
                        }
                        {this.state.sermonsInSeries && this.state.id &&
                            <div className={classes.form}>
                                <Typography variant="h6">
                                    Sermons in Series ({this.state.sermonsInSeries.length.toLocaleString()})
                                </Typography>
                                <Divider />
                                <List>
                                    {this.state.sermonsInSeries.map((sermon, index) =>
                                        <>
                                            <ListItem key={sermon.id}>
                                                <ListItemText primary={sermon.title} secondary={SermonStatus[sermon.status] + ' Sermon'} />
                                                <ListItemSecondaryAction className={classes.sermonActions}>
                                                    {index > 0 &&
                                                        <IconButton disabled={!this.state.enableSubmit} aria-label="Move Up" onClick={() => this.moveSermon(sermon.id, index - 1)}>
                                                            <ArrowUpwardIcon />
                                                        </IconButton>
                                                    }
                                                    {index < this.state.sermonsInSeries!.length - 1 &&
                                                        <IconButton aria-label="Move Down" disabled={!this.state.enableSubmit} onClick={() => this.moveSermon(sermon.id, index + 1)}>
                                                            <ArrowDownwardIcon />
                                                        </IconButton>
                                                    }
                                                    <Link to={`/contributions/sermons/edit/${sermon.id}`}>
                                                        <IconButton aria-label="Edit">
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                    </Link>
                                                    <IconButton aria-label="Delete" onClick={() => this.setState({ selectedSermonIdToDelete: sermon.id, showDeleteDialog: true })}>
                                                        <DeleteIcon color="error" />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <Hidden mdUp><Divider /></Hidden>
                                        </>
                                    )}
                                </List>
                                <div style={{ textAlign: 'right' }}>
                                    <Link className={classes.link} to={`/contributions/sermons/add?sermonSeriesId=${this.state.id}`}><Button >Add New Sermon</Button></Link>
                                    <Button onClick={() => this.setState({ showAddExistingDialog: true })}>Add Existing Sermon</Button>
                                </div>
                            </div>
                        }
                    </Paper>
                }
                <Dialog
                    open={this.state.showDeleteDialog}
                    onClose={e => this.setState({ showDeleteDialog: false, selectedSermonIdToDelete: undefined })}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">Remove Sermon from Series</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle2' id="delete-dialog-description">
                            Are you sure you wish to remove this sermon from this series? This will not delete the sermon.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.setState({ showDeleteDialog: false, selectedSermonIdToDelete: undefined })} color="default">
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteSermon()} color="secondary" autoFocus>
                            Remove
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showAddExistingDialog}
                    onClose={() => this.setState({ showAddExistingDialog: false })}
                    aria-labelledby="add-sermons-dialog-title"
                    aria-describedby="add-sermons-dialog-description"
                >
                    <DialogTitle id="add-sermons-dialog-title">Select a sermon to add</DialogTitle>
                    <DialogContent className={classes.addExistingDialogContent}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search Sermons"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={this.state.queryText}
                                onChange={(e) => this.setState({ queryText: e.target.value })}
                            />
                        </div>
                        {this.state.loadingSermons &&
                            <div style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading Sermons...
                                </Typography>
                            </div>
                        }
                        {!this.state.loadingSermons && this.state.unassignedSermons.length === 0 &&
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                <InsertCommentIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                                <br />
                                You don't have any sermons that aren't already part of sermon series.
                            </Typography>
                        }
                        {this.state.unassignedSermons.length > 0 && this.state.unassignedSermons.filter(s => s.title.toLowerCase().indexOf(this.state.queryText) >= 0).length === 0 &&
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                <InsertCommentIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                                <br />
                                There are no sermons that match your search.
                            </Typography>
                        }
                        {this.state.unassignedSermons.length > 0 && this.state.unassignedSermons.filter(s => s.title.toLowerCase().indexOf(this.state.queryText) >= 0).length > 0 &&
                            <List className={classes.addExistingDialogList}>
                                {this.state.unassignedSermons.filter(s => s.title.toLowerCase().indexOf(this.state.queryText) >= 0).slice(0, 20).map(sermon =>
                                    <ListItem key={sermon.id}>
                                        <ListItemText primary={sermon.title} secondary={SermonStatus[sermon.status] + ' Sermon'} />
                                        <ListItemSecondaryAction>
                                            <IconButton aria-label="Add" color="primary" disabled={!this.state.enableSubmit} onClick={() => this.addSermonToSeries(sermon.id)}>
                                                <AddIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showAddExistingDialog: false })} color="default">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}

export default withRoot(withStyles(styles)(EditSeriesSermon));