export const ArrayHelper = {
   addString: (stringToAdd: string, stringArray: string[]): string[] => {
        let separatedStrings = stringToAdd.replace(/\s\s+/g, ' ').split(',').map(s => s.trim());

        let strings = [ ...stringArray];
        for (var i = 0; i < separatedStrings.length; i++) {

            const stringPart = separatedStrings[i];
            if (stringPart !== '' && !strings.some(s => s.toLowerCase() === stringPart.toLowerCase())) {
                strings.push(stringPart);
            }
        }
        return strings;
    },
   
    removeString: (stringToRemove: string, stringArray: string[]): string[] => {
        return stringArray.filter(s => s !== stringToRemove);
    }
}