export enum SparkSubscriptionStatus {
    Unknown = 0,
    Incomplete = 1,
    IncompleteExpired = 2,
    Trialing = 3,
    Active = 4,
    PastDue = 5,
    Cancelled = 6,
    Unpaid = 7,
}

export enum SparkSubscriptionTerm {
    Unknown = 0,
    Monthly = 1,
    Yearly = 2,
}

export default interface SparkSubscription {
    id: number;
    userId: number;
    stripeSubscriptionId: string;
    stripeCustomerId: string;
    status: SparkSubscriptionStatus;
    startDate: Date;
    endDate?: Date;
    cancelOn?: Date;
    isCancelled: boolean;
    term: SparkSubscriptionTerm;
    price: number;
    expirationDate?: Date;
    renewalDate?: Date;
}