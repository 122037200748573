import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import NoteIcon from '@material-ui/icons/Note';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import AdministratorNote from '../../entities/AdministratorNote';
import ApplicationUser from '../../entities/ApplicationUser';
import { AdminService } from '../../services/AdminService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 2,
            right: theme.spacing() * 2,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
        },
        addNoteDialog: {
            minWidth: '400px'
        },
        card: {
            width: '500px',
            marginBottom: "16px"
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        [theme.breakpoints.down('sm')]: {
            card: {
                width: '100%'
            },
            toolbar: {
                display: 'block',
                padding: 0
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

type State = {
    loading: boolean;
    enableSubmit: boolean;
    queryText: string;
    addNoteText: string;
    showAddNoteDialog: boolean;
    errors: FieldValidationError[];
    notes: AdministratorNote[];
    visibleNotes: AdministratorNote[];
};

class Notes extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: true,
            enableSubmit: true,
            queryText: '',
            addNoteText: '',
            showAddNoteDialog: false,
            errors: [],
            notes: [],
            visibleNotes: []
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Admin Notes";

        try {
            const notes = await AdminService.getAdministratorNotes();

            if (notes) {
                this.setState({ errors: [], loading: false, notes: notes, visibleNotes: notes });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true });
        }
    }

    search() {

        let notes = this.state.notes.filter(n => n.text.search(new RegExp(this.state.queryText, "i")) >= 0);

        this.setState({ visibleNotes: notes });
    }

    highlightText(text: string, match: string) {

        if (!text) {
            return '';
        }

        if (!match) {
            return text;
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    handleAddNoteFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.addNoteText) {
            try {
                this.setState({ enableSubmit: false });
                const response = await AdminService.addAdministratorNote(this.state.addNoteText);

                if (ServerResponse.isServerResponse<AdministratorNote>(response)) {

                    let notes = this.state.notes;
                    notes.unshift(response.data);
                    this.setState({ errors: [], notes: notes, visibleNotes: notes, queryText: '', addNoteText: '', enableSubmit: true, showAddNoteDialog: false });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showAddNoteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showAddNoteDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showAddNoteDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true, showAddNoteDialog: false });
            }
        }
    }

    render() {
        var classes = this.props.classes;

        if (!this.props.user.isAdmin || !this.props.user.inAdminImpersonationMode) {
            return (
                <Redirect to="/" />
            );
        }

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Admin Notes
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={this.state.queryText}
                            onChange={(e) => this.setState({ queryText: e.target.value }, this.search)}
                        />
                    </div>
                </Toolbar>
                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading && this.state.notes.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <NoteIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            This user has no notes. Add one below.
                        </Typography>
                    }
                    {!this.state.loading && this.state.notes.length > 0 && this.state.queryText && this.state.visibleNotes.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <SearchIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            No results found. Try another query.
                        </Typography>
                    }
                    {!this.state.loading && this.state.visibleNotes.length > 0 && this.state.visibleNotes.map(n =>
                        <Card key={n.id} className={classes.card}>
                            <CardContent>
                                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                    Added on {new Date(n.date).toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" dangerouslySetInnerHTML={{ __html: this.highlightText(n.text.replace(/(?:\r\n|\r|\n)/g, '<br>'), this.state.queryText) }} />
                            </CardContent>
                        </Card>
                    )}
                </Grid>
                <Fab className={classes.fab} color="primary" onClick={() => this.setState({ showAddNoteDialog: !this.state.showAddNoteDialog })}>
                    <AddIcon />
                </Fab>

                <Dialog
                    open={this.state.showAddNoteDialog}
                    onClose={() => this.setState({ showAddNoteDialog: !this.state.showAddNoteDialog })}
                    aria-labelledby="form-dialog-title"
                >
                    <form onSubmit={(e) => this.handleAddNoteFormSubmit(e)}>
                        <DialogTitle id="form-dialog-title">Add a new User Note</DialogTitle>
                        <DialogContent>
                            <TextField
                                id="note"
                                label="Note"
                                multiline
                                rows="4"
                                className={classes.addNoteDialog}
                                required
                                value={this.state.addNoteText}
                                onChange={(e) => this.setState({ addNoteText: e.target.value })}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ showAddNoteDialog: !this.state.showAddNoteDialog })} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Add
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </div >
        );
    }
}

export default withRoot(withStyles(styles)(Notes));