import * as React from 'react';
import withRoot from '../withRoot';

interface Props {
    value: number;
    minDecimalPlaces: number;
    maxDecimalPlaces: number;
}

type State = {
    currentValue: number;
    value: number;
};

class AnimatedNumberLabel extends React.Component<Props, State> {

    intervalId: number = 0;
    incrementCount: number = 0;

    constructor(props: Props) {
        super(props);
        this.state = {
            currentValue: props.value,
            value: props.value
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

        if (this.props.value !== prevProps.value) {

            this.incrementCount = 0;
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = 0;
            }

            this.setState({ value: this.props.value, currentValue: prevProps.value }, () => {

                const incrementBy = (this.state.value - this.state.currentValue) / 10;
                this.intervalId = setInterval(() => {
                    if (this.incrementCount < 9) {
                        this.setState({ currentValue: this.state.currentValue + incrementBy });
                        this.incrementCount++;
                    }
                    else {
                        this.setState({ currentValue: this.state.value });
                        clearInterval(this.intervalId);
                    }
                }, 50) as unknown as number;
            });
        }
    }

    render() {

        return (
            <>
                {this.state.currentValue.toLocaleString(undefined, { minimumFractionDigits: this.props.minDecimalPlaces, maximumFractionDigits: this.props.maxDecimalPlaces })}
            </>
        );
    }
}

export default withRoot(AnimatedNumberLabel);