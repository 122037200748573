import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import green from '@material-ui/core/colors/green';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import * as d3 from 'd3';
import * as React from 'react';
import * as topojson from "topojson-client";
import AppConfig from '../../entities/AppConfig';
import ApplicationUser from '../../entities/ApplicationUser';
import { FieldValidationError, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import USState from '../../entities/USState';
import { ContributionsService } from '../../services/ContributionsService';
import { MediaProducerService } from '../../services/MediaProducerService';
import DownloadAndViewCountByRegion from '../../entities/DownloadAndViewCountByRegion';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import AnimatedNumberLabel from '../../components/AnimatedNumberLabel';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer, Label, Line, ReferenceLine, ComposedChart, Brush, PieChart, Pie, Cell, RechartsFunction } from 'recharts';
import { MiscService } from '../../services/MiscService';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        snackbarContent: {
            backgroundColor: theme.palette.error.dark,
        },
        snackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        snackbarMessage: {
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        avatar: {
            margin: '0 auto',
            backgroundColor: theme.palette.primary.light,
            width: 100,
            height: 100,
            fontSize: '40px',
            marginBottom: theme.spacing(),
        },
        welcome: {
            textAlign: 'center',
            marginBottom: theme.spacing(),
        },
        title: {
            textAlign: 'center',
            marginBottom: theme.spacing() * 2,
        },
        card: {
            padding: theme.spacing(),
            width: 450,
            height: 550
        },
        dangerButton: {
            color: theme.palette.error.dark
        },
        secondaryAction: {

        },
        link: {
            textDecoration: 'none',
        },
        [theme.breakpoints.down('md')]: {
            card: {
                minWidth: '100%',
                width: 'auto',
                height: 'auto',
            }
        },
        [theme.breakpoints.down('sm')]: {
            secondaryAction: {
                position: 'static',
                transform: 'none',
                right: 'inherit',
                top: 'inherit',
                paddingLeft: theme.spacing() * 2
            }
        },
        [theme.breakpoints.down('xs')]: {
            secondaryAction: {
                paddingLeft: theme.spacing()
            }
        },
        snackBarText: {
            overflow: 'hidden'
        },
        mapCard: {
            maxHeight: '75vh',
            overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
                width: '100%',
                maxHeight: '70vh',
            }
        },
        mapContainer: {
            display: 'inline-block',
            position: 'relative',
            paddingBottom: '100%',
            verticalAlign: 'top',
            overflow: 'hidden',
            height: "100%",
            width: '100%',
            marginTop: 0
        },
        stateMap: {
            display: 'inline-block',
            position: 'absolute',
            top: 0,
            left: 0,
            "& path": {
                strokeLinejoin: "round",
                strokeLinecap: "round",
                fill: "rgb(163, 219, 241)",
                stroke: "black",
                strokeWidth: ".5px",
                transition: 'all 0.2s ease',
                "&:hover": {
                    fillOpacity: .7,
                    transition: 'all 0.2s ease',
                }
            },
            "& .names": {
                fill: "none",
                stroke: "#fff",
                strokeLinejoin: "round"
            },
            "& title": {
                fontSize: 15,
                borderRadius: 5
            }
        },
        filterButtons: {
            padding: 10,
            justifyContent: 'space-between',
            display: 'flex',
            flexWrap: 'wrap'
        },
        filterButton: {
            marginLeft: 10
        },
        totalBox: {
            minHeight: 72
        },
        barChart: {
            marginTop: 30
        },
        producerPayoutCard: {
            marginBottom: 30
        },
        pieLabels: {
            fontSize: 20,
        },
        noData: {
            paddingTop: '15%'
        }
    });

enum Months {
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12
}

enum MapFilters {
    All = "All",
    Views = "Views",
    Downloads = "Downloads"
}

export enum ChartYears {
    Y2019 = 2019,
    Y2020 = 2020,
    Y2021 = 2021
}

interface Props extends WithStyles<typeof styles> {
    onSignIn: (user: ApplicationUser) => void;
    config: AppConfig;
    user: ApplicationUser;
}

type State = {
    loadingMap: boolean;
    errors: FieldValidationError[];
    usMapData: any;
    usStates: USState[] | undefined;
    mapDownloadAndViewData: DownloadAndViewCountByRegion[] | null;
    anchorYearMenu?: HTMLElement;
    anchorMonthMenu?: HTMLElement;
    anchorByData?: HTMLElement;
    filterByYear: number;
    filterByMonth: Months;
    filterByData: MapFilters;
    totalViews: number;
    totalDownloads: number;
    producerPayoutData: ProducerPayoutData[];
    chartViewing: ChartViewingTypes;
    anchorChartMenu?: HTMLElement;
    anchorChartYearMenu?: HTMLElement;
    anchorChartMonthMenu?: HTMLElement;
    filteredChartData: ProducerPayoutData[];
    filterChartYear: ChartYears,
    filterChartMonth: Months,
    monthData: MonthData[];
    totalChart: number;
};

export interface ProducerPayoutData {
    revenueShare: number | null;
    salesFromMedia: number | null;
    salesFromSSK: number | null;
    total: number | null;
    month: string;
    year: ChartYears;
}

interface MonthData {
    name: string;
    total: number | null;
}

enum ChartViewingTypes {
    Year = "Year",
    Month = "Month"
}

const PieColors = ['#2196f3', '#61b9ff', '#7bc5ff'];

const today = new Date();

const years = [today.getFullYear(), today.getFullYear() - 1, today.getFullYear() - 2]

class MediaDashboard extends React.Component<Props, State> {

    private mapRef: SVGGElement | null = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            loadingMap: false,
            errors: [],
            usMapData: undefined,
            usStates: undefined,
            mapDownloadAndViewData: null,
            filterByYear: years[0],
            filterByMonth: Months[Months[(new Date().getMonth() + 1)]],
            filterByData: MapFilters.All,
            totalDownloads: 0,
            totalViews: 0,
            chartViewing: ChartViewingTypes.Year,
            filterChartYear: years[0],
            filterChartMonth: Months[Months[(new Date().getMonth() + 1)].toString()],
            producerPayoutData: [],
            filteredChartData: [],
            monthData: [],
            totalChart: 0
        };
    }

    async componentDidMount() {
        document.title = "SermonCentral Account - Media Dashboard";

        this.setState({ loadingMap: true });
        ContributionsService.getUSStates()
            .then((usStates) => {
                this.setState({ usStates: usStates || [] });
            });

        axios.get('/data/usStates-data.json', { headers: { Pragma: 'no-cache' } })
            .then(response => {
                this.setState({ usMapData: response.data }, async () => await this.getMapData());
            });

            // Matt isn't ready to give this to producers, so hide for now 3/27
        // var response = await MiscService.getProducerPayoutByYear(+this.state.filterChartYear);

        // if (response != null) {
        //     this.massageProducerPayoutDate(response);
        // }


        this.updateChartData();
    }

    massageProducerPayoutDate(data: ProducerPayoutData[]) {
        let allData: ProducerPayoutData[] = [];

        for (let m = 1; m < 13; m++) {
            let monthData = data.find(a => a.month === Months[Months[m]]);

            if (monthData) {
                allData.push({
                    ...monthData,
                    month: Months[monthData.month],
                })
            } else {
                allData.push({
                    month: Months[m],
                    year: this.state.filterChartYear,
                    revenueShare: null,
                    salesFromMedia: null,
                    salesFromSSK: null,
                    total: null,
                });

            }
        }
        this.setState({ producerPayoutData: allData })
    }

    getMapData = async () => {
        const { filterByYear, filterByMonth } = this.state;
        const mapDownloadAndViewData = await MediaProducerService.GetMediaProducersDownloadsAndViewsCount(filterByYear, filterByMonth);
        this.setState({ mapDownloadAndViewData }, () => this.updateUSMap());
    }

    updateUSMap() {

        const classes = this.props.classes;

        if (!this.state.usMapData || !this.state.mapDownloadAndViewData) {
            return;
        }

        this.setState({ loadingMap: true });


        let totalViews = 0;
        let totalDownloads = 0;


        this.state.usMapData.objects.states.geometries.forEach((geometry: any) => {

            let views: number = 0;
            let downloads: number = 0;
            const usState = this.state.usStates!.filter(c => c.postalAbbreviation === geometry.code)[0];
            if (!usState) {
                geometry.views = views;
                geometry.downloads = downloads;
                return;
            }

            const dataByRegion = this.state.mapDownloadAndViewData!.find(s => s.region === usState.postalAbbreviation);

            if (dataByRegion) {
                views = dataByRegion.viewCount;
                downloads = dataByRegion.downloadCount;
            }

            geometry.views = views;
            geometry.downloads = downloads;
            totalViews += views;
            totalDownloads += downloads;
        });

        this.setState({ totalViews, totalDownloads });

        const rangeArray: Array<any> = [];

        rangeArray.push('#ffffff');
        rangeArray.push('#cde8f3');
        rangeArray.push('#b4dcee');
        rangeArray.push('#9bd1e8');
        rangeArray.push('#82c6e3');
        rangeArray.push('#69badd');
        rangeArray.push('#50afd7');
        rangeArray.push('#36a3d2');
        rangeArray.push('#1d98cc');
        rangeArray.push('#058dc7');
        rangeArray.push('#0376ae');
        rangeArray.push('#026096');
        rangeArray.push('#01497e');
        rangeArray.push('#003366');

        const color = d3.scaleQuantile()
            .domain([0, 1, 5, 10, 20, 50, 100, 250, 400, 800, 1500, 4000, 10000, 20000])
            .range(rangeArray);

        const component = this;
        const svg = d3.select(this.mapRef);
        svg.html(null);

        const projection = d3.geoAlbersUsa();
        var path: any = d3.geoPath(projection);

        svg.attr("preserveAspectRatio", "xMidYMid meet")
            .attr("viewBox", "-100 0 1100 800")
            .attr('class', classes.stateMap)
            .append("g")
            .selectAll("path")
            .data(topojson.feature(this.state.usMapData, this.state.usMapData.objects.states).features)
            .enter()
            .append("path")
            .attr("d", path)
            .style('fill', (d: any) => {
                const geometry = component.state.usMapData.objects.states.geometries.find((geo: any) => geo.id === d.id);
                const colorNumber = +this.getPathDataBasedOnMapData(geometry)![0];
                return color(colorNumber);
            })
            .append('svg:title')
            .text((d: any) => {
                const geometry = this.state.usMapData.objects.states.geometries.find((geo: any) => geo.id === d.id);
                const text = this.getPathDataBasedOnMapData(geometry)![1]
                return text;
            })

        svg.append("path")
            .datum(topojson.mesh(this.state.usMapData, this.state.usMapData.objects.states, (a: any, b: any) => a.id !== b.id))
            .attr("class", "names")
            .attr("d", path);

        this.setState({ loadingMap: false });
    }

    getPathDataBasedOnMapData = (geo: any) => {
        const { filterByData } = this.state;
        let colorNumber: Number = geo.downloads + geo.views;
        let tooltipText: string = `${geo.name}\nViews: ${geo.views}\nDownloads: ${geo.downloads}`;
        switch (filterByData) {
            case MapFilters.Views:
                colorNumber = geo.views;
                tooltipText = `${geo.name}\nViews: ${geo.views}`;
                break;
            case MapFilters.Downloads:
                colorNumber = geo.downloads;
                tooltipText = `${geo.name}\nDownloads: ${geo.downloads}`;
                break;
            default:
                break;
        }
        return [colorNumber.toString(), tooltipText];
    }

    async updateChartData() {
        const { chartViewing, filterChartYear, filterChartMonth } = this.state;


        var response = await MiscService.getProducerPayoutByYear(+this.state.filterChartYear);

        if (response != null) {
            this.massageProducerPayoutDate(response);
        }

        let filteredChartData = [...this.state.filteredChartData];
        let producerPayoutData = [...this.state.producerPayoutData];
        let monthData = [...this.state.monthData];
        let totalChart = 0;

        switch (chartViewing) {
            case ChartViewingTypes.Year:
                filteredChartData = producerPayoutData.filter(data => data.year === filterChartYear);
                break;
            case ChartViewingTypes.Month:
                filteredChartData = producerPayoutData.filter(data => data.year === filterChartYear && Months[data.month] === filterChartMonth);
                monthData = [{
                    name: 'Media',
                    total: filteredChartData[0].salesFromMedia
                },
                {
                    name: 'Sermon Series Kit',
                    total: filteredChartData[0].salesFromSSK
                },
                {
                    name: 'Revenue Share',
                    total: filteredChartData[0].revenueShare
                }];
                break;
        }

        filteredChartData.map(s => {
            if (s.total) {
                totalChart += s.total
            }
        });

        this.setState({ filteredChartData, monthData, totalChart });
    }

    onYearBarClick = (e: any) => {
        if (!e) {
            return;
        }
        var month = e.activeLabel;
        if (!e.activeLabel) {
            return;
        }

        let letMonthsString : string = Months[Months[month]];
        let typedMonthsString: keyof typeof Months = letMonthsString as keyof typeof Months;
        let typedAsMonths : Months = Months[typedMonthsString];

        this.setState({ chartViewing: ChartViewingTypes.Month, filterChartMonth: typedAsMonths, anchorChartMenu: undefined }, () => this.updateChartData())

    }

    renderProducerPayoutCard() {
        var classes = this.props.classes;
        var { filteredChartData, anchorChartMenu, chartViewing, anchorChartYearMenu, anchorChartMonthMenu, filterChartYear, filterChartMonth, monthData, totalChart } = this.state;


        return (
            <Card className={classes.producerPayoutCard}>
                <CardContent>
                    <div className={classes.filterButtons}>
                        <div>
                            <Typography variant="h6">
                                {chartViewing === ChartViewingTypes.Month && `${Months[filterChartMonth].toString()}, `}{filterChartYear} Producer Payout
                        </Typography>
                            <Typography variant="body1">
                                <strong>Total: $<AnimatedNumberLabel minDecimalPlaces={0} maxDecimalPlaces={0} value={totalChart} /></strong>
                            </Typography>
                        </div>
                        <div>
                            <Button className={classes.filterButton} variant="outlined" aria-haspopup="true" aria-owns={anchorChartMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorChartMenu: e.currentTarget })}>
                                Viewing By: {chartViewing}
                            </Button>
                            <Menu
                                anchorEl={anchorChartMenu}
                                open={anchorChartMenu != null}
                                onClose={() => this.setState({ anchorChartMenu: undefined })}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                getContentAnchorEl={null}
                            >
                                {Object.values(ChartViewingTypes).map(viewing => {
                                    return (
                                        <MenuItem key={viewing} onClick={() => this.setState({ chartViewing: viewing as ChartViewingTypes, anchorChartMenu: undefined }, () => this.updateChartData())}>
                                            <ListItemText primary={viewing} />
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                            <Button className={classes.filterButton} variant="outlined" aria-haspopup="true" aria-owns={anchorChartYearMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorChartYearMenu: e.currentTarget })}>
                                Year: {filterChartYear}
                            </Button>
                            <Menu
                                anchorEl={anchorChartYearMenu}
                                open={anchorChartYearMenu != null}
                                onClose={() => this.setState({ anchorChartYearMenu: undefined })}
                                transformOrigin={{ vertical: "top", horizontal: "right" }}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                getContentAnchorEl={null}
                            >
                                {Object.values(ChartYears).filter(x => typeof x === "number").map(year => {
                                    return (
                                        <MenuItem key={year} onClick={() => this.setState({ filterChartYear: year as ChartYears, anchorChartYearMenu: undefined }, () => this.updateChartData())}>
                                            <ListItemText primary={year} />
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                            {chartViewing === ChartViewingTypes.Month &&
                                <>
                                    <Button className={classes.filterButton} variant="outlined" aria-haspopup="true" aria-owns={anchorChartMonthMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorChartMonthMenu: e.currentTarget })}>
                                        Month: {Months[filterChartMonth].toString()}
                                    </Button>
                                    <Menu
                                        anchorEl={anchorChartMonthMenu}
                                        open={anchorChartMonthMenu != null}
                                        onClose={() => this.setState({ anchorChartMonthMenu: undefined })}
                                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                        getContentAnchorEl={null}
                                    >
                                        {Object.values(Months).filter(x => typeof x === "number").map(month => {
                                            return (
                                                <MenuItem key={month} onClick={() => this.setState({ filterChartMonth: month as Months, anchorChartMonthMenu: undefined }, () => this.updateChartData())}>
                                                    <ListItemText primary={Months[month].toString()} />
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </>
                            }
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={650}>
                        {chartViewing === ChartViewingTypes.Year ?
                            <ComposedChart onClick={(e: RechartsFunction) => this.onYearBarClick(e)} barSize={60} barGap={30} className={classes.barChart} data={filteredChartData} margin={{ top: 20, right: 60, bottom: 20, left: 20 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis padding={{ left: 60, right: 60 }} dataKey="month" />
                                <YAxis tickFormatter={(value) => `$${new Intl.NumberFormat('en').format(+value)}`} />
                                <Tooltip formatter={(value) => `$${new Intl.NumberFormat('en').format(+value)}`} filterNull={true} />
                                <Legend />
                                <Bar stackId="Month" legendType="triangle" dataKey="revenueShare" name="Revenue Share" fill="#7bc5ff" />
                                <Bar stackId="Month" legendType="star" dataKey="salesFromSSK" name="Sermon Series Kits" fill="#61b9ff" />
                                <Bar stackId="Month" legendType="diamond" dataKey="salesFromMedia" name="Media" fill="#2196f3" label={{ formatter: (value) => value > 0 ? `$${new Intl.NumberFormat('en').format(+value)}` : '', position: "top" }} />
                                <Line type='monotone' dataKey='total' name="Total" stroke='#ff7300' />
                                <Brush dataKey="month" height={30} stroke="#2196f3" />
                            </ComposedChart>
                            :
                            monthData.every(month => month.total === null) ?
                                <div className={classes.noData}>
                                    <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>

                                        No Data for {Months[filterChartMonth].toString()}, {filterChartYear}.
                                    </Typography>
                                </div>
                                :
                                <PieChart>
                                    <Pie
                                        data={monthData}
                                        label={(value) => `${value.name}: $${new Intl.NumberFormat('en').format(+value.total)}`}
                                        outerRadius="80%"
                                        innerRadius="50%"
                                        dataKey="total"
                                    >
                                        {
                                            monthData.map((entry, index) => <Cell key={`cell-${index}`} fill={PieColors[index % PieColors.length]} />)
                                        }
                                        <Label width={50} position="center" fontSize={20}>
                                            {`${Months[filterChartMonth].toString()} Total: $${new Intl.NumberFormat('en').format(totalChart)}`}
                                        </Label>
                                    </Pie>
                                    <Tooltip formatter={(value) => `$${new Intl.NumberFormat('en').format(+value)}`} filterNull={true} />
                                </PieChart>
                        }

                    </ResponsiveContainer>
                </CardContent>
            </Card>
        );
    }

    renderMapCard() {
        var classes = this.props.classes;
        var { anchorYearMenu, anchorMonthMenu, loadingMap, filterByMonth, filterByYear, anchorByData, filterByData, totalViews, totalDownloads } = this.state;

        return (
            <Card className={classes.mapCard}>
                <div className={classes.filterButtons}>
                    <div className={classes.totalBox}>
                        <Typography variant="h6">
                            {Months[filterByMonth]}, {filterByYear}
                        </Typography>
                        {(filterByData === MapFilters.All || filterByData === MapFilters.Views) &&
                            <Typography variant="body2" >
                                <strong>Total views: <AnimatedNumberLabel minDecimalPlaces={0} maxDecimalPlaces={0} value={totalViews} /></strong>
                            </Typography>
                        }
                        {(filterByData === MapFilters.All || filterByData === MapFilters.Downloads) &&
                            <Typography variant="body2">
                                <strong>Total downloads: <AnimatedNumberLabel minDecimalPlaces={0} maxDecimalPlaces={0} value={totalDownloads} /></strong>
                            </Typography>
                        }
                    </div>
                    <div>
                        <Button variant="outlined" disabled={loadingMap} aria-haspopup="true" aria-owns={anchorByData ? 'menu' : undefined} onClick={e => this.setState({ anchorByData: e.currentTarget })}>
                            View: {filterByData}
                        </Button>
                        <Menu
                            anchorEl={anchorByData}
                            open={anchorByData != null}
                            onClose={() => this.setState({ anchorByData: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            {Object.values(MapFilters).map(filter => {
                                return (
                                    <MenuItem key={filter} onClick={() => this.setState({ filterByData: filter, anchorByData: undefined }, async () => await this.getMapData())}>
                                        <ListItemText primary={filter} />
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                        <Button className={classes.filterButton} variant="outlined" disabled={loadingMap} aria-haspopup="true" aria-owns={anchorYearMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorYearMenu: e.currentTarget })}>
                            Year: {filterByYear}
                        </Button>
                        <Menu
                            anchorEl={anchorYearMenu}
                            open={anchorYearMenu != null}
                            onClose={() => this.setState({ anchorYearMenu: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            {years.map(year => {
                                return (
                                    <MenuItem key={year} onClick={() => this.setState({ filterByYear: year, anchorYearMenu: undefined }, async () => await this.getMapData())}>
                                        <ListItemText primary={year} />
                                    </MenuItem>
                                );
                            })}
                        </Menu>

                        <Button className={classes.filterButton} variant="outlined" disabled={loadingMap} aria-haspopup="true" aria-owns={anchorMonthMenu ? 'menu' : undefined} onClick={e => this.setState({ anchorMonthMenu: e.currentTarget })}>
                            Month: {Months[filterByMonth].toString()}
                        </Button>
                        <Menu
                            anchorEl={anchorMonthMenu}
                            open={anchorMonthMenu != null}
                            onClose={() => this.setState({ anchorMonthMenu: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            {Object.values(Months).filter(x => typeof x === "number").map(month => {
                                return (
                                    <MenuItem key={month} onClick={() => this.setState({ filterByMonth: month as Months, anchorMonthMenu: undefined }, async () => await this.getMapData())}>
                                        <ListItemText primary={Months[month].toString()} />
                                    </MenuItem>
                                );
                            })}
                        </Menu>

                    </div>
                </div>
                {
                    loadingMap &&
                    <div>
                        <div className="lds-ripple"><div></div><div></div></div>
                        <br />
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            Loading Map...
                        </Typography>
                    </div>
                }
                <div className={classes.mapContainer}>
                    <svg style={{ display: (loadingMap ? 'none' : 'block') }} className={classes.map} ref={(input) => { this.mapRef = input; }} />
                </div>
            </Card >
        );
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.snackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.snackbarMessage}>
                                <ErrorIcon className={classes.snackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Container maxWidth="lg">
                    {/* {this.renderProducerPayoutCard()} */}
                    {this.renderMapCard()}
                </Container>

            </div>
        );
    }
}

export default withRoot(withStyles(styles)(MediaDashboard));
