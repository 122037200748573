export enum SermonStatus {
    Pending = 1,
    Approved = 3,
    Rejected = 4,
    Deleted = 5
}

export default interface Sermon {
    id: number;
    sermonSeriesId?: number;
    sermonSeriesName: string;
    title: string;
    intro: string;
    body: string;
    language: string;
    type: string;
    targetAudience: string;
    scriptures: string[];
    tags: string[];
    date: Date;
    status: SermonStatus;
    rating: number;
    ratingCount: number;
    views: number;
    url: string;
    rejectionNotes: string;
}