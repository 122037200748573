export default class DateHelper {
    static getDateStringFormattedForInput = (date: Date) => {
        let pad = "00";

        let year = date.getFullYear().toString();
        let month = (date.getMonth()  + 1).toString();
        let day = date.getDate().toString();

        return year + '-' + (pad.substring(0, pad.length - month.length) + month) + '-' + (pad.substring(0, pad.length - day.length) + day);
    }
}