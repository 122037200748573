export enum AppConfigEnvironment {
    Development = "Development",
    Staging = "Staging",
    Production = "Production"
}

export default interface AppConfig {
    environment: AppConfigEnvironment;
    googleAnalytics: { isEnabled: boolean, trackingId: string };
    paypalUrl: string;
    mainSiteUrl: string;
    paymentsSiteUrl: string;
    makerSiteUrl: string;
    sparkSiteUrl: string;
    accountSiteUrl: string;
    glooSubscriptionsUrl: string;
    glooUserProfileUrl: string;
}
