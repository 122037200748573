import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsIcon from '@material-ui/icons/Directions';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import FavoriteIcon from '@material-ui/icons/Favorite';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AppConfig from '../../entities/AppConfig';
import Favorite from '../../entities/Favorite';
import SermonFolder from '../../entities/SermonFolder';
import { MiscService } from '../../services/MiscService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flexGrow: 1,
        },
        toolbar: {
            flexWrap: 'wrap'
        },
        formControl: {
            margin: theme.spacing(),
            minWidth: 200,
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px"
        },
        cardImage: {
            height: 150,
            backgroundSize: "contain"
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 2,
            right: theme.spacing() * 2,
        },
        extendedFab: {
            marginRight: theme.spacing()
        },
        radioBox: {
            padding: '5px 12px'
        },
        [theme.breakpoints.down('sm')]: {
            cardContainer: {
                width: "100%",
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends RouteComponentProps<{ id: string | undefined }>, WithStyles<typeof styles> {
    config: AppConfig;
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    sermonFolders: SermonFolder[];
    selectedSermonFolderId: number | undefined;
    selectedSermonFolderIdToMove: number | undefined;
    favorites: Favorite[];
    selectedFavoriteId: number | undefined;
    errors: FieldValidationError[];
    showUpdateSuccessMessage: boolean;
    updateSuccessMessage: string;
    showEditDialog: boolean;
    editSermonFolderName: string;
    editSermonFolderNotes: string;
    showEditFavoriteDialog: boolean;
    editFavoriteNotes: string;
    showAddDialog: boolean;
    addSermonFolderName: string;
    addSermonFolderNotes: string;
    showDeleteDialog: boolean;
    showDeleteFavoriteDialog: boolean;
    showMoveFavoriteDialog: boolean;
}

class SermonFolders extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: false,
            sermonFolders: [],
            selectedSermonFolderId: props.match.params.id ? parseInt(props.match.params.id, 10) : undefined,
            selectedSermonFolderIdToMove: props.match.params.id ? parseInt(props.match.params.id, 10) : undefined,
            favorites: [],
            selectedFavoriteId: undefined,
            errors: [],
            showUpdateSuccessMessage: false,
            updateSuccessMessage: '',
            showEditDialog: false,
            editSermonFolderName: '',
            editSermonFolderNotes: '',
            showEditFavoriteDialog: false,
            editFavoriteNotes: '',
            showAddDialog: false,
            addSermonFolderName: '',
            addSermonFolderNotes: '',
            showDeleteDialog: false,
            showDeleteFavoriteDialog: false,
            showMoveFavoriteDialog: false
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - SermonFolders";

        try {
            const sermonFolders = await MiscService.getSermonFolders();

            if (sermonFolders) {

                var selectedSermonFolderId = this.state.selectedSermonFolderId;

                if (sermonFolders.length !== 0) {
                    if (!selectedSermonFolderId || !sermonFolders.some(f => f.id === selectedSermonFolderId)) {
                        selectedSermonFolderId = sermonFolders[0].id;
                    }
                }
                else {
                    selectedSermonFolderId = undefined;
                }

                this.setState({ sermonFolders }, async () => await this.updateSelectedSermonFolder(selectedSermonFolderId));
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async updateSelectedSermonFolder(sermonFolderId: number | undefined) {

        if (sermonFolderId) {

            const selectedSermonFolder = this.state.sermonFolders.find(f => f.id === sermonFolderId)!;

            this.setState({
                selectedSermonFolderId: selectedSermonFolder.id,
                editSermonFolderName: selectedSermonFolder.name,
                editSermonFolderNotes: selectedSermonFolder.notes ? selectedSermonFolder.notes : ''
            });
        }
        else {

            this.setState({
                selectedSermonFolderId: undefined,
                editSermonFolderName: '',
                editSermonFolderNotes: ''
            });
        }

        await this.updateFavorites(sermonFolderId);
    }

    cancelEditSermonFolder() {

        if (this.state.selectedSermonFolderId) {

            const selectedSermonFolder = this.state.sermonFolders.find(f => f.id === this.state.selectedSermonFolderId)!;

            this.setState({
                showEditDialog: false,
                editSermonFolderName: selectedSermonFolder.name,
                editSermonFolderNotes: selectedSermonFolder.notes ? selectedSermonFolder.notes : ''
            });
        }
    }

    cancelAddSermonFolder() {

        this.setState({
            showAddDialog: false,
            addSermonFolderName: '',
            addSermonFolderNotes: ''
        });
    }

    async updateFavorites(sermonFolderId: number | undefined) {

        if (!sermonFolderId) {

            this.props.history.push(`/sermon-folders`);
            this.setState({ favorites: [], loading: false, enableSubmit: true });
        }
        else {
            try {
                const favorites = await MiscService.getFavorites(sermonFolderId);

                if (favorites) {
                    this.props.history.push(`/sermon-folders/${sermonFolderId}`);
                    this.setState({ favorites, loading: false, enableSubmit: true });
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError] });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
    }

    async deleteSermonFolder() {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            if (this.state.selectedSermonFolderId) {
                this.setState({ enableSubmit: false });
                const response = await MiscService.deleteSermonFolder(this.state.selectedSermonFolderId);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let sermonFolders = this.state.sermonFolders;
                    const index = sermonFolders.findIndex(f => f.id === this.state.selectedSermonFolderId);

                    if (index >= 0) {

                        sermonFolders.splice(index, 1);
                        let selectedSermonFolderId: number | undefined = undefined;
                        if (sermonFolders.length > 0) {
                            selectedSermonFolderId = sermonFolders[0].id;
                        }

                        this.setState({
                            sermonFolders,
                            errors: [],
                            enableSubmit: true,
                            showDeleteDialog: false,
                            showUpdateSuccessMessage: true,
                            updateSuccessMessage: 'SermonFolder deleted.',
                        }, async () => await this.updateSelectedSermonFolder(selectedSermonFolderId));
                    }
                    else {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
        }
    }

    async deleteFavorite() {

        if (!this.state.enableSubmit) {
            return;
        }

        try {
            if (this.state.selectedFavoriteId) {
                this.setState({ enableSubmit: false });
                const response = await MiscService.deleteFavorite(this.state.selectedFavoriteId);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let favorites = this.state.favorites;
                    const index = favorites.findIndex(f => f.id === this.state.selectedFavoriteId);

                    if (index >= 0) {

                        favorites.splice(index, 1);
                        this.setState({
                            favorites,
                            errors: [],
                            enableSubmit: true,
                            showDeleteFavoriteDialog: false,
                            showUpdateSuccessMessage: true,
                            updateSuccessMessage: 'Favorite deleted.',
                        });
                    }
                    else {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
        }
    }

    async moveFavorite(favoriteId: number, index: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {

            this.setState({ enableSubmit: false });
            let favorites = this.state.favorites;
            const currentIndex = favorites.findIndex(f => f.id === favoriteId);
            let favorite = favorites[currentIndex];

            const response = await MiscService.saveFavorite(favoriteId, this.state.selectedSermonFolderId!, index, favorite.notes);

            if (ServerResponse.isServerResponse<Favorite>(response)) {

                favorite = response.data;

                favorites.splice(currentIndex, 1);
                favorites.splice(index, 0, favorite);

                this.setState({
                    favorites,
                    errors: [],
                    enableSubmit: true,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'Favorite moved.',
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
            }

        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
        }
    }

    async moveFavoriteFolder(favoriteId?: number, moveToSermonFolderId?: number) {

        if (!this.state.enableSubmit) {
            return;
        }

        try {

            this.setState({ enableSubmit: false });
            let favorites = this.state.favorites;
            const currentIndex = favorites.findIndex(f => f.id === favoriteId);
            let favorite = favorites[currentIndex];

            const response = await MiscService.saveFavorite(favoriteId!, moveToSermonFolderId!, 0, favorite.notes);

            if (ServerResponse.isServerResponse<Favorite>(response)) {
                await this.updateSelectedSermonFolder(moveToSermonFolderId);

                this.setState({
                    errors: [],
                    enableSubmit: true,
                    showMoveFavoriteDialog: false,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'Favorite moved to a new folder.',
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showMoveFavoriteDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true, showMoveFavoriteDialog: false });
            }

        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showMoveFavoriteDialog: false });
        }
    }

    async handleEditFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.editSermonFolderName) {
            try {
                this.setState({ enableSubmit: false });
                const response = await MiscService.saveSermonFolder(this.state.selectedSermonFolderId, this.state.editSermonFolderName, this.state.editSermonFolderNotes);

                if (ServerResponse.isServerResponse<SermonFolder>(response)) {

                    let selectedSermonFolder = this.state.sermonFolders.find(f => f.id === this.state.selectedSermonFolderId)!;

                    selectedSermonFolder.name = this.state.editSermonFolderName;
                    selectedSermonFolder.notes = this.state.editSermonFolderNotes;

                    this.setState({
                        errors: [],
                        enableSubmit: true,
                        showEditDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'SermonFolder updated.',
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showEditDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showEditDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true, showEditDialog: false });
            }
        }
    }

    async handleEditFavoriteFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.selectedFavoriteId && this.state.selectedSermonFolderId) {
            try {

                this.setState({ enableSubmit: false });

                const sortOrder = this.state.favorites.findIndex(f => f.id === this.state.selectedFavoriteId);

                const response = await MiscService.saveFavorite(this.state.selectedFavoriteId, this.state.selectedSermonFolderId, sortOrder, this.state.editFavoriteNotes);

                if (ServerResponse.isServerResponse<Favorite>(response)) {

                    let favorite = this.state.favorites.find(f => f.id === this.state.selectedFavoriteId)!;

                    favorite.notes = this.state.editFavoriteNotes;

                    this.setState({
                        errors: [],
                        enableSubmit: true,
                        showEditFavoriteDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'Favorite updated.',
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showEditDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showEditDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true, showEditDialog: false });
            }
        }
    }

    async handleAddFormSubmit(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.addSermonFolderName) {
            try {
                this.setState({ enableSubmit: false });
                const response = await MiscService.saveSermonFolder(undefined, this.state.addSermonFolderName, this.state.addSermonFolderNotes);

                if (ServerResponse.isServerResponse<SermonFolder>(response)) {

                    let sermonFolders = this.state.sermonFolders;
                    sermonFolders.unshift(response.data);
                    const selectedSermonFolderId = sermonFolders[0].id;

                    this.setState({
                        sermonFolders,
                        errors: [],
                        enableSubmit: true,
                        showAddDialog: false,
                        addSermonFolderName: '',
                        addSermonFolderNotes: '',
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'New SermonFolder added.',
                    }, async () => await this.updateSelectedSermonFolder(selectedSermonFolderId));
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showAddDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showAddDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true, showAddDialog: false });
            }
        }
    }

    render() {

        const classes = this.props.classes;
        const selectedSermonFolder = this.state.sermonFolders.find(f => f.id === this.state.selectedSermonFolderId);
        const selectedFavorite = this.state.favorites.find(f => f.id === this.state.selectedFavoriteId);

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <CheckCircleIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            SermonFolders
                        </Typography>
                    </div>
                    <div className={classes.spacer} />
                    {this.state.sermonFolders.length > 0 && this.state.selectedSermonFolderId &&
                        <React.Fragment>
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="select-sermonfolder">Selected SermonFolder</InputLabel>
                                <Select
                                    value={this.state.selectedSermonFolderId}
                                    onChange={async (e) => { await this.updateSelectedSermonFolder(parseInt(e.target.value as string, 10)); }}
                                    inputProps={{
                                        name: 'sermonfolder',
                                        id: 'select-sermonfolder',
                                    }}
                                >
                                    {this.state.sermonFolders.map(sermonFolder =>
                                        <MenuItem key={sermonFolder.id} value={sermonFolder.id} selected={sermonFolder.id === this.state.selectedSermonFolderId}>{sermonFolder.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <IconButton disabled={!this.state.enableSubmit} aria-label="Edit SermonFolder" onClick={(e) => this.setState({ showEditDialog: true })}>
                                <EditIcon />
                            </IconButton>
                            <IconButton disabled={!this.state.enableSubmit} aria-label="Delete SermonFolder" onClick={(e) => this.setState({ showDeleteDialog: true })}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </React.Fragment>
                    }
                </Toolbar>
                {!this.state.loading && selectedSermonFolder &&
                    <Toolbar style={{ paddingBottom: '20px' }}>
                        <Typography variant="body2"><strong>Notes:</strong> {selectedSermonFolder.notes}</Typography>
                    </Toolbar>
                }
                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading && this.state.sermonFolders.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <FavoriteIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You haven't added any SermonFolders yet. Add one below.
                        </Typography>
                    }
                    {!this.state.loading && this.state.sermonFolders.length > 0 && this.state.favorites.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <FavoriteIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You haven't added any favorites to this SermonFolder yet.
                            <br />
                            <br />
                            Start exploring <a href={this.props.config.mainSiteUrl}>SermonCentral</a> to find something new to save.
                        </Typography>
                    }
                    {!this.state.loading && this.state.favorites.length > 0 && this.state.favorites.map((favorite, index) =>
                        <Card className={classes.cardContainer} key={favorite.id}>
                            <CardMedia
                                className={classes.cardImage}
                                image={favorite.imageUri}
                                title={favorite.title}
                            />
                            <CardContent>
                                <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                    {new Date(favorite.dateCreated).toLocaleDateString()}
                                </Typography>
                                <Typography className={classes.cardStatus} variant="caption" color="textSecondary">
                                    {favorite.type}
                                </Typography>
                                <Typography variant="h5" component="h2">{favorite.title}</Typography>
                                <Typography component="p" dangerouslySetInnerHTML={{ __html: favorite.summary }} />
                                <br />
                                <Typography component="p" dangerouslySetInnerHTML={{ __html: '<strong>Notes:</strong> ' + favorite.notes }} />
                            </CardContent>
                            <CardActions>
                                <Button disabled={!this.state.enableSubmit} color="secondary" onClick={() => this.setState({ selectedFavoriteId: favorite.id, showDeleteFavoriteDialog: true })}>
                                    Delete
                                </Button>
                                <div style={{ marginLeft: "auto", display: 'flex' }}>
                                    <IconButton disabled={!this.state.enableSubmit} onClick={() => this.setState({ selectedFavoriteId: favorite.id, showMoveFavoriteDialog: true })}>
                                        <DirectionsIcon />
                                    </IconButton>
                                    {index > 0 &&
                                        <IconButton disabled={!this.state.enableSubmit} aria-label="Move Up" onClick={() => this.moveFavorite(favorite.id, index - 1)}>
                                            <ArrowUpwardIcon />
                                        </IconButton>
                                    }
                                    {index < this.state.favorites.length - 1 &&
                                        <IconButton disabled={!this.state.enableSubmit} aria-label="Move Down" onClick={() => this.moveFavorite(favorite.id, index + 1)}>
                                            <ArrowDownwardIcon />
                                        </IconButton>
                                    }
                                    <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ selectedFavoriteId: favorite.id, showEditFavoriteDialog: true, editFavoriteNotes: favorite.notes })}>
                                        Edit
                                    </Button>
                                    {favorite.uri && favorite.uri.indexOf('/') >= 0 &&
                                        <Button disabled={!this.state.enableSubmit} color="primary" href={favorite.uri} target='_blank'>
                                            View
                                        </Button>
                                    }
                                </div>
                            </CardActions>
                        </Card>
                    )}

                    <Fab disabled={!this.state.enableSubmit} variant="extended" className={classes.fab} color="primary" onClick={() => this.setState({ showAddDialog: true })}>
                        <AddIcon className={classes.extendedFab} />
                        Add Folder
                    </Fab>

                    <Dialog
                        open={this.state.showEditDialog}
                        onClose={() => this.cancelEditSermonFolder()}
                        aria-labelledby="form-dialog-title"
                    >
                        <form onSubmit={(e) => this.handleEditFormSubmit(e)}>
                            <DialogTitle id="form-dialog-title">Edit SermonFolder</DialogTitle>
                            <DialogContent>
                                <TextField
                                    id="name"
                                    label="Name"
                                    required
                                    fullWidth
                                    value={this.state.editSermonFolderName}
                                    onChange={(e) => this.setState({ editSermonFolderName: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Name')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Name') }} />}
                                />
                                <TextField
                                    id="notes"
                                    label="Notes"
                                    multiline
                                    rows="4"
                                    fullWidth
                                    value={this.state.editSermonFolderNotes}
                                    onChange={(e) => this.setState({ editSermonFolderNotes: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Notes')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Notes') }} />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={!this.state.enableSubmit} onClick={() => this.cancelEditSermonFolder()} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={!this.state.enableSubmit} type="submit" color="primary">
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog
                        open={this.state.showEditFavoriteDialog}
                        onClose={() => this.setState({ showEditFavoriteDialog: false })}
                        aria-labelledby="form-dialog-title"
                    >
                        <form onSubmit={(e) => this.handleEditFavoriteFormSubmit(e)}>
                            <DialogTitle id="form-dialog-title">Edit Favorite</DialogTitle>
                            <DialogContent>
                                <TextField
                                    id="notes"
                                    label="Notes"
                                    multiline
                                    rows="4"
                                    fullWidth
                                    value={this.state.editFavoriteNotes}
                                    onChange={(e) => this.setState({ editFavoriteNotes: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Notes')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Notes') }} />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showEditFavoriteDialog: false })} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={!this.state.enableSubmit} type="submit" color="primary">
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog
                        open={this.state.showAddDialog}
                        onClose={() => this.cancelAddSermonFolder()}
                        aria-labelledby="form-dialog-title"
                    >
                        <form onSubmit={(e) => this.handleAddFormSubmit(e)}>
                            <DialogTitle id="form-dialog-title">Add SermonFolder</DialogTitle>
                            <DialogContent>
                                <TextField
                                    id="name"
                                    label="Name"
                                    required
                                    fullWidth
                                    value={this.state.addSermonFolderName}
                                    onChange={(e) => this.setState({ addSermonFolderName: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Name')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Name') }} />}
                                />
                                <TextField
                                    id="notes"
                                    label="Notes"
                                    multiline
                                    rows="4"
                                    fullWidth
                                    value={this.state.addSermonFolderNotes}
                                    onChange={(e) => this.setState({ addSermonFolderNotes: e.target.value })}
                                    error={FieldValidationError.isFieldInError(this.state.errors, 'Notes')}
                                    helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Notes') }} />}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button disabled={!this.state.enableSubmit} onClick={() => this.cancelAddSermonFolder()} color="primary">
                                    Cancel
                                </Button>
                                <Button disabled={!this.state.enableSubmit} type="submit" color="primary">
                                    Save
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>

                    <Dialog
                        open={this.state.showDeleteDialog}
                        onClose={() => this.setState({ showDeleteDialog: false })}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                    >
                        <DialogTitle id="delete-dialog-title">Delete SermonFolder</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant='subtitle2' id="delete-dialog-description">
                                Are you sure you wish to delete the SermonFolder <strong>{selectedSermonFolder ? selectedSermonFolder.name : ''}</strong>?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showDeleteDialog: false })} color="default">
                                Cancel
                            </Button>
                            <Button disabled={!this.state.enableSubmit} onClick={async () => await this.deleteSermonFolder()} color="secondary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.showDeleteFavoriteDialog}
                        onClose={() => this.setState({ showDeleteFavoriteDialog: false })}
                        aria-labelledby="delete-dialog-title"
                        aria-describedby="delete-dialog-description"
                    >
                        <DialogTitle id="delete-dialog-title">Delete Favorite</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant='subtitle2' id="delete-dialog-description">
                                Are you sure you wish to delete the Favorite <strong>{selectedFavorite ? selectedFavorite.title : ''}</strong>?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showDeleteFavoriteDialog: false })} color="default">
                                Cancel
                            </Button>
                            <Button disabled={!this.state.enableSubmit} onClick={async () => await this.deleteFavorite()} color="secondary">
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.showMoveFavoriteDialog}
                        onClose={() => this.setState({ showMoveFavoriteDialog: false, selectedSermonFolderIdToMove: this.state.selectedSermonFolderId })}
                        aria-labelledby="move-dialog-title"
                        aria-describedby="move-dialog-description"
                    >
                        <DialogTitle id="move-dialog-title">Move Favorite</DialogTitle>
                        <DialogContent>
                            <DialogContentText variant='subtitle2' id="move-dialog-description">
                                Select a folder to move to
                            </DialogContentText>
                            <FormControl className={classes.formControl}>
                                <RadioGroup value={this.state.selectedSermonFolderIdToMove ? this.state.selectedSermonFolderIdToMove.toString() : undefined} onChange={(e: any) => this.setState({ selectedSermonFolderIdToMove: parseInt(e.target.value, 10) })}>
                                    {this.state.sermonFolders.map(folder => {
                                        return (
                                            <FormControlLabel
                                                disabled={folder.id === this.state.selectedSermonFolderId}
                                                key={folder.id}
                                                value={folder.id.toString()}
                                                control={<Radio color='primary' className={classes.radioBox} />}
                                                label={folder.name}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={!this.state.enableSubmit} onClick={() => this.setState({ showMoveFavoriteDialog: false, selectedSermonFolderIdToMove: this.state.selectedSermonFolderId })} color="default">
                                Cancel
                            </Button>
                            <Button disabled={!this.state.enableSubmit} onClick={async () => await this.moveFavoriteFolder(this.state.selectedFavoriteId, this.state.selectedSermonFolderIdToMove)} color="secondary">
                                Move
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Grid>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(SermonFolders));