export enum SermonIllustrationStatus {
    Rejected = 0,
    Approved = 1,
    Pending = 3,
    Deleted = 4
}

export default interface SermonIllustration {
    id: number;
    title: string;
    body: string;
    language: string;
    category: string;
    targetAudience: string;
    scriptures: string[];
    tags: string[];
    date: Date;
    status: SermonIllustrationStatus;
    rating: number;
    ratingCount: number;
    intro: string;
    views: number;
    url: string;
    rejectionNotes: string;
}