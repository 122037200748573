import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import LinkIcon from '@material-ui/icons/Link';
import * as React from 'react';
import ApplicationUser from '../../entities/ApplicationUser';
import SharedLink from '../../entities/SharedLink';
import { ProfileService } from '../../services/ProfileService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        viewButton: {
            marginLeft: 'auto'
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 2,
            right: theme.spacing() * 2,
        },
        extendedFab: {
            marginRight: theme.spacing()
        },
        dialogContent: {
            minWidth: '300px'
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        cardContainer: {
            maxWidth: "500px",
            marginBottom: "16px"
        },
        [theme.breakpoints.down('sm')]: {
            dialogContent: {
                minWidth: 'auto',
                width: '100%'
            },
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
    user: ApplicationUser;
}

interface State {
    enableSubmit: boolean;
    links?: SharedLink[];
    showAddLinkDialog: boolean;
    addLinkText: string;
    addLinkUrl: string;
    showDeleteLinkDialog: boolean;
    selectedLinkToDelete?: SharedLink;
    showTooManyLinksDialog: boolean;
    errors: FieldValidationError[];
    showUpdateSuccessMessage: boolean;
    updateSuccessMessage: string;
}

class SharedLinks extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showAddLinkDialog: false,
            enableSubmit: true,
            showDeleteLinkDialog: false,
            showTooManyLinksDialog: false,
            errors: [],
            addLinkText: '',
            addLinkUrl: '',
            showUpdateSuccessMessage: false,
            updateSuccessMessage: ''
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Shared Links";

        try {
            const links = await ProfileService.getSharedLinks();

            if (links) {
                this.setState({ links });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async deleteLink() {

        if (!this.state.enableSubmit) {
            return;
        }
        try {
            if (this.state.links && this.state.selectedLinkToDelete) {

                this.setState({ enableSubmit: false });
                const response = await ProfileService.deleteSharedLink(this.state.selectedLinkToDelete.id);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let links = this.state.links;
                    links = links.filter(l => l.id !== this.state.selectedLinkToDelete!.id);

                    this.setState({
                        links,
                        selectedLinkToDelete: undefined,
                        enableSubmit: true,
                        showDeleteLinkDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'The shared link was deleted'
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteLinkDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showDeleteLinkDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteLinkDialog: false });
                }
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showDeleteLinkDialog: false });
        }
    }

    async onAddUrl(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();
        if (!this.state.enableSubmit) {
            return;
        }
        try {
            this.setState({ enableSubmit: false });

            const text = this.state.addLinkText;
            let url = this.state.addLinkUrl;
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                url = 'http://' + url;
            }

            const response = await ProfileService.addSharedLink(text, url);

            if (ServerResponse.isServerResponse<SharedLink>(response) && this.state.links) {

                let links = this.state.links;
                links.push(response.data);
                this.setState({
                    links,
                    enableSubmit: true,
                    showAddLinkDialog: false,
                    showUpdateSuccessMessage: true,
                    updateSuccessMessage: 'A new shared link has been added'
                });
            }
            else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                if (response.valid) {
                    const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                    this.setState({ errors: [serverError], enableSubmit: true, showAddLinkDialog: false });
                }
                else {
                    this.setState({ errors: response.errors, enableSubmit: true });
                }
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: [response.message] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError], enableSubmit: true, showAddLinkDialog: false });
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <Toolbar>
                    <Typography variant="h5">
                        Shared Links
                    </Typography>
                    <Button href={this.props.user.publicProfileUrl} target="blank" className={classes.viewButton}>(View public profile)</Button>
                </Toolbar>
                <Grid container direction="column" justify="center" alignItems="center">
                    {!this.state.links &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {this.state.links &&
                        <React.Fragment>
                            {this.state.links.length === 0 &&
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    <LinkIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                                    <br />
                                    You haven't shared any links yet. Add one below.
                                </Typography>
                            }
                            {this.state.links.map(link =>
                                <Card className={classes.cardContainer} key={link.id}>
                                    <CardContent>
                                        <Typography variant="h6">
                                            {link.text}
                                        </Typography>
                                        <Typography variant="body2">
                                            {link.url}
                                        </Typography>
                                    </CardContent>
                                    <CardActions >
                                        <Button href={link.url} target="blank" color="primary" size="small">
                                            Open
                                            </Button>
                                        <div style={{ marginLeft: "auto" }}>
                                            <Button color="secondary" size="small" onClick={() => this.setState({ selectedLinkToDelete: link, showDeleteLinkDialog: true })}>Delete</Button>
                                        </div>
                                    </CardActions>
                                </Card>
                            )}
                            <Fab variant="extended" className={classes.fab} color="primary" onClick={() => this.setState({ showAddLinkDialog: this.state.links!.length < 5, showTooManyLinksDialog: this.state.links!.length >= 5 })}>
                                <AddIcon className={classes.extendedFab} />
                                Add Link
                            </Fab>
                        </React.Fragment>
                    }
                </Grid>
                <Dialog
                    open={this.state.showDeleteLinkDialog}
                    onClose={() => this.setState({ showDeleteLinkDialog: false })}
                >
                    <DialogTitle>Delete shared link</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Typography align="center">Are you sure you wish to delete <strong>{this.state.selectedLinkToDelete !== undefined && this.state.selectedLinkToDelete.text}</strong>?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ showDeleteLinkDialog: false })} color="primary">
                            Cancel
                        </Button>
                        <Button color="secondary" onClick={() => this.deleteLink()}>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showAddLinkDialog}
                    onClose={() => this.setState({ showAddLinkDialog: false, addLinkUrl: '', addLinkText: '', errors: [] })}
                    aria-labelledby="form-dialog-title"
                >
                    <form onSubmit={(e) => this.onAddUrl(e)}>
                        <DialogContent className={classes.dialogContent}>
                            <Typography variant="h6">Share a new link</Typography>
                            <TextField margin="dense" type="text" InputLabelProps={{ shrink: true }} label="Link Name" fullWidth required error={FieldValidationError.isFieldInError(this.state.errors, 'Text')} onChange={(e) => this.setState({ addLinkText: e.target.value })} helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Text') }} />} />
                            <TextField margin="dense" type="text" InputLabelProps={{ shrink: true }} label="Link Url" onChange={(e) => this.setState({ addLinkUrl: e.target.value })} placeholder="https://www.example.com" fullWidth required error={FieldValidationError.isFieldInError(this.state.errors, 'Url')} helperText={<span dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(this.state.errors, 'Url') }} />} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ showAddLinkDialog: false, addLinkUrl: '', addLinkText: '', errors: [] })} color="default">
                                Cancel
                            </Button>
                            <Button color="primary" type="submit" disabled={!this.state.enableSubmit}>
                                Add Link
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.showTooManyLinksDialog}
                    onClose={() => this.setState({ showTooManyLinksDialog: false })}
                >
                    <DialogTitle>Unable to add link</DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <Typography align="center">We're sorry, you can only share 5 links.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={() => this.setState({ showTooManyLinksDialog: false })}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(SharedLinks));