import { WebClient, ServerResponse,  ServerError, ServerModelValidationResponse } from "./ServiceHelper";
import { VideoMedia, VideoMediaType, MediaStatus } from "../entities/VideoMedia";

interface ServerVideoMedia {
    id: number;
    type: string;
    title: string;
    summary: string;
    language: string;
    isEnabled: boolean;
    needsAttention: boolean;
    dateAvailable?: string;
    listPrice?: number;
    discountedPrice?: number;
    previewImageUrl?: string;
    thumbnailImageUrl?: string;
    posterImageUrl?: string;
    url: string;
    scriptureReferences: string[];
    genres: string[];
    audiences: string[];
    styles: string[];
    topics: string[];
    status: MediaStatus;
    parentId?: number;
    adminComment?: string;
    producerComment?: string
}

interface ServerPendingVideoMedia {
    id: number;
    type: string;
    title: string;
    summary: string;
    language: string;
    dateAvailable?: string;
    listPrice?: number;
    discountedPrice?: number;
    previewImageUrl?: string;
    thumbnailImageUrl?: string;
    posterImageUrl?: string;
    scriptureReferences: string[];
    genres: string[];
    audiences: string[];
    styles: string[];
    topics: string[];
}

export interface VideoDownload {
    id: number;
    extension: string;
    quality: string;
}


export interface SaveVideoMediaRequest {
    id?: number;
    parentId?: number;
    type: string;
    dateAvailable: Date;
    title: string;
    summary: string;
    language: string;
    topics: string[];
    scriptureReferences: string[];
    serviceStyles: string[];
    audiences: string[];
    serviceGenres: string[];
    listPrice?: number;
    discountedPrice?: number;
    status: MediaStatus;
    producerComment?: string;
    adminComment?: string
}

export interface SaveRejectedVideoMediaRequest {
    id: number;
    parentId?: number;
    type: string;
    dateAvailable: Date;
    title: string;
    summary: string;
    language: string;
    topics: string[];
    scriptureReferences: string[];
    serviceStyles: string[];
    audiences: string[];
    serviceGenres: string[];
    listPrice?: number;
    discountedPrice?: number;
    status: MediaStatus;
    producerComment?: string;
    adminComment?: string
}

export interface SaveApprovedVideoMediaRequest {
    id?: number;
    type: string;
    dateAvailable: Date;
    title: string;
    summary: string;
    language: string;
    topics: string[];
    scriptureReferences: string[];
    serviceStyles: string[];
    audiences: string[];
    serviceGenres: string[];
    listPrice?: number;
    discountedPrice?: number;
    status: MediaStatus;
    producerComment?: string;
    adminComment?: string
}

export interface SaveVideoMediaPreviewImageRequest {
    videoMediaId: number;
    base64ThumbnailImage: string;
    base64PosterImage: string;
}

export interface SaveMediaVideoAssetRequest {
    videoMediaId: number;
    previewVideoPath: string;
    downloadDetails: VideoMediaDetail[];
}

interface VideoMediaDetail {
    path: string;
    extension: string;
    quality: string;
    duration: number;
}

export interface CreateEncodingJobRequest {
    videoMediaId: number;
    videoMediaType: VideoMediaType;
    sourceFileName: string;
    sourceQuality: string;
}

export interface EncodingJobStatus {
    isComplete: boolean;
    error?: string;
    completionPercentage: number;
    preview?: EncodingOutputDetail;
    downloads?: EncodingOutputDetail[];
}

export interface EncodingOutputDetail {
    extension: string;
    path: string;
    quality: string;
    duration: number;
}

export class VideoMediaService {
    static async getVideoMedia(id: number) {
        let response = await WebClient.Get<ServerVideoMedia>("api/VideoMedia/GetVideoMedia/" + id);
        return VideoMediaService.translateSingleResponse(response);
    }

    static async getPendingVideoMedia(id: number) {
        let response = await WebClient.Get<ServerVideoMedia>("api/VideoMedia/GetPendingVideoMedia/" + id);
        return VideoMediaService.translateSingleResponse(response);
    }

    static async getVideoMediaAvailableVideoDownloads(videoMediaId: number) {
        let response = await WebClient.Get<VideoDownload[]>("api/VideoMedia/GetVideoMediaAvailableVideoDownloads/" + videoMediaId);
        return response;
    }

     static async getPendingVideoMediaAvailableVideoDownloads(videoMediaId: number) {
        let response = await WebClient.Get<VideoDownload[]>("api/VideoMedia/GetPendingVideoMediaAvailableVideoDownloads/" + videoMediaId);
        return response;
    }

    static async generateVideoUploadUrl(id: number, type: VideoMediaType, fileName: string) {
        let response = await WebClient.Get<{ url: string }>(`api/VideoMedia/GenerateVideoUploadUrl?id=${id}&type=${type}&fileName=${fileName}`);
        return response;
    }

    static async saveVideoMedia(request: SaveVideoMediaRequest) {
        let response = await WebClient.Put<ServerVideoMedia>("api/VideoMedia/SaveVideoMedia/", request);
        return VideoMediaService.translateSingleResponse(response);
    }

    static async enableVideoMedia(videoMediaId: number) {
        let response = await WebClient.Put<ServerVideoMedia>("api/VideoMedia/EnableVideoMedia/" + videoMediaId, {});
        return VideoMediaService.translateSingleResponse(response);
    }

    static async disableVideoMedia(videoMediaId: number) {
        let response = await WebClient.Put<ServerVideoMedia>("api/VideoMedia/DisableVideoMedia/" + videoMediaId, {});
        return VideoMediaService.translateSingleResponse(response);
    }

    static async saveVideoMediaPreviewImage(request: SaveVideoMediaPreviewImageRequest) {
        let response = await WebClient.Put<ServerVideoMedia>("api/VideoMedia/SaveVideoMediaPreviewImage/", request);
        return VideoMediaService.translateSingleResponse(response);
    }

    static async saveVideoMediaVideoMedia(request: SaveMediaVideoAssetRequest) {
        let response = await WebClient.Put<ServerVideoMedia>("api/VideoMedia/SaveMediaVideoAsset/", request);
        return VideoMediaService.translateSingleResponse(response);
    }

    static async uploadSourceVideo(uploadUrl: string, file: File, progress: (percentage: number) => void) {
        let response = await WebClient.PutFile(uploadUrl, file, progress);
        return response;
    }

    static async createEncodingJob(request: CreateEncodingJobRequest) {
        let response = await WebClient.Put<{ jobId: string }>("api/VideoMedia/CreateEncodingJob/", request);
        return response;
    }

    static async getEncodingJobStatus(jobId: string) {
        let response = await WebClient.Get<EncodingJobStatus>(`api/VideoMedia/GetEncodingJobStatus/${jobId}`);
        return response;
    }

    static async createPendingItemFromApproved(request: SaveApprovedVideoMediaRequest) {
        let response = await WebClient.Post<ServerVideoMedia>("api/VideoMedia/CreatePendingMediaItemFromApproved", request);
        return VideoMediaService.translateSingleResponse(response);
    }

    static async changeRejectedToPending(request: SaveRejectedVideoMediaRequest) {
        let response = await WebClient.Post<ServerVideoMedia>("api/VideoMedia/CreatePendingMediaItemFromRejected", request);
        return VideoMediaService.translateSingleResponse(response);
    }

    private static translateSingleResponse(response: ServerError | ServerModelValidationResponse | ServerResponse<ServerVideoMedia>) {
        if (ServerResponse.isSuccess<ServerVideoMedia>(response)) {
            let videoMedia: VideoMedia = {
                ...response.data,
                dateAvailable: response.data.dateAvailable ? new Date(response.data.dateAvailable) : undefined,
                type: VideoMediaService.translateVideoMediaType(response.data.type)
            };
            let translatedResponse: ServerResponse<VideoMedia> = {
                message: response.message,
                data: videoMedia
            };
            return translatedResponse;
        }
        return response;
    }

    private static translateVideoMediaType(type: string) {
        switch (type) {
            case VideoMediaType.Countdown_Video: return VideoMediaType.Countdown_Video;
            case VideoMediaType.Motion_Background: return VideoMediaType.Motion_Background;
            case VideoMediaType.Video_Illustration: return VideoMediaType.Video_Illustration;
            default: return VideoMediaType.Video_Illustration;
        }
    }
}