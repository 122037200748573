import React from "react";

import { Button, makeStyles } from "@material-ui/core";
import { GlooIcon } from "./GlooIcon";

const useStyles = makeStyles((theme) => ({
    glooButton: {
        borderColor: '#41B768',
        borderRadius: 16,
        borderWidth: 2,
        color: '#41B768',
        fontFamily: 'poppins',
        fontSize: '1.1rem',
        minHeight: '50px',
        padding: '2px 0px',
        textTransform: 'none',
    },
    glooIcon: {
        transform: 'scale(3)',
        marginLeft: 28,
        marginRight: 24,
    },
    disabledLink: {
        pointerEvents: 'none',
        cursor: 'default',
    }
}));

interface Props {
    url: string;
    disabled?: boolean;
    fullWidth?: boolean;
    style?: React.CSSProperties;
}

export function GlooButton(props: Props) {
    const classes = useStyles();

    return (
        <a href={props.url} className={props.disabled ? classes.disabledLink : undefined}>
            <Button variant="outlined" size="large" type="button" disabled={props.disabled} fullWidth={props.fullWidth} className={classes.glooButton} style={props.style}>
                Connect with
                <GlooIcon className={classes.glooIcon} style={{filter: props.disabled ? 'grayscale(100%) contrast(250%)' : ''}} />
            </Button>
        </a>
    );
}