import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import * as React from 'react';
import DiscussionNotification from '../../entities/DiscussionNotification';
import { MiscService } from '../../services/MiscService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';

const styles = (theme: Theme) =>
    createStyles({
        root: {
        },
        paper: {
            ...theme.mixins.gutters(),
            paddingTop: theme.spacing() * 2,
            paddingBottom: theme.spacing() * 2,
            marginBottom: theme.spacing() * 2
        },
        divider: {
            marginTop: theme.spacing(),
            marginBottom: theme.spacing(),
        },
        image: {
            width: '100%',
            maxWidth: '50%',
            margin: '0 auto',
            display: 'block'
        },
        date: {
            opacity: 0.7,
            marginBottom: theme.spacing()
        },
        cardContainer: {
            maxWidth: '500px',
            marginBottom: "16px"
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardType: {
            float: "right"
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
}

interface State {
    discussionNotifications?: DiscussionNotification[];
    deleteDialogOpen: boolean;
    selectedId?: number;
    selectedType?: string;
    errors: FieldValidationError[];
    enableSubmit: boolean;
    showUpdateSuccessMessage: boolean;
}

class DiscussionNotifications extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            deleteDialogOpen: false,
            errors: [],
            enableSubmit: true,
            showUpdateSuccessMessage: false
        };
    }

    async componentDidMount() {

        document.title = "SermonCentral Account - Discussion Notifications";

        try {
            const discussionNotifications = await MiscService.getDiscussionNotifications();

            if (discussionNotifications) {
                this.setState({ discussionNotifications });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async unsubscribeFromDiscussionNotification() {

        if (!this.state.enableSubmit) {
            return;
        }

        if (this.state.selectedType && this.state.selectedId && this.state.discussionNotifications) {
            try {
                this.setState({ enableSubmit: false });
                const response = await MiscService.unsubscribeFromDiscussionNotification(this.state.selectedId, this.state.selectedType);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let discussionNotifications = this.state.discussionNotifications;
                    discussionNotifications = discussionNotifications.filter(d => d.id !== this.state.selectedId);
                    this.setState({
                        discussionNotifications,
                        errors: [],
                        enableSubmit: true,
                        selectedId: undefined,
                        selectedType: undefined,
                        deleteDialogOpen: false,
                        showUpdateSuccessMessage: true
                    });
                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError], enableSubmit: true });
            }
        }
    }

    render() {
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <div className={classes.paper}>
                    <Typography variant="h5" component="h2">
                        Discussions Notifications
                    </Typography>
                    {!this.state.discussionNotifications &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {this.state.discussionNotifications && this.state.discussionNotifications.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <NotificationsOffIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You are not subscribed to any discussions yet.
                        </Typography>
                    }
                    {this.state.discussionNotifications && this.state.discussionNotifications.length > 0 &&
                        <Grid container direction="column" justify="center" alignItems="center">
                            {this.state.discussionNotifications.map(subscription =>
                                <Card className={classes.cardContainer} key={subscription.id}>
                                    <CardContent>
                                        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                            {new Date(subscription.dateSubscribed).toDateString()}
                                        </Typography>
                                        <Typography className={classes.cardType} variant="caption" color="textSecondary">
                                            {subscription.type}
                                        </Typography>
                                        <Typography variant="h5" component="h2">
                                            {subscription.title}
                                        </Typography>
                                        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: subscription.introText }} />
                                    </CardContent>
                                    <CardActions>
                                        <Button color="secondary" onClick={e => this.setState({ deleteDialogOpen: true, selectedId: subscription.id, selectedType: subscription.type })}>
                                            Unsubscribe
                                                </Button>
                                        <div style={{ marginLeft: "auto" }}>
                                            <Button href={subscription.url} target="blank" color="primary">
                                                View
                                                    </Button>
                                        </div>
                                    </CardActions>
                                </Card>
                            )}
                        </Grid>
                    }
                </div>
                <Dialog
                    open={this.state.deleteDialogOpen}
                    onClose={e => this.setState({ deleteDialogOpen: false })}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">Unsubscribe?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="delete-dialog-description">
                            Are you sure you wish to unsubscribe from discussion notifications for this {this.state.selectedType}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.setState({ deleteDialogOpen: false })} disabled={!this.state.enableSubmit} color="default">
                            No
                        </Button>
                        <Button onClick={() => this.unsubscribeFromDiscussionNotification()} color="primary" disabled={!this.state.enableSubmit}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <SuccessIcon className={classes.successSnackbarContentIcon} />
                                <span>Your profile has been updated</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
            </div >
        );
    }
}

export default withRoot(withStyles(styles)(DiscussionNotifications));