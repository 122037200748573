import axios, { AxiosError } from 'axios';

export interface ServerError {
    message: string;
    statusCode: number;
}

export class ServerError {
    public static isServerError(model: any): model is ServerError {
        return model.message !== undefined && model.statusCode !== undefined;
    }
}

export interface ServerResponse<T> {
    data: T;
    message: string;
}

export class ServerResponse<T> {
    public static isServerResponse<T>(model: any): model is ServerResponse<T> {
        return model.data !== undefined && model.message !== undefined;
    }
    static isSuccess<T>(model: ServerResponse<any> | ServerError | ServerModelValidationResponse): model is ServerResponse<T> {
        let object: any = model;
        return object.data !== undefined && object.message !== undefined;
    }
    static isError(model: ServerResponse<any> | ServerError | ServerModelValidationResponse): model is ServerError {
        let object: any = model;
        return object.message !== undefined && object.statusCode !== undefined;
    }
    static isModelValidation(model: ServerResponse<any> | ServerError | ServerModelValidationResponse): model is ServerModelValidationResponse {
        let object: any = model;
        return object.valid !== undefined && object.errors !== undefined;
    }
}

export interface ServerModelValidationResponse {
    valid: boolean;
    errors: FieldValidationError[];
}

export class ServerModelValidationResponse {
    public static isServerModelValidationResponse(model: any): model is ServerModelValidationResponse {
        return model.valid !== undefined && model.errors !== undefined;
    }
}

export interface FieldValidationError {
    field: string;
    errors: string[];
}

export class FieldValidationError {
    public static isFieldInError(errors: FieldValidationError[], fieldName: string): boolean {
        return errors.map(error => error.field).indexOf(fieldName) > -1;
    }
    public static getFieldErrorSummary(errors: FieldValidationError[], fieldName: string): string {
        var retVal = '';
        errors.filter(error => error.field === fieldName).forEach(error => {
            error.errors.forEach(str => retVal += str + ' ');
        });
        return retVal;
    }
    public static hasGenericError(errors: FieldValidationError[]): boolean {

        return errors.map(error => error.field).indexOf('') > -1;
    }
    public static getGenericErrorSummary(errors: FieldValidationError[]): string {
        var retVal = '';
        errors.filter(error => error.field === '').forEach(error => {
            error.errors.forEach(str => retVal += str + ' ');
        });
        return retVal;
    }
}

const serializeAxiosError = (errorResponse: AxiosError) => {
    let serverError: ServerError;
    let response = errorResponse.response;

    // Happens if the http request gets killed mid request
    if (!response) {
        serverError = { message: "An error occurred while processing your request. Please try again.", statusCode: 500 };
    }
    else if (response.status === 404) {
        serverError = { message: "An error occurred while processing your request. Please try again.", statusCode: response.status };
    }
    // Redirect to sign in page
    else if (response.status === 401) {
        let path = window.location.pathname;
        let redirect = "?returnUrl=" + encodeURIComponent(path);
        window.location.replace("/sign-in" + redirect);
        let standardServerResponse: ServerResponse<string> = response.data;
        serverError = { message: standardServerResponse.message, statusCode: response.status };
    }
    else {
        let standardServerResponse: ServerResponse<string> = response.data;
        serverError = { message: standardServerResponse.message, statusCode: response.status };
    }

    return serverError;
};

const uploadProgressPercent = (progress: ProgressEvent) => {
    return Math.round((progress.loaded * 100) / progress.total);
};


export class WebClient {
    public static async Get<T>(url: string) {
        try {
            const response = await axios.get(url, { headers: { Pragma: 'no-cache' } });
            return response.data as (ServerResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError((errorResult));
        }
    }

    public static async Post<T>(url: string, data: any) {
        try {
            const response = await axios(url, {
                method: "post",
                data: data,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as (ServerResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

    public static async Put<T>(url: string, data: any) {
        try {
            const response = await axios(url, {
                method: "put",
                data: data,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as (ServerResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

    public static async Delete<T>(url: string, data: any) {
        try {
            const response = await axios(url, {
                method: "delete",
                data: data,
                headers: { Pragma: 'no-cache' }
            });
            return response.data as (ServerResponse<T> | ServerModelValidationResponse);
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

    public static async PutFile(url: string, file: File, onUploadProgress?: (percent: number) => void) {
        try {
            await axios.put(url, file, {
                onUploadProgress: onUploadProgress ? p => onUploadProgress(uploadProgressPercent(p)) : undefined,
                headers: { Pragma: 'no-cache' }
            });
            let response: ServerResponse<boolean> = {
                data: true,
                message: ""
            };
            return response;
        }
        catch (errorResult) {
            return serializeAxiosError(errorResult);
        }
    }

}
