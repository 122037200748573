import AppConfig from "../entities/AppConfig";
import { ServerResponse, WebClient } from "../services/ServiceHelper";

export class AppConfigService {

    public static async getAppConfig() {

        var response = await WebClient.Get<AppConfig>("api/AppConfig");
        if (ServerResponse.isServerResponse<AppConfig>(response)) {
            return response.data;
        } else {
            return null;
        }
    }
}
