export interface MediaItem {
    id: number;
    title: string;
    summary: string;
    type: MediaType;
    isEnabled: boolean;
    needsAttention: boolean;
    dateAvailable?: Date;
    previewImageUrl: string;
    url: string;
    mediaProducerId: number;
    mediaProducer: string;
    scriptureReferences: string[];
    genres: string[];
    styles: string[];
    topics: string[];
    status: MediaItemStatus;
    parentId?: number;
} 

export enum MediaType {
    Still_Background = "Still Background",
    Countdown_Video = "Countdown Video",
    Video_Illustration = "Video Illustration",
    Motion_Background = "Motion Background",
    Preaching_Slide = "Preaching Slide",
    Worship_Lyric_Slide = "Worship Lyric Slide",
    Comedy = "Comedy",
    Inspirational = "Inspirational",
    Small_Group_Study = "Small Group Study",
    Social_Graphic = "Social Graphic"
}

export enum MediaItemStatus {
    Pending = 0,
    Rejected,
    Approved
}