import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import FilterListIcon from '@material-ui/icons/FilterList';
import PaymentIcon from '@material-ui/icons/Payment';
import SearchIcon from '@material-ui/icons/Search';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { RouteComponentProps } from 'react-router-dom';
import 'url-search-params-polyfill';
import { MediaItemHistory, MediaService } from '../../services/MediaService';
import { FieldValidationError } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Divider from '@material-ui/core/Divider';

const styles = (theme: Theme) =>
    createStyles({
        root: {

        },
        header: {

        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing() * 5,
            },
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing() * 5,
            },
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        cardStatus: {
            float: "right"
        },
        pos: {
            marginBottom: 12,
        },
        sortButton: {
            paddingRight: "6px",
            fontSize: "13px",
            opacity: .86,
            whiteSpace: "nowrap"
        },
        menuItem: {
            minWidth: "200px",
        },
        sort: {
            display: "flex"
        },
        infiniteScroll: {
            width: 'auto',
        },
        cardContainer: {
            maxWidth: "500px",
            marginBottom: "16px",
            [theme.breakpoints.up('md')]: {
                minWidth: '500px',
            },
        },
        cardImage: {
            height: 250,
            backgroundSize: "contain"
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            toolbar: {
                display: 'block',
                padding: 0
            },
            title: {
                float: 'left'
            },
            sort: {
                display: 'block',
                float: 'right'
            },
            filterContainer: {
                float: 'right'
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

enum FilterValue {
    PreachingSlide = "Preaching Slide",
    WorshipLyricSlide = "Worship Lyric Slide",
    MotionBackground = "Motion Background",
    VideoIllustration = "Video Illustration",
    CountdownVideo = "Countdown Video",
    StillBackground = "Still Background",
    SermonSeriesKit = "Sermon Series Kit",
    PremiumSermonKit = "Premium Sermon Kit",
    SocialGraphic = "Social Graphic",
    Comedy = "Comedy",
    Inspirational = "Inspirational",
    SmallGroupStudy = "Small Group Study",
    ChurchMediaSet = "Church Media Set",
    All = ""
}

interface Props extends RouteComponentProps<null>, WithStyles<typeof styles> {

}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    mediaItems: MediaItemHistory[];
    filteredMediaItems: MediaItemHistory[];
    visibleMediaItemCount: number;
    queryText: string;
    sortOrder: string;
    orderBy: string;
    anchorSort: undefined;
    anchorFilter: undefined;
    filterValue: FilterValue;
    errors: FieldValidationError[];
}

class Purchases extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: true,
            mediaItems: [],
            filteredMediaItems: [],
            visibleMediaItemCount: 10,
            queryText: '',
            sortOrder: 'Descending',
            orderBy: 'Date',
            anchorSort: undefined,
            anchorFilter: undefined,
            filterValue: FilterValue.All,
            errors: []
        };
    }

    async componentDidMount() {
        document.title = "SermonCentral Account - Purchase History";

        try {
            const mediaItems = await MediaService.getPurchaseHistory();

            let filterValue = FilterValue.All;
            const params = new URLSearchParams(this.props.location.search);
            if (params.get('type') && params.get('type')!.match(/backgroundimages/i)) {
                filterValue = FilterValue.StillBackground;
            }
            else if (params.get('type') && params.get('type')!.match(/countdownvideos/i)) {
                filterValue = FilterValue.CountdownVideo;
            }
            else if (params.get('type') && params.get('type')!.match(/motionbackgrounds/i)) {
                filterValue = FilterValue.MotionBackground;
            }
            else if (params.get('type') && params.get('type')!.match(/preachingslides/i)) {
                filterValue = FilterValue.PreachingSlide;
            }
            else if (params.get('type') && params.get('type')!.match(/worshiplyricsldies/i)) {
                filterValue = FilterValue.WorshipLyricSlide;
            }
            else if (params.get('type') && params.get('type')!.match(/sermonserieskits/i)) {
                filterValue = FilterValue.SermonSeriesKit;
            }
            else if (params.get('type') && params.get('type')!.match(/videoillustrations/i)) {
                filterValue = FilterValue.VideoIllustration;
            }
            else if (params.get('type') && params.get('type')!.match(/socialgraphics/i)) {
                filterValue = FilterValue.SocialGraphic;
            }
            else if (params.get('type') && params.get('type')!.match(/comedy/i)) {
                filterValue = FilterValue.Comedy;
            }
            else if (params.get('type') && params.get('type')!.match(/inspirational/i)) {
                filterValue = FilterValue.Inspirational;
            }
            else if (params.get('type') && params.get('type')!.match(/smallgroupstudy/i)) {
                filterValue = FilterValue.SmallGroupStudy;
            }
            else if (params.get('type') && params.get('type')!.match(/churchmediaset/i)) {
                filterValue = FilterValue.ChurchMediaSet;
            }


            if (mediaItems) {
                this.setState({ mediaItems, filterValue, loading: false }, this.updateFilteredMediaItems);
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    highlightText(text: string, match: string) {

        if (!text) {
            return '';
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    sortColumn(orderBy: string) {

        var sortOrder = this.state.sortOrder === 'Ascending' ? 'Descending' : 'Ascending';
        if (orderBy !== this.state.orderBy) {
            sortOrder = 'Descending';
        }

        this.setState({ anchorSort: undefined, orderBy, sortOrder }, this.updateFilteredMediaItems);
    }

    updateFilteredMediaItems() {

        let filteredMediaItems = this.state.mediaItems;

        if (this.state.queryText.length >= 0) {
            const query = this.state.queryText.toLowerCase();
            filteredMediaItems = filteredMediaItems.filter(s => {

                if (s.title.toLowerCase().includes(query)) {
                    return true;
                }

                if (s.summary.toLowerCase().includes(query)) {
                    return true;
                }

                return false;
            });
        }

        if (this.state.filterValue !== "") {
            filteredMediaItems = filteredMediaItems.filter(s => s.type === this.state.filterValue);
        }

        const sortGreaterThanValue = this.state.sortOrder === 'Ascending' ? 1 : -1;
        const sortLessThanValue = this.state.sortOrder === 'Ascending' ? -1 : 1;
        const fieldName = this.state.orderBy.toLowerCase();

        filteredMediaItems.sort((s1, s2) => {

            let s1FieldValue = s1[fieldName];
            if (typeof s1FieldValue === 'string') {
                s1FieldValue = s1FieldValue.toLowerCase();
            }

            let s2FieldValue = s2[fieldName];
            if (typeof s2FieldValue === 'string') {
                s2FieldValue = s2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof s1FieldValue === 'string' && typeof s2FieldValue === 'string') {
                const value = s1FieldValue.localeCompare(s2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (s1FieldValue > s2FieldValue) {
                return sortGreaterThanValue;
            }
            if (s1FieldValue < s2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        this.setState({ filteredMediaItems });
    }

    render() {

        const classes = this.props.classes;
        const visibleMediaItems = this.state.filteredMediaItems.slice(0, this.state.visibleMediaItemCount);

        return (
            <div className={classes.root}>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            Purchase History
                        </Typography>
                        {!this.state.loading && this.state.mediaItems.length > 0 && this.state.mediaItems.length === this.state.filteredMediaItems.length &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.mediaItems.length} purchase{this.state.mediaItems.length === 1 ? '' : 's'}
                            </Typography>
                        }
                        {!this.state.loading && this.state.mediaItems.length > 0 && this.state.mediaItems.length !== this.state.filteredMediaItems.length &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.filteredMediaItems.length} match{this.state.filteredMediaItems.length === 1 ? '' : 'es'} of {this.state.mediaItems.length} purchase{this.state.mediaItems.length === 1 ? '' : 's'}
                            </Typography>
                        }
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={this.state.anchorSort ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorSort: e.currentTarget })}>
                            {this.state.orderBy === 'Title' && 'Title'}
                            {this.state.orderBy === 'Date' && 'Date Purchased'}
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={this.state.anchorSort}
                            open={Boolean(this.state.anchorSort)}
                            onClose={(e: any) => this.setState({ anchorSort: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Title')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Title' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Title'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Date')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Date' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Date Purchased'} />
                            </MenuItem>

                        </Menu>

                        <IconButton aria-label="Reverse Direction" onClick={e => this.sortColumn(this.state.orderBy)}>
                            {this.state.sortOrder === 'Descending' ? <ArrowDownwardIcon style={{ fontSize: "14px" }} /> : <ArrowUpwardIcon style={{ fontSize: "14px" }} />}
                        </IconButton>
                    </div>
                    <div className={classes.filterContainer}>
                        <IconButton aria-label="Filter Media Types" aria-haspopup="true" aria-owns={this.state.anchorFilter ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorFilter: e.currentTarget })}>
                            <FilterListIcon style={{ fontSize: "14px" }} />
                        </IconButton>
                        <Menu
                            id="filter-menu"
                            anchorEl={this.state.anchorFilter}
                            open={Boolean(this.state.anchorFilter)}
                            onClose={(e: any) => this.setState({ anchorFilter: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.All }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.All ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="All Media Types" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.ChurchMediaSet }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.ChurchMediaSet ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Church Media Sets" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.CountdownVideo }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.CountdownVideo ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Countdown Video" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.StillBackground }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.StillBackground ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Still Backgrounds" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.MotionBackground }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.MotionBackground ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Motion Backgrounds" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.PreachingSlide }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.PreachingSlide ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Preaching Slides" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.WorshipLyricSlide }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.WorshipLyricSlide ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Worship Lyric Slide" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.SermonSeriesKit }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.SermonSeriesKit ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Sermon Series Kit" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.SermonSeriesKit }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.PremiumSermonKit ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Premium Sermon Kit" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.VideoIllustration }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.VideoIllustration ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Video Illustration" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.SocialGraphic }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.SocialGraphic ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Social Graphics" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.Comedy }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.Comedy ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Comedy Video" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.Inspirational }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.Inspirational ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Inspirational Video" />
                            </MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.setState({ anchorFilter: undefined, filterValue: FilterValue.SmallGroupStudy }, this.updateFilteredMediaItems)}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.filterValue === FilterValue.SmallGroupStudy ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary="Small Group Study Video" />
                            </MenuItem>
                        </Menu>
                    </div>
                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={this.state.queryText}
                            onChange={(e) => this.setState({ queryText: e.target.value, visibleMediaItemCount: 10 }, this.updateFilteredMediaItems)}
                        />
                    </div>
                </Toolbar>

                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading && this.state.mediaItems.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <PaymentIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You haven't purchased any media.
                        </Typography>
                    }
                    {!this.state.loading && this.state.mediaItems.length > 0 && visibleMediaItems.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <SearchIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            No matches found.
                        </Typography>
                    }
                    {!this.state.loading && visibleMediaItems.length > 0 &&
                        <InfiniteScroll
                            className={classes.infiniteScroll}
                            pageStart={0}
                            threshold={0}
                            loadMore={() => this.setState({ visibleMediaItemCount: this.state.visibleMediaItemCount + 10 })}
                            hasMore={visibleMediaItems.length < this.state.filteredMediaItems.length}
                            useWindow={false}
                            getScrollParent={() => document.getElementById('main')}
                            loader={<div key="loader" style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading...
                                </Typography>
                            </div>}
                        >
                            {visibleMediaItems.map(mediaItem =>
                                <Card className={classes.cardContainer} key={mediaItem.id}>
                                    <CardMedia
                                        className={classes.cardImage}
                                        image={mediaItem.imageUrl}
                                        title={mediaItem.title}
                                    />
                                    <CardContent>
                                        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                            {new Date(mediaItem.date).toLocaleDateString()}
                                        </Typography>
                                        <Typography className={classes.cardStatus} variant="caption" color="textSecondary">
                                            {mediaItem.type}
                                        </Typography>
                                        <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: this.highlightText(mediaItem.title, this.state.queryText) }} />
                                        <Typography component="p" dangerouslySetInnerHTML={{ __html: this.highlightText(mediaItem.summary, this.state.queryText) }} />
                                    </CardContent>
                                    <CardActions>
                                        {mediaItem.type !== "Church Media Set" &&
                                            <>
                                                {(mediaItem.shareLink && mediaItem.shareableUrl && mediaItem.shareableUrlName) &&
                                                    <Button color="primary" href={mediaItem.shareableUrl} target='_blank'>
                                                        {mediaItem.shareableUrlName}
                                                    </Button>
                                                }
                                            </>
                                        }
                                        {mediaItem.url && mediaItem.url.indexOf('/') >= 0 &&
                                            <div style={{ marginLeft: "auto" }}>
                                                <Button color="primary" href={mediaItem.url} target='_blank'>
                                                    View/Download
                                                </Button>
                                            </div>
                                        }
                                    </CardActions>
                                    <Divider />
                                    {mediaItem.shareLink &&
                                        <CardContent>
                                            <Typography component="caption" align="left" color="textSecondary" noWrap display="inline"><strong>Share link: </strong><Button color="primary" variant="text" href={mediaItem.shareLink} target="_blank">{mediaItem.shareLink}</Button></Typography>
                                            <Typography component="caption" align="left" color="textSecondary" display="inline"><strong>Views: {mediaItem.views}</strong></Typography>
                                        </CardContent>
                                    }
                                </Card>
                            )}
                        </InfiniteScroll>
                    }
                </Grid>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(Purchases));