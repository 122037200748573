import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import SermonSeries from '../../entities/SermonSeries';
import { ContributionsService } from '../../services/ContributionsService';
import { FieldValidationError, ServerModelValidationResponse, ServerResponse } from '../../services/ServiceHelper';
import withRoot from '../../withRoot';
import Fab from '@material-ui/core/Fab';

const styles = (theme: Theme) =>
    createStyles({
        root: {

        },
        header: {

        },
        title: {
            flex: '0 0 auto',
        },
        spacer: {
            flex: '1 1 100%',
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.primary.light, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.light, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(),
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing() * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                width: theme.spacing() * 5,
            },
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing(),
            paddingRight: theme.spacing(),
            paddingBottom: theme.spacing(),
            paddingLeft: theme.spacing() * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing() * 5,
            },
        },
        cardTitle: {
            fontSize: 14,
            display: "inline-block"
        },
        pos: {
            marginBottom: 12,
        },
        sortButton: {
            paddingRight: "6px",
            fontSize: "13px",
            opacity: .86,
            whiteSpace: "nowrap"
        },
        menuItem: {
            minWidth: "200px",
        },
        sort: {
            display: "flex"
        },
        infiniteScroll: {
            width: 'auto',
        },
        cardContainer: {
            width: "500px",
            marginBottom: "16px"
        },
        ratingStarContainer: {
            verticalAlign: "text-top",
            marginLeft: "4px"
        },
        ratingStar: {
            fontSize: "16px"
        },
        fab: {
            position: 'absolute',
            bottom: theme.spacing() * 4,
            right: theme.spacing() * 4,
            [theme.breakpoints.down('md')]: {
                bottom: theme.spacing() * 2,
                right: theme.spacing() * 2,
            },
        },
        extendedFab: {
            marginRight: theme.spacing()
        },
        errorSnackbarContent: {
            flexWrap: 'nowrap',
            backgroundColor: theme.palette.error.dark,
        },
        errorSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        errorSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        successSnackbarContent: {
            backgroundColor: green[600],
        },
        successSnackbarContentIcon: {
            fontSize: 20,
            opacity: 0.9,
            marginRight: theme.spacing(),
        },
        successSnackbarMessage: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
        },
        close: {
            padding: theme.spacing() / 2,
        },
        tagsAndScriptures: {
            fontStyle: 'italic',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        [theme.breakpoints.down('sm')]: {
            infiniteScroll: {
                width: '100%',
            },
            cardContainer: {
                width: "100%",
            },
            toolbar: {
                display: 'block',
                padding: 0
            },
            title: {
                float: 'left'
            },
            sort: {
                display: 'block',
                float: 'right'
            },
            filterContainer: {
                float: 'right'
            },
            search: {
                margin: '10px auto',
                maxWidth: '500px',
                clear: 'both'
            }
        },
        snackBarText: {
            overflow: 'hidden'
        }
    });

interface Props extends WithStyles<typeof styles> {
}

interface State {
    loading: boolean;
    enableSubmit: boolean;
    sermonSeries: SermonSeries[];
    filteredSermonSeries: SermonSeries[];
    visibleSermonSeriesCount: number;
    queryText: string;
    sortOrder: string;
    orderBy: string;
    anchorSort: undefined;
    showDeleteDialog: boolean;
    selectedSermonSeriesIdToDelete?: number;
    showUpdateSuccessMessage: boolean;
    updateSuccessMessage: string;
    errors: FieldValidationError[];
}

class SermonSeriesPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            enableSubmit: true,
            sermonSeries: [],
            filteredSermonSeries: [],
            visibleSermonSeriesCount: 10,
            queryText: '',
            sortOrder: 'Descending',
            orderBy: 'Date',
            anchorSort: undefined,
            showDeleteDialog: false,
            showUpdateSuccessMessage: false,
            updateSuccessMessage: '',
            errors: []
        };
    }

    async componentDidMount() {
        document.title = "SermonCentral Account - My Sermon Series";

        try {
            const sermonSeries = await ContributionsService.getAllSermonSeries();

            if (sermonSeries) {
                this.setState({ sermonSeries, filteredSermonSeries: sermonSeries, loading: false });
            }
            else {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }
        catch (errorResult) {
            const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
            this.setState({ errors: [serverError] });
        }
    }

    async deleteSermonSeries() {
        if (this.state.selectedSermonSeriesIdToDelete) {
            try {
                const response = await ContributionsService.deleteSermonSeries(this.state.selectedSermonSeriesIdToDelete);

                if (ServerResponse.isServerResponse<null>(response)) {

                    let sermonSeries = this.state.sermonSeries;
                    sermonSeries = sermonSeries.filter(l => l.id !== this.state.selectedSermonSeriesIdToDelete!);

                    this.setState({
                        sermonSeries,
                        selectedSermonSeriesIdToDelete: undefined,
                        enableSubmit: true,
                        showDeleteDialog: false,
                        showUpdateSuccessMessage: true,
                        updateSuccessMessage: 'The sermon series was deleted'
                    }, this.updateFilteredSermonSeries);

                }
                else if (ServerModelValidationResponse.isServerModelValidationResponse(response)) {
                    if (response.valid) {
                        const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                        this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                    }
                    else {
                        this.setState({ errors: response.errors, enableSubmit: true, showDeleteDialog: false });
                    }
                }
                else {
                    const serverError: FieldValidationError = { field: "", errors: [response.message] };
                    this.setState({ errors: [serverError], enableSubmit: true, showDeleteDialog: false });
                }
            }
            catch (errorResult) {
                const serverError: FieldValidationError = { field: "", errors: ['An unknown error occurred. Please try again.'] };
                this.setState({ errors: [serverError] });
            }
        }

    }

    highlightText(text: string, match: string) {

        if (!text) {
            return '';
        }

        match = match.trim().replace(/ /gi, '|');
        const regexp = new RegExp('(' + match + ')', 'gi');
        return text.replace(regexp, '<span style="background-color: #ffff00a8">$1</span>');
    }

    sortColumn(orderBy: string) {

        var sortOrder = this.state.sortOrder === 'Ascending' ? 'Descending' : 'Ascending';
        if (orderBy !== this.state.orderBy) {
            sortOrder = 'Descending';
        }

        this.setState({ anchorSort: undefined, orderBy, sortOrder }, this.updateFilteredSermonSeries);
    }

    updateFilteredSermonSeries() {

        let filteredSermonSeries = this.state.sermonSeries;

        if (this.state.queryText.length >= 0) {
            const query = this.state.queryText.toLowerCase();
            filteredSermonSeries = filteredSermonSeries.filter(s => {

                if (s.title && s.title.toLowerCase().includes(query)) {
                    return true;
                }

                if (s.intro && s.intro.toLowerCase().includes(query)) {
                    return true;
                }

                if (s.scriptures && s.scriptures.some(sr => sr.toLowerCase().includes(query))) {
                    return true;
                }

                if (s.tags && s.tags.some(tg => tg.toLowerCase().includes(query))) {
                    return true;
                }

                return false;
            });
        }

        const sortGreaterThanValue = this.state.sortOrder === 'Ascending' ? 1 : -1;
        const sortLessThanValue = this.state.sortOrder === 'Ascending' ? -1 : 1;
        const fieldName = this.state.orderBy.toLowerCase();

        filteredSermonSeries.sort((s1, s2) => {

            let s1FieldValue = s1[fieldName];
            if (typeof s1FieldValue === 'string') {
                s1FieldValue = s1FieldValue.toLowerCase();
            }

            let s2FieldValue = s2[fieldName];
            if (typeof s2FieldValue === 'string') {
                s2FieldValue = s2FieldValue.toLowerCase();
            }

            if ('localeCompare' in String.prototype && typeof s1FieldValue === 'string' && typeof s2FieldValue === 'string') {
                const value = s1FieldValue.localeCompare(s2FieldValue);
                if (value > 0) {
                    return sortGreaterThanValue;
                }

                if (value < 0) {
                    return sortLessThanValue;
                }

                return 0;
            }

            if (s1FieldValue > s2FieldValue) {
                return sortGreaterThanValue;
            }
            if (s1FieldValue < s2FieldValue) {
                return sortLessThanValue;
            }
            return 0;
        });

        this.setState({ filteredSermonSeries });
    }

    renderStars = (rating: number) => {
        let val: JSX.Element[] = [];

        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                val.push(<StarIcon key={i} className={this.props.classes.ratingStar} />);
            } else if (i <= Math.ceil(rating)) {
                val.push(<StarHalfIcon key={i} className={this.props.classes.ratingStar} />);
            }
        }

        return val;
    }

    render() {

        const classes = this.props.classes;
        const visibleSermonSeries = this.state.filteredSermonSeries.slice(0, this.state.visibleSermonSeriesCount);

        return (
            <div className={classes.root}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={FieldValidationError.hasGenericError(this.state.errors)}
                >
                    <SnackbarContent
                        className={classes.errorSnackbarContent}
                        aria-describedby="client-snackbar"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                                <ErrorIcon className={classes.errorSnackbarContentIcon} />
                                <span dangerouslySetInnerHTML={{ __html: FieldValidationError.getGenericErrorSummary(this.state.errors) }} />
                            </span>}
                    />
                </Snackbar>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.showUpdateSuccessMessage}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ showUpdateSuccessMessage: false })}
                >
                    <SnackbarContent
                        className={classes.successSnackbarContent}
                        aria-describedby="client-snackbar1"
                        classes={{
                            message: classes.snackBarText
                        }}
                        message={
                            <span id="client-snackbar1" className={classes.successSnackbarMessage}>
                                <CheckCircleIcon className={classes.successSnackbarContentIcon} />
                                <span>{this.state.updateSuccessMessage}</span>
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={classes.close}
                                onClick={() => this.setState({ showUpdateSuccessMessage: false })}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </Snackbar>

                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}>
                        <Typography variant="h5" component="h2">
                            My Sermon Series
                        </Typography>
                        {!this.state.loading && this.state.sermonSeries.length > 0 && this.state.sermonSeries.length === this.state.filteredSermonSeries.length &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.sermonSeries.length} shared sermon series
                            </Typography>
                        }
                        {!this.state.loading && this.state.sermonSeries.length > 0 && this.state.sermonSeries.length !== this.state.filteredSermonSeries.length &&
                            <Typography variant="body2" component="p" color="textSecondary" style={{ fontStyle: 'italic', marginBottom: '5px' }}>
                                {this.state.filteredSermonSeries.length} match{this.state.filteredSermonSeries.length === 1 ? '' : 'es'} of {this.state.sermonSeries.length} shared sermon series
                            </Typography>
                        }
                    </div>
                    <div className={classes.spacer} />
                    <div className={classes.sort}>
                        <Button className={classes.sortButton} aria-haspopup="true" aria-owns={this.state.anchorSort ? 'menu' : undefined} onClick={(e: any) => this.setState({ anchorSort: e.currentTarget })}>
                            {this.state.orderBy === 'Title' && 'Title'}
                            {this.state.orderBy === 'Date' && 'Date Created'}
                            {this.state.orderBy === 'Rating' && 'User Rating'}
                            {this.state.orderBy === 'Views' && 'Total Views'}
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={this.state.anchorSort}
                            open={Boolean(this.state.anchorSort)}
                            onClose={(e: any) => this.setState({ anchorSort: undefined })}
                            transformOrigin={{ vertical: "top", horizontal: "right" }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            getContentAnchorEl={null}
                        >
                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Title')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Title' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Title'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Date')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Date' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Date Created'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Rating')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Rating' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'User Rating'} />
                            </MenuItem>

                            <MenuItem className={classes.menuItem} onClick={() => this.sortColumn('Views')}>
                                <ListItemIcon className={classes.menuListItemIcon}>
                                    {this.state.orderBy === 'Views' ? <CheckIcon /> : <span />}
                                </ListItemIcon>
                                <ListItemText primary={'Total Views'} />
                            </MenuItem>

                        </Menu>

                        <IconButton aria-label="Reverse Direction" onClick={e => this.sortColumn(this.state.orderBy)}>
                            {this.state.sortOrder === 'Descending' ? <ArrowDownwardIcon style={{ fontSize: "14px" }} /> : <ArrowUpwardIcon style={{ fontSize: "14px" }} />}
                        </IconButton>
                    </div>

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            value={this.state.queryText}
                            onChange={(e) => this.setState({ queryText: e.target.value, visibleSermonSeriesCount: 10 }, this.updateFilteredSermonSeries)}
                        />
                    </div>
                </Toolbar>
                <Grid container direction="column" justify="center" alignItems="center">
                    {this.state.loading &&
                        <div style={{ textAlign: 'center' }}>
                            <CircularProgress style={{ marginTop: "50px" }} />
                            <br />
                            <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                Loading...
                            </Typography>
                        </div>
                    }
                    {!this.state.loading && this.state.sermonSeries.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <InsertCommentIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            You haven't shared any sermon series yet. Add one below.
                        </Typography>
                    }
                    {!this.state.loading && this.state.sermonSeries.length > 0 && visibleSermonSeries.length === 0 &&
                        <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                            <SearchIcon style={{ marginTop: "50px", width: "220px", height: "220px", color: "#CCC" }} />
                            <br />
                            No matches found.
                        </Typography>
                    }
                    {!this.state.loading && visibleSermonSeries.length > 0 &&
                        <InfiniteScroll
                            className={classes.infiniteScroll}
                            pageStart={0}
                            threshold={0}
                            loadMore={() => this.setState({ visibleSermonSeriesCount: this.state.visibleSermonSeriesCount + 10 })}
                            hasMore={visibleSermonSeries.length < this.state.filteredSermonSeries.length}
                            useWindow={false}
                            getScrollParent={() => document.getElementById('main')}
                            loader={<div key="loader" style={{ textAlign: 'center' }}>
                                <CircularProgress style={{ marginTop: "50px" }} />
                                <br />
                                <Typography variant="body1" color="textSecondary" style={{ textAlign: 'center' }}>
                                    Loading...
                                </Typography>
                            </div>}
                        >
                            {visibleSermonSeries.map(series =>
                                <Card className={classes.cardContainer} key={series.id}>
                                    <CardContent>
                                        <Typography className={classes.cardTitle} color="textSecondary" gutterBottom>
                                            {new Date(series.date).toLocaleDateString()}
                                        </Typography>
                                        <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: this.highlightText(series.title, this.state.queryText) }} />

                                        <Typography className={classes.pos} color="textSecondary">
                                            {series.views} {series.views === 1 ? "view" : "views"} | {series.ratingCount} {series.ratingCount === 1 ? "rating" : "ratings"}
                                            <span className={classes.ratingStarContainer}>{this.renderStars(series.rating)}</span>
                                        </Typography>
                                        <Typography component="p" dangerouslySetInnerHTML={{ __html: this.highlightText(series.intro, this.state.queryText) }} />

                                    </CardContent>
                                    <CardActions>
                                        <Button color="secondary" onClick={e => this.setState({ showDeleteDialog: true, selectedSermonSeriesIdToDelete: series.id })}>
                                            Delete
                                        </Button>
                                        <div style={{ marginLeft: "auto" }}>
                                            <Button href={series.url} target='_blank'>
                                                View
                                            </Button>
                                            <Link to={`/contributions/sermon-series/edit/${series.id}`} style={{ textDecoration: 'none' }}>
                                                <Button color="primary" >
                                                    Edit
                                                </Button>
                                            </Link>
                                        </div>
                                    </CardActions>
                                    <Divider />
                                    <CardContent>
                                        <Typography className={classes.tagsAndScriptures} color="textSecondary">
                                            <strong>Sermons in Series:</strong> {series.sermonCount}
                                            <br />
                                            <strong>Scripture:</strong> {series.scriptures.length > 0 ? <span dangerouslySetInnerHTML={{ __html: this.highlightText(series.scriptures.join(', '), this.state.queryText) }} /> : 'None'}
                                            <br />
                                            <strong>Tags:</strong> {series.tags.length > 0 ? <span dangerouslySetInnerHTML={{ __html: this.highlightText(series.tags.join(', '), this.state.queryText) }} /> : 'None'}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )}
                        </InfiniteScroll>
                    }
                </Grid>
                <Dialog
                    open={this.state.showDeleteDialog}
                    onClose={e => this.setState({ showDeleteDialog: false })}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">Delete Sermon Series</DialogTitle>
                    <DialogContent>
                        <DialogContentText variant='subtitle2' id="delete-dialog-description">
                            Are you sure you wish to delete this sermon series?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={e => this.setState({ showDeleteDialog: false })} color="default">
                            Cancel
                        </Button>
                        <Button onClick={() => this.deleteSermonSeries()} color="secondary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Link to="/contributions/sermon-series/add">
                    <Fab variant="extended" color="primary" aria-label="Add" className={classes.fab}>
                        <AddIcon className={classes.extendedFab} />
                        Add Series
                    </Fab>
                </Link>
            </div>
        );
    }
}

export default withRoot(withStyles(styles)(SermonSeriesPage));