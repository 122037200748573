import AboutMe from '../entities/AboutMe';
import ApplicationUser from '../entities/ApplicationUser';
import MyChurch from '../entities/MyChurch';
import MyStory from '../entities/MyStory';
import SharedLink from '../entities/SharedLink';
import { ServerResponse, WebClient } from '../services/ServiceHelper';

export class ProfileService {

    public static changeProfileImage(base64ImageUrl: string | null) {
        return WebClient.Post<null>("api/Profile/ChangeProfileImage", { base64ImageUrl: base64ImageUrl });
    }

    public static async getAboutMe() {

        var response = await WebClient.Get<AboutMe>("api/Profile/GetAboutMe");
        if (ServerResponse.isServerResponse<AboutMe>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async updateAboutMe(firstName: string, lastName: string, education: string, experience: string, jobPosition: string) {

        return WebClient.Post<ApplicationUser>("api/Profile/UpdateAboutMe", { firstName, lastName, education, experience, jobPosition });
    }

    public static async getMyChurch() {

        var response = await WebClient.Get<MyChurch>("api/Profile/GetMyChurch");
        if (ServerResponse.isServerResponse<MyChurch>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getDenominations() {

        var response = await WebClient.Get<{ name: string }[]>("api/Profile/GetDenominations");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getChurchAges() {

        var response = await WebClient.Get<{ name: string }[]>("api/Profile/GetChurchAges");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getChurchAttendances() {

        var response = await WebClient.Get<{ name: string }[]>("api/Profile/GetChurchAttendances");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getChurchMedianAudienceAges() {

        var response = await WebClient.Get<{ id: number, name: string }[]>("api/Profile/GetChurchMedianAudienceAges");
        if (ServerResponse.isServerResponse<{ id: number, name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getChurchPrimaryEthnicities() {

        var response = await WebClient.Get<{ name: string }[]>("api/Profile/GetChurchPrimaryEthnicities");
        if (ServerResponse.isServerResponse<{ name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getChurchServiceStyles() {

        var response = await WebClient.Get<{ id: number, name: string }[]>("api/Profile/GetChurchServiceStyles");
        if (ServerResponse.isServerResponse<{ id: number, name: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getUSStates() {

        var response = await WebClient.Get<{ name: string, postalAbbreviation: string }[]>("api/Profile/GetUSStates");
        if (ServerResponse.isServerResponse<{ name: string, postalAbbreviation: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getCanadianProvinces() {

        var response = await WebClient.Get<{ name: string, postalAbbreviation: string }[]>("api/Profile/GetCanadianProvinces");
        if (ServerResponse.isServerResponse<{ name: string, postalAbbreviation: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async getCountries() {

        var response = await WebClient.Get<{ name: string, twoCharCode: string, threeCharCode: string }[]>("api/Profile/GetCountries");
        if (ServerResponse.isServerResponse<{ name: string, twoCharCode: string, threeCharCode: string }[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async updateMyChurch(name: string, description: string, denomination: string, age: string, attendance: string, medianAudienceAge: string, primaryEthnicity: string, serviceStyle: string, phoneNumber: string, emailAddress: string, website: string, streetAddress: string, city: string, state: string, postalCode: string, country: string) {

        return WebClient.Post<null>("api/Profile/UpdateMyChurch", { name, description, denomination, age, attendance, medianAudienceAge, primaryEthnicity, serviceStyle, phoneNumber, emailAddress, website, streetAddress, city, state, postalCode, country });
    }

    public static async getMyStory() {

        var response = await WebClient.Get<MyStory>("api/Profile/GetMyStory");
        if (ServerResponse.isServerResponse<MyStory>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static async updateMyStory(family: string, hobbies: string, bestAdvice: string, sermonDifference: string, favoriteIllustration: string, bookImpact: string, somethingFunny: string, parentsThink: string, spouseThink: string, ifICould: string, tombstone: string, comment: string) {

        return WebClient.Post<null>("api/Profile/UpdateMyStory", { family, hobbies, bestAdvice, sermonDifference, favoriteIllustration, bookImpact, somethingFunny, parentsThink, spouseThink, ifICould, tombstone, comment });
    }

    public static async getSharedLinks() {
        var response = await WebClient.Get<SharedLink[]>("api/Profile/GetSharedLinks");

        if (ServerResponse.isServerResponse<SharedLink[]>(response)) {
            return response.data;
        }
        else {
            return null;
        }
    }

    public static addSharedLink(text: string, url: string) {
        return WebClient.Post<SharedLink>("api/Profile/SaveSharedLink", { text, url });
    }

    public static deleteSharedLink(id: number) {
        return WebClient.Delete<null>("api/Profile/DeleteSharedLink", { id });
    }
}